import LockIcon from '@mui/icons-material/Lock';
import MenuIcon from '@mui/icons-material/Menu';
import { Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import AccountBtn from './AccountBtn';
const drawerWidth = 300;

function ResponsiveDrawer(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let [openChapter, setOpenChapter] = React.useState(props.chapter || props.chapters[0]);
  let [userEmail, setUserEmail] = React.useState("");
  React.useEffect(() => {
    setUserEmail(window?.userEmail || "");
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    if (!mobileOpen) {
      setOpenChapter(props.chapter || props.chapters[0]);
    }
  };
  const onChapterClick = (chapter) => {
    setOpenChapter(chapter);
    setMobileOpen(true);
  }
  const drawer = (
    <div>
      <Toolbar>      
        <Typography variant="body2" component="div" style={{verticalAlign: "middle", padding: 5, fontSize: 11}}>
          Hi there! If you are using this website, please drop me a note at visuallyexplain01 at gmail. I would love to hear from you.
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {props.chapters.map((chapter, index) => (
          <>
            <ListItem key={chapter.name} disablePadding>
              <ListItemButton onClick={()=>onChapterClick(chapter)}>
                <ListItemText primary={chapter.name} href={"/" + chapter.name + "/algorithm"} />
              </ListItemButton>
            </ListItem>
            
            {chapter.name == openChapter.name && chapter.children && chapter.children.map((child, index) => (        
              <ListItem key={child.name} disablePadding sx={{ pl: 3, fontSize: 8}} className={props.chapter.name == chapter.name && props.child.name == child.name ? "selected-sidebar-item" : ""}>
                {(child.component==null) && (
                    <Tooltip title="Login to view this content" style={{}}>
                      <LockIcon style={{fontSize: 20, cursor: "pointer", marginRight: 0, opacity: 0.38}}/>
                    </Tooltip>
                )}
                <ListItemButton href={"/" + chapter.name.replaceAll(" ", "-").toLowerCase() + "/" + child.name.replaceAll(" ", "-").toLowerCase()}>
                  <ListItemText className={"sidebar-subitem"} primary={child.name}/>                  
                </ListItemButton>
                
              </ListItem>
            ))}
          </>
        ))}
      </List>
    </div>
  );
  const container = props.window !== undefined ? () => props.window().document.body : undefined;
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>

          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2}}>
            <MenuIcon />
          </IconButton>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center"}}>            
            <Typography variant="h6" noWrap component="div" style={{verticalAlign: "middle"}}>{props.title}</Typography>
            
            {userEmail.length>0 ? (<AccountBtn email={userEmail} />) : <div><a style={{textDecoration: "none", marginRight: 5}} href='/login'>Sign In</a></div>}
          </div>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: 0 }, flexShrink: { sm: 0 } }} aria-label="sidebar" >
        <Drawer container={container} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{ keepMounted: true }} sx={{ '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth } }}>
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 0, width: { sm: `100%` } }} >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}
export default ResponsiveDrawer;