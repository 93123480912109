import { Button, TextField } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { add, pop } from "./BinaryHeapFunctions";
import Code from './Code';
import Graph from "./Graph";

const codeAdd = 
`void insert(int value) {
    size++;
    values[size - 1] = value;
    int i = size - 1;
    while (i > 0 && values[parent(i)] > values[i]) {
        swap(i, parent(i)));
        i = parent(i);
    }
}
void swap(int a, int b) {
    int temp = values[a];
    values[a] = values[b];
    values[b] = temp;    
}`.split("\n"),
codePop = 
`void pop() {
    if (size == 0) return;
    values[0] = values[size-1];
    size--;
    heapify(0);
}
void heapify(int index) {
    if (size <= 1) return;

    int left = leftChild(index), right = rightChild(index); 
    int smallest = index; 
    if (left < size && values[left] < values[index]) 
        smallest = left; 
    if (right < size && values[right] < values[smallest]) 
        smallest = right; 

    if (smallest != index) {
        swap(index, smallest);
        heapify(smallest); 
    }
}
void swap(int a, int b) {
    int temp = values[a];
    values[a] = values[b];
    values[b] = temp;    
}`.split("\n");

export default function BinaryHeap ({nodes, setNodes, showAdd, showPop, redrawMethodName="redrawHeap", minOrMax}) {
    let ref = createRef();
    let [popped, setPoppedLocal] = useState(null);
    let [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        window[redrawMethodName]({relayout: true}, nodes);
    }, [nodes]);

    let onAddClick = async () => {
        // FIXME check isNumeric
        setInProgress(true);
        await add({nodes, setNodes, sleepMs: 500, minOrMax, value: parseInt(ref.current.value)});
        setInProgress(false);    
    }
    let onPopClick = async () => {
        if (!nodes.length) return;
        setInProgress(true);
        setPoppedLocal(nodes[0].label)
        await pop({nodes, setNodes, minOrMax, sleepMs: 500});
        setInProgress(false);    
    }

    return (
<div>            
    {showAdd && (
    <div className="input-text-wrapper" style={{display:"flex", justifyContent: "space-between", alignItems:"stretch", gap: 10}}>
        <div className="input-text-wrapper" style={{display:"flex", alignItems:"stretch", gap: 10}}>
            <TextField label="Value" variant="outlined" inputRef={ref} style={{width: 100}} disabled={inProgress}/>
            <Button variant="contained" onClick={async () => onAddClick()} disabled={inProgress}>Add</Button>
            {showPop && (<Button variant="contained" onClick={async () => onPopClick()} disabled={inProgress}>Pop</Button>)}
        </div>
    </div>)}
    {popped && (<div style={{marginTop: 10}}>Popped Value = {popped}</div>)}
    <div className="hzflex">
        <div className="fifty">
            <Graph nodes={nodes} setNodes={setNodes} redrawMethodName={redrawMethodName} height={200} isBinary={true}/>
        </div>
        <div className="fifty">
            <Code code={showPop ? codePop : codeAdd} />
        </div>        
    </div>
</div>
)}