import React from "react";

const problem = `Given a binary tree, find its minimum depth. The minimum depth is the number of nodes along the shortest path from the root node down to the nearest leaf node. The node structure for the BST passed to your function will be
class TreeNode {
    int val;
    TreeNode left, right;
}`, example = `Input:
1 2
1 6
2 4
2 5

Output:
2

Explanation:
The minimum depth is 2 through the path from root node 1 to leaf node 6.
`;

export default function MinDepth() {
    return (
        <div style={{textAlign: "left"}}>
            <pre>{problem}</pre>
            <div className="example">
                <pre>{example}</pre>
            </div>
        </div>)
}