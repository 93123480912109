import { Button, Typography } from '@mui/material';
const { useState } = require("react");

function genGrid(n) {
    let grid = [];
    for (let i = 0; i < n; i++) {
        let row = [];
        for (let j = 0; j < n; j++) {
            row.push(Math.floor(Math.random() * 10));
        }
        grid.push(row);
    }
    return grid;
}

function genPaths(grid, x, y) {
    if (x >= grid.length || y >= grid[0].length) return [];
    if (x == grid.length-1 && y == grid[0].length-1) return [[[x,y]]];    
    let paths = [...genPaths(grid, x+1, y), ...genPaths(grid, x, y+1)];
    paths = paths.filter(path => path.length > 0).map(path => [[x,y]].concat(path));
    return paths;
}

export default function MinPathSum() {
    let [grid, setGrid] = useState(genGrid(4));
    let paths = genPaths(grid, 0, 0);
    let [steps, setSteps] = useState(0);
    let [log, setLog] = useState([]);
    let [btn, setBtn] = useState("Begin");

    const getCellCls = (i, j, path) => {
        let cls = 'gridcell';
        if (path.some(([x,y]) => x == i && y == j)) cls = cls + ' selected';
        if (steps == 20 && i == grid.length-1 && j == grid[0].length-1) cls = cls + ' doublyselected';
        console.log(path)
        if (steps == 30 && path[path.length-2][0] == i && path[path.length-2][1] == j) cls = cls + ' doublyselected';
        return cls;
    }
    const next = () => {
        if (steps == 0) {
            setLog(["Let's list all possible paths from top-left to bottom-right."]);
            setBtn("List All Paths");
            setSteps(steps+10);
        }
        if (steps == 10) {
            setLog(["In every single path below, the last step is the bottom-right cell."]);
            setBtn("Next Step");
            setSteps(steps+10);
        }
        if (steps == 20) {
            setLog(["Also, in every single path, the last-but-one step is either the cell above or the cell to the left of the last step."]);
            setBtn("Next Step");
            setSteps(steps+10);
        }
    }

    return (
        <div>
            <div style={{position: "absolute", top: 0, right: 10, opacity: 0.5}}>Steps: {steps}</div>
            <Typography variant="body1">{log.join(" ")}</Typography>
            {steps <= 140 && (
                <Button fullWidth="true" style={{marginTop: 10, marginBottom: 10}} variant="contained" onClick={() => next()}>{btn}</Button>)}

            <Typography variant="body1">Input</Typography>
            <div className="grid" style={{marginBottom: 20}}>
                {grid.map((row, i) => (
                <div className="row" key={i}>
                    {row.map((cell, j) => (
                        <div className="gridcell" key={j}>
                            {cell}
                        </div>
                    ))}
                </div>))}                                          
            </div>
            {steps >= 20 && (
                <div className="paths">
                {paths.map((path, ii) => (
                    <div className='grid-container'>
                        <div className="grid">
                            {grid.map((row, i) => (
                            <div className="row" key={i}>
                                {row.map((cell, j) => (
                                    <div className={getCellCls(i,j,path)} key={j}>
                                        {cell}
                                    </div>
                                ))}
                            </div>))}                                         
                        </div>
                        <div className="sum">Path Sum = {path.reduce((sum, [x,y]) => sum + grid[x][y], 0)}</div>
                    </div>
                    ))}                                 
                </div>)}
        </div>
    )
}