import React from "react";
import Dijkstra from "./Dijkstra";

export default function NetworkDelayTimeSolution() {
   
    return (
<div>
<p>TODO: This is dijkstra. It needs to be changed slightly for the problem. </p>
<div className="playground">
    <Dijkstra />
</div>
    
</div>)
}