import BFS from "./BFS";

export const BFSAlgo = () => {
return (
<div className="algocontainer">
    <p>Breadth-First Search is a technique to traverse all the nodes of a tree. The traversal begins at a source node, visits node after node, until all nodes are visited. "Breadth" refers to the horizontal dimension of a tree, as opposed to "depth", which is the vertical dimension. In BFS, we go through the nodes layer-wise.</p>
    <p>BFS uses a queue and a hashmap in its implementation. Go ahead and try it out.</p>
    <BFS isTree={true} search={false}/>

</div>
)}