import { Button, Typography } from '@mui/material';
import Code from '../Code';
import * as Constants from "../Constants";
import Graph from '../Graph';
import { bfs } from "../GraphFunctions";
import { Array } from './Array';

const { useState, useEffect } = require("react");
const specialColor = Constants.HIGHLIGHTED_NODE_COLOR, defaultNodeColor = Constants.DEFAULT_NODE_COLOR;

const code = 
`bool equationsPossible(vector<string>& e) {
    for (int i = 0; i < e.size(); i++) {
        int a = e[i][0]-'a', b = e[i][3]-'a';
        if (e[i][1] == '=') {
            union(a, b);
        }
    }
    for (int i = 0; i < e.size(); i++) {
        int a = e[i][0]-'a', b = e[i][3]-'a';
        if (e[i][1] == '!' && find(a) == find(b)) {
            return false;
        }
    }
    return true;
}`.split("\n");
function generateInput() {
    let n = Math.floor(Math.random() * 5) + 5;
    let input = [];
    for (let i = 0; i < n; i++) {
        let code1 = 'a'.charCodeAt(0) + Math.floor(Math.random() * 10);        
        let code2 = 'a'.charCodeAt(0) + Math.floor(Math.random() * 10);
        if (code1 > code2) {
            let tmp = code1; code1 = code2; code2 = tmp;            
        }
        let a = String.fromCharCode(code1), b = String.fromCharCode(code2);
        let op = Math.random() < 0.6 ? '==' : '!=';
        input.push(`${a}${op}${b}`);
    }
    input = input.filter((x, i) => input.indexOf(x) == i);
    return input;
}

export default function EqualityEquationsSolution() {
    let [input, setInput] = useState([]);
    let [btn, setBtn] = useState("Begin");

    let [inProgress, setInProgress] = useState(false);
    let [ii, setI] = useState(-1);
    let [nodes, setNodes] = useState([]);
    let [rank, setRank] = useState({});
    let [phase, setPhase] = useState(0);
    let [result, setResult] = useState("");
    let [letterToIndex, setLetterToIndex] = useState({});
    let [line, setLine] = useState(-1);

    const a = 'a'.charCodeAt(0);
    useEffect(() => {
        input = generateInput();
        setInput(input);
        setI(-1);
        let letters = {};
        
        for (let i = 0; i < input.length; i++) {
            let let1 = input[i][0].charCodeAt(0) - a, let2 = input[i][3].charCodeAt(0) - a;
            letters[let1] = 1;
            letters[let2] = 1;
        }
        nodes = [];
        rank = {};
        letterToIndex = {};
        for (let i = 0; i < 26; i++) if (letters[i]) {
            letterToIndex[i] = nodes.length;
            nodes.push({
                label: String.fromCharCode(a + i), 
                index: nodes.length, 
                parents: [],
                children: []
            });
            rank[i] = 1;
        }
        setLetterToIndex(letterToIndex);
        setNodes(nodes);
        setRank(rank);
        setPhase(0);
        
    }, []);
    let getNode = (j) => {
        return nodes[letterToIndex[input[ii][j].charCodeAt(0) - a]];
    }

    let color = (n, clr) => {
        for (let i = 0; i < n.length; i++) {
            nodes[n[i].index].fill = clr;
        }
        setNodes([...nodes]);
        window.redrawGraph(null, nodes);        
    }


    let onBtn = async () => {
        if (ii == -1) {
            ii++; setI(ii);
        } else {
            let let1 = getNode(0), let2 = getNode(3);
            if (phase == 0) {
                setLine(4);
                union(let1, let2);
            } else {
                let root1 = find(let1), root2 = find(let2);
                color([root1, root2], specialColor);
                if (root1.index == root2.index) {
                    setResult("Inconsistency found!");
                    setBtn("");
                    setLine(10);
                    return;
                } else {
                    color([root1, root2], defaultNodeColor);
                    setResult("Consistent so far...");
                    await new Promise(r => setTimeout(r, 1000));
                }
            }
            ii++; setI(ii);
        }
        
        while(ii < input.length) {            
            let let1 = getNode(0), let2 = getNode(3);
            if (phase == 0 && input[ii].includes('==')) {
                setBtn("Call union(" + let1.label + ", " + let2.label + ")");
                return;
            } 
            if (phase == 1 && input[ii].includes('!=')) {
                setLine(9);
                setBtn("Is find(" + let1.label + ") == find(" + let2.label + ")?");
                return;
            }
            ii++;
            setI(ii);                
        }
        if (ii == input.length) {
            if (phase == 1) {
                setResult("Finished! No inconsistencies found.");
                setBtn("");
                setLine(13);
            } else {
                ii = -1;
                setI(ii);
                setPhase(1);
                setBtn("Begin the find comparisons");  
                setLine(7);
            }            
        }
    }

    let find = (node) => {
        if (node.parents[0] == null) return node;
        let root = find(nodes[node.parents[0]]);
        // remove from old parent's children
        nodes[node.parents[0]].children = nodes[node.parents[0]].children.filter(x => x != node.index);
        // add to root's children
        nodes[root.index].children.push(node.index);
        // set root as parent
        nodes[node.index].parents = [root.index];
        setNodes([...nodes]);        
        window.redrawGraph({relayout: true}, nodes);
        return root;
    }

    let union = (node1, node2) => {
        let root1 = find(node1).index, root2 = find(node2).index;
        if (root1 == root2) return;
        if (rank[root1] == rank[root2]) {
            nodes[root1].parents = [root2];
            nodes[root2].children.push(root1);
            rank[root2]++;
            setRank(rank);
        } else if(rank[root1] < rank[root2]) {
            nodes[root1].parents = [root2];
            nodes[root2].children.push(root1);
        } else {
            nodes[root2].parents = [root1];
            nodes[root1].children.push(root2);
        }
        nodes = bfs(nodes);
        setNodes([...nodes]);
        window.redrawGraph({relayout: true}, nodes);
    }

    return (
<div>
    <p>Suppose the input is [a==b, b==c, c!=d, d==e]. If we can assign integers to the variables, a, b and c will have the same integer value. d and e also will have the same integer value, but it can be different from the value of a/b/c. For example, we can set a=1, b=1, c=1, and d=2, e=2. If we think of the variables as nodes in a graph, then an edge exists between two nodes that are connected by ==. Once we've constructed such a graph, we can check all the inequalities in the input, such as c!=d, and check whether the two nodes belong to the same graph. If they do, that means that they must be assigned the same integer value, and hence an inequality between them is not possible.</p>
    <p>Let's visualize this.</p>
    <div className="playground hzflex">
        <div className="fifty">
            {result.length > 0 && <Typography className="result" variant="body1" sx={{mb: 1}}>{result}</Typography>}
            {btn.length > 0 && <Button style={{textTransform: "lowercase"}} variant="outlined" onClick={async () => onBtn()} disabled={inProgress}>{btn}</Button>}

            <div style={{marginTop: 10, marginBottom: 10}} className="equations-array">
                <Array values={input} itFn={(i)=> (i == ii) ? "i="+i : null} current={ii}/>
            </div>
            <div>
                <Graph nodes={nodes} setNodes={setNodes}/>                                
            </div>
        </div>
        <div className="fifty">
            <Code code={code} codeLine={line}/>
        </div>
    </div>
</div>
)}