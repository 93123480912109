import { useState } from "react"
import { Button } from '@mui/material';
import Graph from '../Graph';
const explanation = `
Example:
The order of words in the dictionary is ["baa","abcd","abca","cab","cad"].

In English, the alphabet order is a < b < c ... < z. So "apple" would come before "apricot" in the dictionary, since p < r. However, since the language is not English, the correct order is not a < b < c < d.

Let's look at every word and its next word, in pairs:
    baa < abcd, so b must come before a
    abcd < abca, so d must come before a
    abca < cab, so a must come before c
    cab < cad, so b must come before d

If you make the nodes as the alphabets, and the directed edges as the "must come before" relationship, then we can draw a graph. The solution is to find a topological sorted order of nodes.
`;
const generateRandomFruits = () => {
    let fruits = [
        "apple",
        "banana",
        "cherry",
        "date",
        "eggfruit",
        "fig",
        "grape",
        "honeydew",
        "jackfruit",
        "kiwi",
        "lemon",
        "mango",
        "orange",
        "papaya",
        "quince",
        "raspberry",
        "strawberry",
        "tomato",
        "watermelon",
        "xigua",
        "yam",
        "zucchini"
    ];
    let randomFruits = [];
    for (let i = 0; i < 5; i++) {
        let randomIndex = Math.floor(Math.random() * fruits.length);
        randomFruits.push(fruits[randomIndex]);
        if (i==2) randomFruits.push(randomFruits[2]);
    }
    
    return randomFruits;
}

export const AlienDictionarySolution = () => {
    let [words, setWords] = useState(generateRandomFruits());
    let [step, setStep] = useState(-1);

    let [wordIndex, setWordIndex] = useState(-1);
    let [letterIndex, setLetterIndex] = useState(-1);
    let [compareResult, setCompareResult] = useState("");
    let [goToNextWord, setGoToNextWord] = useState(false);
    let [goToNextLetter, setGoToNextLetter] = useState(false);
    let [nodes, setNodes] = useState([]);
    let [links, setLinks] = useState([]);

    const countStuff = () => {
        let x = 0, mp = {};
        for (let i = 0; i < words.length; i++) {
            for (let j = 0; j < words[i].length; j++) {
                if (mp[words[i][j]] == undefined) {
                    mp[words[i][j]] = x;
                    x++;
                }
            }
        }
        setStep(step+1);
        setGoToNextWord(true);
        setCompareResult("There are " + words.length + " words and " + x + " letters. Let's make a graph with " + x + " nodes.");
        // layoutGraph(mp);
    }

    const layoutGraph = (mp) => {
        let nodes = Object.keys(mp).map((x, i) => {return {label: x}});
        for (var i = 0; i < words.length-1; i++) {
            for (var j = 0; j < words[i].length && j < words[i+1].length; j++) {
                if (words[i][j] != words[i+1][j]) {
                    let tar = mp[words[i+1][j]];
                    nodes[tar].parents = nodes[tar].parents || [];
                    nodes[tar].parents.push(mp[words[i][j]]);
                    break;
                }
            }
        }
        setNodes(nodes);
        window.redrawGraph();
    }

    const addEdge = (from, to) => {
        let toIndex = nodes.findIndex((x) => x.label == to);
        if (toIndex == -1) {
            nodes.push({
                label: to,
                parents: []
            });
        }
        let fromIndex = nodes.findIndex((x) => x.label == from);
        if (fromIndex == -1) {
            nodes.push({
                label: from,
                parents: []
            });
        }
        toIndex = nodes.findIndex((x) => x.label == to);
        fromIndex = nodes.findIndex((x) => x.label == from);
        nodes[toIndex].parents.push(fromIndex);

        setNodes(nodes);
        console.log(nodes)
        window.redrawGraph({relayout:true});
    }


    const compareWords = () => {
        if (goToNextWord) {
            wordIndex++; 
            letterIndex=0;
            if (wordIndex == words.length-1) {
                setStep(step+1);
                setGoToNextWord(false); 
                setGoToNextLetter(false);
                setCompareResult("Great! We've finished adding the edges. However, to make it easy to sort, let's change the labels from letters to numbers. Let's make a as 0, b as 1, c as 2, and so on.");                
                return;
            }
            setWordIndex(wordIndex);
            setLetterIndex(letterIndex);
            setGoToNextWord(false);
            setGoToNextLetter(true);
            // setCompareResult("");
        }
        if (goToNextLetter) {
            letterIndex++;
            setLetterIndex(letterIndex);
        }
        if (letterIndex >= words[wordIndex].length || letterIndex >= words[wordIndex+1].length) {
            setCompareResult("We've reached the end of a word.");            
            setGoToNextWord(true);
            setGoToNextLetter(false);
            return;
        }
        if (words[wordIndex][letterIndex] == words[wordIndex+1][letterIndex]) {
            setCompareResult("The letters are the same.");        
            setGoToNextLetter(true);
        } else {
            setCompareResult("The letters are different. Adding the edge " + words[wordIndex][letterIndex]+ " -> " + words[wordIndex+1][letterIndex] + " to our graph.");
            setGoToNextWord(true);
            setGoToNextLetter(false);

            addEdge(words[wordIndex][letterIndex], words[wordIndex+1][letterIndex])
        }
              
    }
    const changeLetters = () => {
        for (let i = 0; i < nodes.length; i++) {
            nodes[i].label = parseInt(nodes[i].label, 36) - 10;
        }
        setNodes(nodes);
        window.redrawGraph();
        setStep(step+1);
    }
    const getLetterClassName = (wIndex, lIndex) => {
        if (letterIndex == lIndex && wIndex+1 < words.length) {
            return words[wIndex][lIndex] == words[wIndex+1][lIndex] ? "letter green" : "letter red";
        }
        return "letter";
    }

    return (
        <>            
           {step >= 0 &&  (
            <div className="fruits">
                {words.join(", ")}
            </div>)}
            
            {step == 0 && (<Button variant="contained" onClick={() => countStuff()}>Count Stuff</Button>)}

            <div className="words-result">
                {compareResult}
            </div>
            {goToNextWord && (<Button variant="contained" onClick={() => compareWords()}>Go to next word</Button>)}
            {goToNextLetter && (<Button variant="contained" onClick={() => compareWords()}>Go to next letter</Button>)}
            {step == 2 && (<Button variant="contained" onClick={() => changeLetters()}>Change letters to numbers</Button>)}
            <div className="solution">
                {step == -1 && (
                    <>
                        <pre>{explanation}</pre>
                        <Button variant="contained" onClick={() => setStep(step+1)}>Continue</Button>
                    </>
                )}
                {step == 1 && wordIndex >= 0 && wordIndex+1 < words.length && (
                    <div className="words">
                        <div className="word">
                            {words[wordIndex].split("").map((letter, index) => <div className={getLetterClassName(wordIndex, index)}>{letter}</div>)}
                        </div>
                        <div className="word">
                            {words[wordIndex+1].split("").map((letter, index) => <div className={getLetterClassName(wordIndex, index)}>{letter}</div>)}
                        </div>
                    </div>
                )}
                {step >= 1 && (
                <div className="graph">
                    <Graph nodes={nodes} setNodes={setNodes} links={links}/>
                </div>)}
            </div>
        </>

        
    )
}