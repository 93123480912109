import { Button } from "@mui/material";
import { useState } from "react";

function rand(n) {
    return parseInt((Math.random() * 10000000000000) % n);
}


let code = 
`int sum = 0, maxSum = 0, start = 0;
for (int end = 0; end < arr.size(); end++) {
    sum += arr[end]; 
    if (end > k) { // shrink the window only when it becomes bigger than K (window size)
        maxSum = max(maxSum, sum);
        sum -= arr[start];
        start++;
    }
}
return maxSum;`;

code = code.split("\n");

const generateRandomArray = (size, isBinary) => {
    let array = [];
    for (let i = 0; i < size; i++) {
        let sign = 1; //Math.random() * 100 < 50 ? -1 : 1;
        if (isBinary) array.push(Math.floor(Math.random() * 2));
        else array.push(sign * Math.floor(Math.random() * 20));
    }
    return array;
}
export default function MaxSubarraySumSizeKSolution() {
    let [steps, setSteps] = useState(0);
    let [array, setArray] = useState(generateRandomArray(10));
    let [windowStart, setWindowStart] = useState(0);
    let [windowEnd, setWindowEnd] = useState(0);
    let [codeBtn, setCodeBtn] = useState("Initialize");
    let [selectedLine, setSelectedLine] = useState(-1);
    let [size, setSize] = useState(rand(5)+1);
    
    let [maxSum, setMaxSum] = useState(0);
    let [windowSum, setWindowSum] = useState(0);
    
    let [codeComplete, setCodeComplete] = useState(false);
    const nextCode = () => {
        if (selectedLine == -1) {
            setSelectedLine(0);
            setWindowStart(0);
            setWindowEnd(0);
            setWindowSum(0);
            setMaxSum(0);
            setCodeBtn("Next Step");
            setCodeComplete(false);
        }
        if (selectedLine == 0) {
            if (windowEnd == array.length - 1) {
                // finish
                setSelectedLine(10);
                return;
            }
            setSelectedLine(2);
        }
        
        if (selectedLine == 2) {
            setSelectedLine(3);
            
            windowSum = windowSum + array[windowEnd];
            setWindowSum(windowSum);
            
        }
        if (selectedLine == 3) {            
            maxSum = Math.max(maxSum, windowSum)
            setMaxSum(maxSum);
            if (windowEnd >= size - 1) {
                setSelectedLine(5);                
                return;
            } else {
                windowEnd = windowEnd + 1;
                setWindowEnd(windowEnd);
                setSelectedLine(2);
                return;
            }
        }
        if (selectedLine == 5) {            
            windowSum = windowSum - array[windowStart];
            setWindowSum(windowSum);
            setSelectedLine(6);
        }
        if (selectedLine == 6) {
            windowStart = windowStart + 1;
            setWindowStart(windowStart);
            setSelectedLine(8);
        }
        if (selectedLine == 8) {
            if (windowEnd == array.length - 1) {
                setSelectedLine(10);
                setCodeBtn("");
                setCodeComplete(true);
            } else {        
                windowEnd = windowEnd + 1;
                setWindowEnd(windowEnd);
                setSelectedLine(2);
            }
        }
        
    }

    return (
<div className="">
    <div className="">
        <div className="">
            <div style={{position: "absolute", top: 0, right: 10, opacity: 0.5}}>Steps: {steps}</div>

            <div className="code-and-btns">                
                <div className="btns">
                    {codeBtn.length>0 && (<Button fullWidth="true" style={{marginTop: 10, marginBottom: 10}} variant="contained" onClick={() => nextCode()}>{codeBtn}</Button>)}
                    <div>
                        <div>K = {size}</div>
                        {<div className="window">
                                {array.map((value, index) =>  (
                                    <div className={"windowitem " + ((index >= windowStart && index <= windowEnd) ? "in" : "") + " " + (index == 0 ? "first " : "") + (index == array.length-1 ? "last " : "")}>
                                        <div className="number" style={{textAlign: "center"}}>{value}</div>
                                    </div>
                                ))}
                        </div> }
                        <div></div>
                        <div className="variable" style={{marginTop: 20, marginRight: 10, display: "inline-block", width: 80, textAlign: "center"}}>
                            <div className="variable-title">windowSum</div>
                            <div>{windowSum}</div>
                        </div>                           
                        <div className={"variable " + (codeComplete ? "complete" : "")} style={{marginTop: 20, marginRight: 10, display: "inline-block", width: 80, textAlign: "center"}}>
                            <div className="variable-title">maxSum</div>
                            <div>{maxSum}</div>
                        </div>                                               
                    </div>
                </div>
                <div className="code">
                    {code.map((line, i) => (
                    <div className={"line " + ((selectedLine==i) ? "selected" : "")} key={i}>
                        <span className="linenumber">{i}</span>
                        <pre>{line}</pre>
                    </div>))}
                </div>
            </div>
        </div>
    </div>
</div>
)}