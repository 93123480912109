import { Button, Typography } from '@mui/material';
import { createRef } from 'react';
import CallStack from '../CallStack';
import Code from '../Code';
import Grid from '../Grid';
import Variable from '../Variable';
const { useState } = require("react");


let genGrid =   () => {
    let grid = [];
    for (let i = 0; i < 5; i++) {
        let row = [];
        for (let j = 0; j < 5; j++) {
            row.push(Math.random() < 0.5 ? 0 : 1);
        }
        grid.push(row);
    }
    return grid;
}

function CountIslands({}) {
    let [data, setData] = useState(genGrid());
    let [visited, setVisited] = useState(data.map(row => row.map(v => 0)));
    let [visiting, setVisiting] = useState(data.map(row => row.map(v => 0)));
    let [recursionStack, setRecursionStack] = useState([]);
    let [incrementingKey, setIncrementingKey] = useState(0);
    let [count, setCount] = useState(0);
    let [btn, setBtn] = useState("Start DFS");
    let [result, setResult] = useState("");
    let [ii, setI] = useState(0);
    let [jj, setJ] = useState(-1);
    let [resultFinal, setResultFinal] = useState(false);

    let next = () => {
        if (recursionStack.length > 0) { // dfs is in progress
            dfs();
            return;
        }
        if (ii == data.length-1 && jj == data[0].length-1) {
            setRecursionStack([]); setIncrementingKey(incrementingKey+1);
            setResult("Finished counting! There are " + count + " islands.");
            setResultFinal(true);
            return;
        }
        
        if (jj < data[0].length-1) jj++;
        else { ii++; jj=0; }
        setI(ii); setJ(jj);
       
        if (visited[ii][jj]) {
            setResult(ii + ", " + jj + " is a visited cell. Skip it and proceed.");
            return;
        }
        if (!data[ii][jj]) {
            setResult(ii + ", " + jj + " is a water cell. Skip it and proceed.");
            return;
        }
        setResult(ii + ", " + jj + " is an unvisited land cell. Will increment number-of-islands count & start dfs from here.");
        setCount(count+1);
        recursionStack.push({
            xy: [ii,jj],
            label: "x=" + ii + ", y=" + jj
        }); 
        setRecursionStack(recursionStack); setIncrementingKey(incrementingKey+1);
        visited[ii][jj] = 1;
        setVisited(visited);
    }
    
    const dfs = async () => {
        
        let unseenChild = false;
        let children = makeChildren(recursionStack[recursionStack.length-1].xy)
        for (let i = 0; i < children.length; i++) {
            let v = children[i];
            let x = v[0], y = v[1];

            if (!visited[x][y]) {
                unseenChild = true;
                console.log("pushing " + x + ", " + y);
                visiting[x][y] = 1;
                setVisiting(visiting);
                recursionStack.push({
                    xy: [x,y],
                    label: "x=" + x + ", y=" + y
                });
                setRecursionStack(recursionStack); setIncrementingKey(incrementingKey+1);
                visited[x][y] = 1;
                setVisited(visited);
                // await sleep();
                // setSelectedLine(1);
                setBtn("Call DFS on " + x + ", " + y + "'s children");
                return;
            }
        }
        if (!unseenChild) {
            let popped = recursionStack.pop();
            setRecursionStack(recursionStack); 
            setIncrementingKey(incrementingKey+1);
            visiting[popped.xy[0]][popped.xy[1]] = 0;
            setVisiting(visiting);
            if (recursionStack.length == 0) {
                // setResult("Finished!");
                return;
            }
            setBtn("Next Step");
        }
        
    }
    let makeChildren = (xy) => {
        let children = [], x = xy[0], y = xy[1];
        if (x > 0) {
            children.push([x-1, y]);
        }
        if (x < data.length-1) {
            children.push([x+1, y]);
        }
        if (y > 0) {
            children.push([x, y-1]);
        }
        if (y < data[0].length-1) {
            children.push([x, y+1]);
        }
        children = children.filter(v => data[v[0]][v[1]] == 1);
        return children;
    }
    let getCls = (i, j) => {
        let s = " ";
        if (recursionStack.length > 0 && recursionStack[recursionStack.length-1].xy[0] == i && recursionStack[recursionStack.length-1].xy[1] == j) {
            s = "dfs-node ";
        }
        if (ii == i && jj == j) {
            s = s + ' dfs-start-node';
        }
        if (visiting[i][j]) {
            s = s + ' dfs-start-node';
        }
        return s;
    }
    return (
        <>
            {result && <Typography className="result" variant="body1" sx={{mb: 2}}>{result}</Typography>}
            {/* {!result && progressStatement && <Typography variant="body1" sx={{mb: 1}}>{progressStatement}</Typography>} */}
            {!resultFinal && (<Button variant="contained" onClick={() => next()} sx={{mb: 2}}>{btn}</Button>)}
            <div style={{display: "flex", flexDirection: "row", gap: 10, alignItems: "stretch", justifyContent: "space-between"}}>
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    <CallStack  style={{flexGrow: 1}} recursionStack={recursionStack} incrementingKey={incrementingKey}/>
                    <div>
                        <Variable name={"count"} value={count} />
                    </div>                    
                </div>
                <Grid name="Map" grid={data} getCls={getCls} ii={ii} jj={jj}/>       
                <Grid name="Visited" grid={visited} getCls={getCls} ii={ii} jj={jj}/>
            </div>
        </>
    )
}


let code = [`
for (int i = 0; i < rows; i++) {
    for (int j = 0; j < cols; j++) {
        // cell[i][j] is a land or water cell
    }
}`, `
for (int i = 0; i < rows; i++) {
    for (int j = 0; j < cols; j++) {
        if (!visited[i][j] && cell[i][j] == 1) {
            // i,j is an unvisited land cell
            // do a traversal starting from i,j
        }
    }
}`, `function dfs(i, j) {
    if (i < 0 || i >= rows || j < 0 || j >= cols || visited[i][j] || cell[i][j] == 0) {
        return;
    }
    visited[i][j] = true;
    dfs(i + 1, j);
    dfs(i - 1, j);
    dfs(i, j + 1);
    dfs(i, j - 1);
}`];
for (let i = 0; i < code.length; i++) {
    code[i] = code[i].split("\n");
}

export default function CountIslandsSolution() {
    let [btn, setBtn] = useState("Begin");

    let [inProgress, setInProgress] = useState(false);
    let [incrementingKey, setIncrementingKey] = useState(0);
    let ref = createRef();

    return (
<div>
    <p>Let's convert the grid into a graph, where every cell on the grid is a node, and an edge exists between two cells if they are adjacent to each other. The grid will then become a bunch of graphs, and the solution to the problem is the number of graphs.</p>
    <p>Any graph traversal algorithm, such as BFS, DFS or Union-Find can be used to solve this problem. The idea is to iterate through the nodes one by one:</p>
    <Code code={code[0]}/>
    <p>Using a traversal algorithm, we will track the visited cells. Whenever we find an unvisited land cell, we must do a traversal on that cell and visit the entire island of which that land cell is a part. Like this:</p>
    <Code code={code[1]}/>
    <p>A DFS traversal looks like this:</p>
    <Code code={code[2]}/>
    <p>Try this playground to visualize the full solution:</p>
    <div className="playground">
        <CountIslands />
    </div>
</div>
)}