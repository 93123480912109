

function kmp({text, pattern, lps, i=0, j=0, setI, setJ}) {
    if (!lps) return;
    if(i+j < text.length) {
        if (pattern[j] == text[i+j]) {
            // i = i + 1; setI(i);
            j = j + 1; setJ(j);
        }
        if (j == pattern.length) {
            console.log("match");
            return i - j;
        }
        else if (i+j < text.length && pattern[j] != text[i+j]) {
            if (j > 0) {
                i = i + j - lps[j - 1]; setI(i);
                j = lps[j - 1];
                setJ(j);
            }
            else {
                i = i + j + 1;
                setI(i);
            }
        }
    } else {
        console.log("no match");
        return -1
    }
}
function calculateLPS({pattern}) {
    let LPS = {}
    LPS[0] = 0;
    let len = 0, i = 1;
    
    while(i < pattern.length) {
        if (pattern[i]== pattern[len]) {
            len = len + 1;
            LPS[i] = len;
            i = i + 1;
        }
        else  {
            if (len != 0) {
                len = LPS[len - 1];
            }
            else  {
                LPS[i] = 0;
                i = i + 1;
            }
        }
    }
    return LPS
}

function sleep() {
    return new Promise(resolve => setTimeout(resolve, 200));
}

async function bruteforce({text, pattern, i=0, j=0, setI, setJ}) {
    if (j >= pattern.length || i+j >= text.length) return;
    if (text[i+j] == pattern[j] && j < pattern.length && i+j < text.length) {
        j = j + 1; setJ(j);
    } else if (i+pattern.length < text.length) {
        i = i + 1; setI(i);
        j = 0; setJ(j);
    }
}

function genString(n=10, letters=4) {
    let s = "";
    for (let i=0; i<n; i++) {
        s += String.fromCharCode(65 + Math.floor(Math.random()*letters));
    }
    return s;
}

export { kmp, calculateLPS, bruteforce, genString };
