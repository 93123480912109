import { Button } from "@mui/material";
import React, { useState } from "react";
import BinaryHeap from './BinaryHeap';
import { add, pop } from "./BinaryHeapFunctions";

const problem = `You are given an array of k linked-lists lists, each linked list is sorted in ascending order. You need to merge all the linked list into a single linked list and return it.`, 

examples = [`Example 1:
Input: [[1, 4, 5], [1, 3, 4], [2, 6]]
Output: [1, 1, 2, 3, 4, 4, 5, 6]
Explanation: The linked-lists are:
[
1 ⇾ 4 ⇾ 5,
1 ⇾ 3 ⇾ 4,
2 ⇾ 6
] 
 
Merged Linked list will be
1  ⇾ 1 ⇾ 2  ⇾ 3  ⇾ 4  ⇾ 4  ⇾ 5  ⇾ 6`, `Example 2: 
Input: [[1, 2, 3], [4, 5, 6], [7 ,8, 9]]
Output: [1, 2, 3, 4, 5, 6, 7, 8, 9]
Explanation: The linked-lists are:
[
1 ⇾ 2  ⇾ 3,
4  ⇾ 5  ⇾ 6,
7  ⇾ 8  ⇾ 9
]
Merged Linked list will be
1  ⇾ 2  ⇾ 3  ⇾ 4  ⇾ 5  ⇾ 6  ⇾ 7  ⇾ 8  ⇾ 9`];
function MergeKSortedArraysProblem() {
    return (
        <div style={{textAlign: "left"}}>
            <pre>{problem}</pre>
            {examples.map((example, index) => (
                <div key={index} className="example">
                    <pre>{example}</pre>
                </div>
            ))}
        </div>)
}
function generateSortedArrays (k) {
    let arrays = [];
    for (let i = 0; i < k; i++) {
        let array = [], n = Math.floor(Math.random() * 2) + 2;        
        for (let j = 0; j < n; j++) {
            array.push(Math.floor(Math.random() * 20));
        }
        array.sort((a, b) => a - b);
        arrays.push(array);
    }
    return arrays;
}
function copy(thing) {
    return JSON.parse(JSON.stringify(thing));
}
function KArraysPlaygroundHeaps({onSuccess, k, removeFirst=false}) {
    let [arraysCopy, setArraysCopy] = useState(generateSortedArrays(k));
    let [arrays, setArrays] = useState(copy(arraysCopy));
    let [total, setTotal] = useState(arrays.reduce((acc, array) => acc + array.length, 0));
    let [mergedArray, setMergedArray] = useState([]);
    let [error, setError] = useState(false);
    let [success, setSuccess] = useState(false);
    let [heapNodes, setHeapNodes] = useState([]);
    let [its, setIts] = useState(new Array(k).fill(0));
    let [btn, setBtn] = useState("Initialize");
    
    let comparator = ({nodes, minOrMax, parentIndex, childIndex}) => {
        return nodes[parentIndex].label.value <= nodes[childIndex].label.value;
    }
    let addToHeap = async (num, index, sleepMs=1000) => {
        heapNodes =  await add({nodes: heapNodes, setNodes: setHeapNodes, minOrMax: "min", value: {
            value: num,
            index: index,
            fn: (node) => node.label.value
        }, sleepMs: sleepMs, isParentChildInvariant: comparator});
        
        setHeapNodes([...heapNodes]);
    }
    let popFromHeap = async () => {
        heapNodes = await pop({nodes: heapNodes, setNodes: setHeapNodes, minOrMax: "min", sleepMs: 1000, isParentChildInvariant: comparator});
        setHeapNodes([...heapNodes]);
    }
    let restart = () => {
        setError(false);
        setSuccess(false);
        setArrays(copy(arraysCopy));
        setTotal(arraysCopy.reduce((acc, array) => acc + array.length, 0));
        setMergedArray([]);        
        setIts(new Array(k).fill(0));
        setBtn("Initialize");
        heapNodes = [];
        setHeapNodes([]);
    }
    let next = async () => {
        if (btn == "Initialize") {
            for (let i = 0; i < k; i++) {
                console.log("adding " + arrays[i][0])
                await addToHeap(arrays[i][0], i, 100);
            }
            setBtn("Next Step");
            return
        }
        let popped = heapNodes[0].label;
        setMergedArray([...mergedArray, popped.value]);
        console.log("popped " + popped.value + "(" + popped.index +")" + " from heap")
        await popFromHeap();
        
        its[popped.index]++;
        setIts(its);
        if (its[popped.index] < arrays[popped.index].length) {
            await addToHeap(arrays[popped.index][its[popped.index]], popped.index);
        }       
        
        if (mergedArray.length+1 == total) {
            setSuccess(true);
            onSuccess(k);
            return;
        }

    }
    return (
    <div className="">
        <div style={{display: "flex", flexDirection: "row", gap: 20, justifyContent: "space-between", alignItems: "stretch", marginBottom: 20}}>
            {success && (<div className="success" style={{marginBottom: 20}}>Success!</div>)}
            {error && (<div className="error">Oops! The merged array is no longer sorted!</div>)}
            {!error && !success && (<Button variant="contained" onClick={() => next()}>{btn}</Button>)}
            <div></div>
            <Button variant="outlined" onClick={() => restart()}>Restart</Button>
        </div>
            
        <div style={{display: "flex", flexDirection: "row", gap: 20, justifyContent: "center", alignItems: "stretch"}}>
            <div style={{width: "30%"}} className="bucket">
                <div className="bucket-title">Merged Array</div>
                {mergedArray.map((value, index) => (
                    <div className="sorted-array-value arrayitem" key={index} style={{width: 30, textAlign: "center"}}>{value}</div>
                ))}
            </div>
            <div style={{width: "30%"}} className="bucket">
                <BinaryHeap showAdd={false} showPop={false} key={1} minOrMax="min" nodes={heapNodes} setNodes={setHeapNodes}/>
            </div>
            <div className="bucket" style={{width: "30%"}}>
                <div className="bucket-title">K Sorted Arrays</div>
                {arrays.map((array, index) => (
                    <div className="sorted-array" key={index} style={{marginBottom: 30}}>
                        {array.map((value, j) => (
                            <div className={"sorted-array-value arrayitem "} key={j}  style={{position: "relative", width: 30, textAlign: "center"}}>
                                {value}
                                {j == its[index] && (<span style={{position: "absolute", bottom: -33, left: "23%", color: "lightcoral", fontSize: 30}}>↑</span>)}
                                
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    </div>)
}
function KArraysPlayground({onSuccess, k, removeFirst=true}) {
    let [arraysCopy, setArraysCopy] = useState(generateSortedArrays(k));
    let [arrays, setArrays] = useState(copy(arraysCopy));
    let [total, setTotal] = useState(arrays.reduce((acc, array) => acc + array.length, 0));
    let [its, setIts] = useState(new Array(k).fill(0));
    let [mergedArray, setMergedArray] = useState([]);
    let [error, setError] = useState(false);
    let [success, setSuccess] = useState(false);

    let onClick = (j, index) => {
        if (error) return;
        if (!removeFirst && j < its[index]) return;
        setMergedArray([...mergedArray, arrays[index][j]]);
        for (let i = 0; i < mergedArray.length; i++) {
            if (mergedArray[i] > arrays[index][j]) {
                setError(true);
                return;
            }      
        }
        if (removeFirst) {
            arrays[index].splice(j, 1);
        } else {
            its[index]++;
            setIts(its);
        }
        setArrays([...arrays]);
        if (mergedArray.length+1 == total) {
            setSuccess(true);
            onSuccess(k);
        }
    }
    let restart = () => {
        setError(false);
        setSuccess(false);
        setArrays(copy(arraysCopy));
        setTotal(arraysCopy.reduce((acc, array) => acc + array.length, 0));
        setMergedArray([]);
        setIts(new Array(k).fill(0));
    } 
    return (
<div  className="">
    <div style={{display: "flex", flexDirection: "row", gap: 20, justifyContent: "space-between", alignItems: "stretch", marginBottom: 20}}>
        {success && (<div className="success" style={{marginBottom: 20}}>Success!</div>)}
        {error && (<div className="error">Oops! The merged array is no longer sorted!</div>)}
        <div></div>
        <Button variant="outlined" onClick={() => restart()}>Restart</Button>
    </div>    
    <div style={{display: "flex", flexDirection: "row", gap: 20, justifyContent: "center", alignItems: "stretch"}}>
        <div style={{width: "50%"}} className="bucket">
            <div className="bucket-title">Merged Array</div>
            {mergedArray.map((value, index) => (
                <div className="sorted-array-value arrayitem" key={index} style={{width: 30, textAlign: "center"}}>{value}</div>
            ))}
        </div>
        <div className="bucket" style={{width: "50%"}}>
            <div className="bucket-title">K Sorted Arrays</div>
            {arrays.map((array, index) => (
                <div className="sorted-array" key={index} style={{marginBottom: 30}}>
                    {array.map((value, j) => (
                        <div className={"sorted-array-value arrayitem "} key={j}  style={{position: "relative", width: 30, textAlign: "center"}} onClick={() => onClick(j, index)}>
                            {value}
                            {j == its[index] && !removeFirst && (<span style={{position: "absolute", bottom: -33, left: "23%", color: "lightcoral", fontSize: 30}}>↑</span>)}
                            
                        </div>
                    ))}
                </div>
            ))}
        </div>
    </div>
</div>
    )
    
}


function MergeKSortedArraysSolution() {
    let [steps, setSteps] = useState(0);
    let onSuccess = (k) => {
        setSteps(steps+1);
    }
    return (
        <div className="algocontainer">
            <p>Let's solve this problem for k = 2. Click the number that should go into the merged array next.</p>
            <div className="playground">
                <KArraysPlayground onSuccess={onSuccess} k={2}/>
            </div>
            
            {steps >= 1 && (
                <>
                    <p>Great! Let's now try k = 3.</p>
                    <div className="playground">
                        <KArraysPlayground onSuccess={onSuccess} k={3}/>
                    </div>
                </>
            )}
            
            {steps >= 2 && (
                <>
                    <p>Great! Let's now try k = 4. But before that, as I'm sure you have guessed already, at each step, we add the smallest element out of all first elements, from the K sorted arrays. Once added, that element should be removed from the array to which it belonged, so that the next smallest element in that array becomes the array's first element. But we don't actually have to remove the element from its array. Instead, we can keep an iterator for each array, tracking the current-smallest-element that is not yet added to the merged array. Like this:</p>
                    <div className="playground">
                        <KArraysPlayground onSuccess={onSuccess} k={4} removeFirst={false}/>
                    </div>
                </>
            )}

            {steps >= 3 && (<>
                <p>Great! But how do we calculate the smallest out of all first elements? The bruteforce method is to iterate over all the smallest elements. Is there a faster method?</p>
                {steps == 3 && (<div><Button variant="contained" onClick={() => setSteps(steps+1)}>Show Answer</Button></div>)}
                
            </>)}

            {steps >= 4 && (<>
                <p>The trick is to store the current-element of each array in a min-heap. The size of the min-heap would be K, since it contains one element from each array. At every step, we will pop the smallest element from the heap, add it to our merged array, and add the next element from the popped-element's array into the heap. That's it! Let's see this in action.</p>
                <div className="playground">
                    <KArraysPlaygroundHeaps onSuccess={onSuccess} k={4}/>
                </div>
            </>)}
        </div>
    )
    
}
export { MergeKSortedArraysProblem, MergeKSortedArraysSolution };
// todo: add code https://www.scaler.com/topics/merge-k-sorted-arrays/