import { Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import * as Constants from "../Constants";
import Graph from "../Graph";
import { bfs, genNodes } from "../GraphFunctions";
import { pathCompress } from './UnionFindFunctions';
const specialColor = Constants.HIGHLIGHTED_NODE_COLOR, defaultNodeColor = Constants.DEFAULT_NODE_COLOR;


const Union = ({redrawMethodName="redrawGraph"}) => {
    let [progressStatement, setProgressStatement] = useState("");    
    let [clickedNodes, setClickedNodes] = useState([]);
    let [height, setHeight] = useState({});
    let [nodes, setNodes] = useState([]);
    
    useEffect (() => {
        nodes = genNodes(10);
        setNodes(nodes);
        window[redrawMethodName]({relayout: true}, nodes);
    }, []);
    const forceUpdateTree = (node, field, value) => {
        if (field == "label") return;
        nodes[node.index][field] = value;
        setNodes(nodes);
        window[redrawMethodName](null, nodes);
    }
    const onNodeClick = async (node) => {
        if (clickedNodes.find((n) => n.index === node.index)) {
            forceUpdateTree(node, "fill", defaultNodeColor);
            clickedNodes = clickedNodes.filter((n) => n.index !== node.index);
            setClickedNodes(clickedNodes);   
            return;
        }
        clickedNodes.map((n) => forceUpdateTree(n, "fill", defaultNodeColor));
        clickedNodes = [...clickedNodes, node].slice(-2);
        setClickedNodes(clickedNodes);
        clickedNodes.map((n) => forceUpdateTree(n, "fill", specialColor));
        
        (clickedNodes.length == 1) && setProgressStatement("Click another node.");        
        if (clickedNodes.length === 2) {
            let node1 = clickedNodes[0], node2 = clickedNodes[1];
            clickedNodes = [];
            setClickedNodes([]);
            await onTwoNodesClicked(node1, node2);
        }
    }

    const findRootNoAnimation = async (node) => {
        if (node.parents[0] == null) return node;        
        let result = await findRootNoAnimation(nodes[node.parents[0]]);
        return result;
    }
    const onTwoNodesClicked = async (node1, node2) => {        
        await combine(node1, node2);
    }

    const attachRootsNow = async (root1, root2) => {
        // change parent
        let oldParentIndex = nodes[root1.index].parents[0];
        if (oldParentIndex != null)
            nodes[oldParentIndex].children = nodes[oldParentIndex].children.filter(child => child != root1.index);
        nodes[root1.index].parents = [root2.index];
        nodes[root2.index].children.push(root1.index);
        nodes = bfs(nodes);        
        setNodes(nodes);
        window[redrawMethodName]({relayout: true}, nodes);

        if(height[root1.index] == height[root2.index]) {
            height[root2.index] = height[root2.index] + 1;
            setHeight(height);
        }
        setProgressStatement("Done! Click on two nodes to combine their trees.");
    }

    const combine = async (node1, node2) => {
        let root1 = await findRootNoAnimation(node1);
        let root2 = await findRootNoAnimation(node2);
        if (root1 === root2) {
            setProgressStatement(node1.name + " and " + node2.name + " belong to the same tree already!");
            return;
        }
        await pathCompress({nodes, setNodes, node: node1, root: root1, redrawMethodName, forceUpdateTree, slow: false});
        await pathCompress({nodes, setNodes, node: node2, root: root2, redrawMethodName, forceUpdateTree, slow: false});

        clickedNodes = [];
        setClickedNodes([]);

        if (!height[root1.index]) {
            height[root1.index] = 0;
            setHeight(height);
        }
        if (!height[root2.index]) {
            height[root2.index] = 0;
            setHeight(height);
        }
        let node1Height = height[root1.index], node2Height = height[root2.index];

        if (node1Height > node2Height) {
            setProgressStatement("The two roots are " + root1.name + " & " + root2.name + ". Attach " + root2.name + " as a subtree of " + root1.name + " since " + root1.name + "'s depth is bigger than " + root2.name + "'s depth.");
            await attachRootsNow(root2, root1);
            
        } else if (node1Height < node2Height) {
            setProgressStatement("The two roots are " + root1.name + " & " + root2.name + ". Attach " + root1.name + " as a subtree of " + root2.name + " since " + root2.name + "'s depth is bigger than " + root1.name + "'s depth.");
            await attachRootsNow(root1, root2);
        } else {
            setProgressStatement("The two roots are " + root1.name + " & " + root2.name + ". Attach " + root2.name + " as a subtree of " + root1.name + " since " + root2.name + " and " + root1.name + " have the same depth. Then we'll increase " + root1.name + "'s depth by one.");
            await attachRootsNow(root2, root1);
        }
        
    }

    return (
<div className="unionfind">
    <div>
        {progressStatement && <Typography variant="body1" sx={{mb: 1}}>{progressStatement}</Typography>}
    </div>    
    <div>
        <Graph nodes={nodes} setNodes={setNodes} onNodeClick={onNodeClick} redrawMethodName={redrawMethodName}/>                                
    </div>
</div>
      );
}

export default Union;