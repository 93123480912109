import * as Constants from "../Constants";

const waitTimeMs = 400, pathCompressionColor = Constants.HIGHLIGHTED_NODE_COLOR, color2 = "#0c8fc1", color1 = Constants.DEFAULT_NODE_COLOR;
function sleep(ms=waitTimeMs) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const findRoot = async ({nodes, node, shouldPathCompress, forceUpdateTree}) => {
    forceUpdateTree(node, "fill", color2);
    await sleep();

    let parentNode = nodes[node.parents[0]];      
    if (parentNode == null) {
        forceUpdateTree(node, "fill", shouldPathCompress ? pathCompressionColor : color1);
        return node;
    }        
    
    let result = await findRoot({node:parentNode, nodes, shouldPathCompress, forceUpdateTree});
    await sleep();        
    forceUpdateTree(node, "fill", shouldPathCompress ? pathCompressionColor : color1);
    return result;
}
const pathCompress = async ({nodes, setNodes, node, root, forceUpdateTree, redrawMethodName, slow = true}) => {
    let parentIndex = node.parents[0];
    if (parentIndex == null || node.parents[0] == root.index) {
        forceUpdateTree(node, "fill", color1);
        forceUpdateTree(nodes[root.index], "fill", color1);
        return;
    }    
    forceUpdateTree(node, "fill", pathCompressionColor);
    slow && await sleep();
    
    // change parent
    let oldParentIndex = nodes[node.index].parents[0];
    nodes[oldParentIndex].children = nodes[oldParentIndex].children.filter(child => child != node.index);
    nodes[node.index].parents = [root.index];
    nodes[root.index].children.push(node.index);

    setNodes(nodes);
    window[redrawMethodName]({relayout: true}, nodes);

    slow && await sleep();
    forceUpdateTree(node, "fill", color1);
    slow && await sleep();
    await pathCompress({node: nodes[parentIndex], nodes, setNodes, root, forceUpdateTree, redrawMethodName});
}
export {findRoot, pathCompress}