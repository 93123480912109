
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import './App.css';
import { BacktrackingAlgo } from "./BacktrackingAlgo";
import { BFSAlgo } from "./BFSAlgo";
import { BinaryHeapAlgo } from "./BinaryHeapAlgo";
import BinarySearch from "./BinarySearch";
import CountIslands from "./CountIslands";
import { DFSAlgo } from "./DFSAlgo";
import { DijkstraAlgo } from "./DijkstraAlgo";
import EqualityEquations from "./EqualityEquations";
import FruitsIntoBaskets from "./FruitsIntoBaskets";
import FruitsIntoBasketsSolution from "./FruitsIntoBasketsSolution";
import KMPAlgo from "./KMPAlgo";
import LevelOrder from "./LevelOrder";
import LevelOrderSuccessor from "./LevelOrderSuccessor";
import MaxSubarraySumSizeK from "./MaxSubarraySumSizeK";
import MaxSubarraySumSizeKSolution from "./MaxSubarraySumSizeKSolution";
import MedianNumberStream from "./MedianNumberStream";
import { MergeKSortedArraysProblem, MergeKSortedArraysSolution } from "./MergeKSortedArrays";
import { MergeSortAlgo } from "./MergeSortAlgo";
import { MinCostStepper } from "./MinCostStepper";
import MinCostTickets from "./MinCostTickets";
import MinDepth from "./MinDepth";
import { MinPathSumStepper } from "./MinPathSumStepper";
import NetworkDelayTime from "./NetworkDelayTime";
import NetworkDelayTimeSolution from "./NetworkDelayTimeSolution";
import { AlienDictionarySolution } from "./problems/AlienDictionarySolution";
import CountIslandsSolution from "./problems/CountIslandsSolution";
import EqualityEquationsSolution from "./problems/EqualityEquationsSolution";
import LevelOrderSuccessorSolution from "./problems/LevelOrderSuccessorSolution";
import LevelOrderTraversalSolution from "./problems/LevelOrderTraversalSolution";
import MedianNumberStreamSolution from "./problems/MedianNumberStreamSolution";
import MinCostTicketsSolution from "./problems/MinCostTicketsSolution";
import MinDepthSolution from "./problems/MinDepthSolution";
import { ProbAlienDictionary } from "./problems/ProbAlienDictionary";
import ReverseLevelOrderSolution from "./problems/ReverseLevelOrderSolution";
import { ProblemStepper } from "./ProblemStepper";
import { QuickSortAlgo } from "./QuickSortAlgo";
import ReverseLevelOrder from "./ReverseLevelOrder";
import { SlidingWindowAlgo } from "./SlidingWindowAlgo";
import { TopsortAlgo } from "./ToposortAlgo";
import { UnionFindAlgo } from "./UnionFindAlgo";
import Wrapper from "./Wrapper";

const theme = createTheme({
  
  typography: {
    body2: {
        // color: "#fff"
    },
    body1: {
      // color: "#fff"
    }
  },
  palette: {
    primary: {
      main: "#47b6d2", //"#e3a424",
      contrastText: "#fff" 
    },
    secondary: {
      main: "#19857b", 
      contrastText: "#fff" 
    },
  }
});
const constChapters = [{
  name: "Binary Heap",
  children: [{
    name: "Algorithm",
    component: <BinaryHeapAlgo />
  }, {
    name: "Median of a Number Stream", // TODO code is different from visualization
    component: <ProblemStepper title="Median of a Number Stream" problem=<MedianNumberStream/> solution=<MedianNumberStreamSolution/> />
  }, {
    name: "Merge K Sorted Arrays",
    component: <ProblemStepper title="Merge K Sorted Arrays" problem=<MergeKSortedArraysProblem/> solution=<MergeKSortedArraysSolution/> />
  }]
}, {
  name: "Backtracking",
  children: [{
    name: "Algorithm (wip)",
    component: <BacktrackingAlgo />
  }]
}, {
  name: "Sliding Window",
  children: [{
    name: "Algorithm",
    component: <SlidingWindowAlgo />
  }, {
    name: "Fruits Into Baskets",
    component: <ProblemStepper title="Fruits Into Baskets" problem=<FruitsIntoBaskets/> solution=<FruitsIntoBasketsSolution/> />  
  }, {
    name: "Maximum Sum Subarray",
    component: <ProblemStepper title="Maximum Sum Subarray" problem=<MaxSubarraySumSizeK/> solution=<MaxSubarraySumSizeKSolution/> />
  }]
}, {
  name: "Binary Search",
  children: [{
    name: "Algorithm",
    component: <BinarySearch />
  }]
}, {
  name: "Breadth First Search",
  children: [{
    name: "Algorithm",
    module: 0,
    component: <BFSAlgo/>
  }, {
    name: "Minimum Depth",
    module: 2,
    component: <ProblemStepper title="Minimum Depth (wip)" problem=<MinDepth/> solution=<MinDepthSolution/> />
  }, {
    name: "Level Order Traversal",
    component: <ProblemStepper title="Level Order Traversal" problem=<LevelOrder/> solution=<LevelOrderTraversalSolution /> />
  }, {
    name: "Reverse Level Order Traversal",
    component: <ProblemStepper title="Reverse Level Order Traversal" problem=<ReverseLevelOrder/> solution=<ReverseLevelOrderSolution /> />
  }, /*, {
    name: "Zigzag Traversal",
    component: <ProblemStepper title="Zigzag Traversal" problem=<Zigzag/> solution=<div>TODO: ZigzagTraversal</div>></ProblemStepper>
  }, */{
    name: "Level Order Successor",
    component: <ProblemStepper title="Level Order Successor" problem=<LevelOrderSuccessor/> solution=<LevelOrderSuccessorSolution /> />
  }]
}, {
  name: "Depth First Search",
  children: [{
    name: "Algorithm",
    module: 0,
    component: <DFSAlgo/>
  }, {
    name: "Number of Islands",
    component: <ProblemStepper title="Number of Islands" problem=<CountIslands/> solution=<CountIslandsSolution/> />
  }]
}, {
  name: "Dijkstra's Algorithm",
  children: [{
    name: "Algorithm",
    module: 0,
    component: <DijkstraAlgo />
  }, {
    name: "Network Delay Time (wip)",
    component: <ProblemStepper title="Network Delay Time" problem=<NetworkDelayTime/> solution=<NetworkDelayTimeSolution/> />
  }]
}, {
  name: "Topological Sort",
  children: [{
    name: "Algorithm",
    module: 0,
    component: <TopsortAlgo/>
  }, {
    name: "Alien Dictionary",
    component: <ProblemStepper title="Alien Dictionary" problem=<ProbAlienDictionary/> solution=<AlienDictionarySolution/> />  
  }]
}, {
  name: "Union Find",
  children: [{
    name: "Algorithm",
    module: 0,
    component: <UnionFindAlgo/>
  }, {
    name: "Satisfiability of Equality Equations",
    component: <ProblemStepper title="Satisfiability of Equality Equations" problem=<EqualityEquations/> solution=<EqualityEquationsSolution/> />
  }]
}, {
  name: "KMP",
  children: [{
    name: "Algorithm (wip)",
    component: <KMPAlgo />
  }]
}, {
  name: "Sorting",
  children: [{
    name: "Quicksort",
    component: <QuickSortAlgo />
  }, {
    name: "Mergesort (wip)",
    component: <MergeSortAlgo />
  }]
}, {
  name: "Dynamic Programming",
  children: [{
    name: "Algorithm"
  }, {
    name: "Climbing Stairs",
    module: 0,
    component: <MinCostStepper />
  }, {
    name: "Minimum Path Sum (wip)",
    module: 0,
    component: <MinPathSumStepper />    
  }, {
    name: "Min Cost Tickets",
    module: 0,
    component: <ProblemStepper title="Min Cost Tickets" problem=<MinCostTickets/> solution=<MinCostTicketsSolution/> />  
  }]
}];
function AppVisuallyExplain({loggedout=true}) {
  let [title, setTitle] = useState("Visual Explanations of Algorithms");
  let [chapters, setChapters] = useState(constChapters);

  return (
      <ThemeProvider theme={theme}>
          <Routes>
            {chapters.map((chapter, index) => (
              chapter.children.map((child, index) => 
                (<Route path={"/" + chapter.name.replaceAll(" ", "-").toLowerCase() + "/" + child.name.replaceAll(" ", "-").toLowerCase()} element=<Wrapper child={child} chapters={chapters} chapter={chapter} loggedout={loggedout}/> />)
              )
            ))}
            <Route path="*" element=<Wrapper child={chapters[0].children[0]} chapters={chapters} chapter={chapters[0]} loggedout={loggedout}/> />
          </Routes>
      </ThemeProvider>   
  );
}

export default AppVisuallyExplain;
