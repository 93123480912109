const { useState, useEffect, useRef } = require("react")
const d3 = require("d3")

const calculateAvailableWidth = () => {
  return document.getElementById("svgcontainer").offsetWidth;
};
const Graph = ({nodes=[], height=400, setNodes, onNodeClick, weights, fadeOutEdges=false, redrawMethodName="redrawGraph", isBinary=false, forceManyBody=false, redrawWhenNodesChange=false, layoutKey}) => {
  const ref = useRef();
  let [links, setLinks] = useState([]);
  let [w, setWidth] = useState(100);
  let [h, setHeight] = useState(height);

  // useEffect (() => {
  //   if (redrawWhenNodesChange) {
  //     redraw();
  //   }      
  // }, [nodes]);

  // useEffect (() => {
  //   redraw({relayout: true}, nodes);       
  // }, [layoutKey]);
 

  const reLink = () => { // THIS IS A HACK. d3's simulation stupidly changes the links that we supply.
    links = []
    for (let i=0; i<nodes.length; i++) {
      nodes[i].index = i;
      nodes[i].name = nodes[i].label;
      for (let j=0; j<nodes[i].parents.length; j++) {
        let link = {
          source: nodes[nodes[i].parents[j]], 
          target: nodes[i]
        };
        if (weights) {
          link.weight = weights[nodes[i].parents[j]][nodes[i].index];
          console.log("loading weight", nodes[i].parents[j], nodes[i].index, link.weight)
        }
        links.push(link);
      }   
    }
    setLinks(links);
    setNodes(nodes);
  }

  const relayout = () => {  
    reLink();
    const dist = 100;

    // sorting needed by the custom force
    for (var i in nodes) {
      if (nodes[i].children)
        nodes[i].children = nodes[i].children.sort((a, b) => a.index - b.index);
    }
    setNodes(nodes);

    var simulation = d3.forceSimulation(nodes);
    if (forceManyBody)
      simulation = simulation.force('charge', d3.forceManyBody().strength(-500).distanceMax(200));

    simulation = simulation
      .force('center', d3.forceCenter(w/2, height/2))
      .force('link', d3.forceLink().links(links).distance(50))
      .force("custom", () => {
        // console.log("=== CUSTOM FORCE IS CALLED w: " + w + ", h: " + h)
        nodes.forEach(node => {
          if (node.level >= 0) { // if level is set, we assume that it's a tree on which BFS was done
            node.y = 10 + (node.level+1) * 50;
            let parentIndex = nodes[node.index].parents[0];
            if (parentIndex != null) {
              let cnt = isBinary ? 2 : nodes[parentIndex].children.length;
              let pos = nodes[parentIndex].children.findIndex((c) => c == node.index);
              let startingPosition = nodes[parentIndex].x + dist/2 - ((cnt)/2) * dist;
              node.x = (pos) * dist + startingPosition;
              // console.log(nodes[parentIndex].label + ".x = " + nodes[parentIndex].x);
              // console.log(node.label + ".x = " + node.x + ", pos = " + pos + ", cnt = " + cnt)
            }
          }          
          if (node.x < 10) node.x = 10;
          if (node.x > w-10) node.x = w-10;
          if (node.y < 10) node.y = 10;
          if (node.y > h-10) node.y = h-10;
          // console.log(node.label + " = " + node.x + ", " + node.y )
          return this;
        })
      })      
      .force('collision', d3.forceCollide().radius(function(d) {
        return 30 //d.radius
      }))   
      .stop();
    simulation.tick(300);
  }
  const redraw = (opts, newNodes) => {
    if(newNodes) nodes = newNodes;
    if (opts?.relayout) {
      relayout();
    }
    if (opts?.redrawEdges) {
      reLink();
    }
    ticked();
  }

const drawNodes = () => {  
  var node = d3.select(ref.current)
      .select('.nodes')
      .selectAll('.node')
      .data(nodes)
      .join(
        enter => enter.append("g")
            .style("fill", (d) => d.fill || "url(#MyGradient)")
            .text(d => d),
        update => update
      )
      .attr("class", (d) => "node" + (d.children ? " node--internal" : " node--leaf"))
      .attr("transform", (d) => {
        return "translate(" + d.x + "," + d.y + ")"
      });
    node.select('circle').remove();
    node.append("circle")
      .attr("r", 15)
      .style("stroke", (d) => d.stroke)
      .style("fill", (d) => {
        // console.log(d.index + "(label = " + d.label + ") = " + d.fill)
        return d.fill
      })
      .on('click', (e, thisNode) =>  {
        onNodeClick(thisNode);
      });
    
    node.select('text').remove();
    node.append('text')
      .text((d) => typeof(d.label.fn) == "function" ? d.label.fn(d) : d.label)
      .attr('x', (d) => -1)
      .attr('y', (d) => 5)
      .on('click', (e, thisNode) =>  {
        onNodeClick(thisNode);
      });
}
  
const drawEdges = () => {
    // This is a hack. First I draw transparent edges, then place labels along them.
    // Then I draw the actual edges. 
    var edgepaths = d3.select(ref.current).selectAll(".edgepath")
      .data(links)
      .join('path')
      .attr('d', d => (
        (+d.source.x < +d.target.x) ?  
        ('M '+ d.source.x + ' ' + d.source.y + ' L ' + d.target.x + ' ' + d.target.y) : 
        ('M '+ d.target.x + ' ' + d.target.y + ' L ' + d.source.x + ' ' + d.source.y)
      ))
      .attr('class','edgepath')
      .attr('fill-opacity', 0)
      .attr('stroke-opacity', 0)
      .attr('fill', '#000')
      .attr('stroke','#000')
      .attr('id', function(d,i) {return 'edgepath'+i})
      .style("pointer-events", "none");
      
    let edgelabel = d3.select(ref.current).selectAll(".edgelabel");
    edgelabel.select('text').remove();
    edgelabel
        .data(links)
        .join('text')
        .style("pointer-events", "none")
        .attr('class', 'edgelabel')
        .attr('id', (d,i) => 'edgelabel'+i)
        // .attr('font-size', 12)
        .attr('dx', 50)
        .attr('dy', 14);
    edgelabel.select('textPath').remove();
    edgelabel.append('textPath')
        .attr('xlink:href',function(d,i) {return '#edgepath'+i})
        .style("pointer-events", "none")
        .text(d => d.weight);

    let link = d3.select(ref.current)
      .select('.links')
      .selectAll('line')
      .data(links)
      .attr("marker-end", "url(#end)");
    
    link
      .join(
        enter => enter.append("line")
            .attr("marker-end", "url(#end)"),
        update => update.attr("fill", "red"),
        exit => exit
        .transition()
        .duration(fadeOutEdges ? 1000 : 0)
        .style('opacity', 0.01)
        .delay(0)
        .remove()
      )
      .attr('x1', (d) => d.source.x)
      .attr('y1',(d) => d.source.y)
      .attr('x2', (d) => d.target.x)
      .attr('y2', (d) => d.target.y);   
}
const ticked = () => {
    drawEdges();
    setTimeout(() => {  
      drawNodes();
    }, 100);
}

  useEffect(() => {
    window[redrawMethodName] = redraw;
    d3.select(ref.current).select("defs").selectAll("marker")
      .data(["end"])      // Different link/path types can be defined here
    .enter().append("svg:marker")    // This section adds in the arrows
      .attr("id", String)
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 25)
      .attr("refY", 0.5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto")
      .attr('fill', '#000')
    .append("svg:path")
      .attr("d", "M0,-5L10,0L0,5");

    w = calculateAvailableWidth();
    setWidth(w);
    redraw({relayout:true});
  }, [])

  return (
    <div id="svgcontainer" style={{width:"100%", height:"100%"}}>
      <svg overflow="visible" width={w} height={h}  ref={ref}>
        <g className="links"></g>
        <g className="nodes"></g>
        <defs>
          <radialGradient id="MyGradient">
            <stop offset="10%" stopColor="#47b6d2" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  )
}
export default Graph