import React from "react";

const problem = `The median is the middle value in an ordered integer list. If the size of the list is even, there is no middle value, and the median is the mean of the two middle values.

In [2,3,4], the median is 3.
In [2,3], the median is (2 + 3) / 2 = 2.5.
Implement a class with these two methods:
void insertNum(int num) adds a number to the data structure.
double findMedian() returns the median of all elements so far.`, 

examples = [`Example:
insertNum(3) // numbers are [3], no output is needed
insertNum(1) // numbers are [1, 3], no output is needed
findMedian() // output: 2
insertNum(5) // numbers are [1, 3, 5], no output is needed
findMedian() // output: 3
insertNum(4) // numbers are [1, 3, 4, 5], no output is needed
findMedian() // output: 3.5`];

export default function MedianNumberStream() {
    return (
        <div style={{textAlign: "left"}}>
            <pre>{problem}</pre>
            {examples.map((example, index) => (
                <div key={index} className="example">
                    <pre>{example}</pre>
                </div>
            ))}
        </div>)
}