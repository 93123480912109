import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { bruteforce, calculateLPS, kmp } from "./KMPFunctions.jsx";

export default function KMPBoth(props) {
    let [pattern, setPattern] = useState(props.pattern); //genPattern(4) + genPattern(3));
    let [text, setText] = useState(props.text); //genString(40, 2)); //genText(40, pattern));

    let [bfi, setBFi] = useState(props.i);
    let [bfj, setBFj] = useState(props.j);
    let [i, setI] = useState(props.i);
    let [j, setJ] = useState(props.j);
    let [nextBackKey, setNextBackKey] = useState("Next");
    
    useEffect (() => {        
        setI(props.i);
        setJ(props.j);
        setBFi(props.i);
        setBFj(props.j);
        setStack([]);  
    }, []);
    let getTextLetterCls = (k, ii, jj) => {
        let s = (ii+jj >= k && ii <= k)? "highlighted kmp-cell" : "kmp-cell";
        if (ii+jj == k) {
            s += (text[ii+jj] == pattern[jj] ? " matched" : " unmatched");
        }
        return s;       
    }
    let getPatternLetterCls = (k, ii, jj) => {
        let s = jj >= k ? "highlighted kmp-cell" : "kmp-cell";
        if (jj == k)
            s += (text[ii+jj] == pattern[jj] ? " matched" : " unmatched");
        return s;
    }
    let [stack, setStack] = useState([]);
    let nextback = async () => {
        
        if (nextBackKey == "Next") {
            setNextBackKey("Back");
            next();
        }
        else {
            setNextBackKey("Next");
            back();
        }
    }
    let next = async () => {
        stack.push({i, j, bfi, bfj});
        kmp({text, pattern, lps: calculateLPS({pattern}), i, j, setI, setJ});
        bruteforce({text, pattern, i: bfi, j: bfj, setI: setBFi, setJ: setBFj});
    }

    let back = () => {
        if (stack.length > 0) {
            let {i, j, bfi, bfj} = stack.pop();
            setI(i);
            setJ(j);
            setBFi(bfi);
            setBFj(bfj);            
        }
    }
    function Space({ii}) {
        return (
            text.substring(0, ii).split("").map((t, k) => (<div className="kmp-cell opaque">{t}</div>))
        )
    }
    return (
<div className="">            
    <div>
        {text.split("").map((t, k) => (<div className="kmp-cell" key={k}>
            <div style={{opacity: (i == k) ? 1 : 0.5, fontSize:12, textAlign: "center"}}>{k}</div>
            <div className={getTextLetterCls(k, i, j)}>{t}</div>
        </div>))}
    </div>
    
    <div style={{display:"inline-block"}}>
        <Space ii={i}/>
        {pattern.split("").map((p, k) => (<div className="kmp-cell" key={k}>
            <div className={getPatternLetterCls(k, i, j)}>{p}</div>
            <div style={{opacity: (j == k) ? 1 : 0.5, fontSize:12, textAlign: "center"}}>{k}</div>                            
        </div>))}                                
    </div>
    <div style={{display:"inline-block", marginTop: 3, marginLeft: 10, verticalAlign: "top"}}>← KMP (i={i}, j={j})</div>
    <div></div>
    <div style={{marginTop: 20}}>
        {text.split("").map((t, k) => (<div className="kmp-cell" key={k}>
            <div style={{opacity: (bfi == k) ? 1 : 0.5, fontSize:12, textAlign: "center"}}>{k}</div>
            <div className={getTextLetterCls(k, bfi, bfj)}>{t}</div>
        </div>))}
    </div>                              
    <div style={{display:"inline-block"}}>
        <Space ii={bfi}/>
        {pattern.split("").map((p, k) => (<div className="kmp-cell" key={k}>
            <div className={getPatternLetterCls(k, bfi, bfj)}>{p}</div>
            <div style={{opacity: (bfj == k) ? 1 : 0.5, fontSize:12, textAlign: "center"}}>{k}</div>                            
        </div>))}
    </div>
    
    <div style={{display:"inline-block", marginTop: 3, marginLeft: 10, verticalAlign: "top"}}>← Bruteforce (i={bfi}, j={bfj})</div>
    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20}}>                
        <Button style={{}} variant={nextBackKey == "Next" ? "contained" : "outlined"} onClick={() => nextback() }>{nextBackKey == "Next" ? "What happens next?" : "Go back"}</Button>
        <Button style={{}} variant="contained" onClick={() => props.nextExample() } disabled={false && nextBackKey == 'Next'}>See Next Example</Button>
    </div>
    
</div>
)}