import { QuickSort } from "./QuickSort";
import { QuickSortPartition } from "./QuickSortPartition";

export const QuickSortAlgo = () => {
    return (
<div className="algocontainer">
    <p>Quicksort is a recursive, in-place, sorting algorithm. In each call, it partitions the current subarray further into two subarrays, with numbers smaller than the "pivot" to the left, and numbers greater than the "pivot" to the right. Then it recursively sorts the left and the right subarrays. The tricky bit to understand is the partition logic, which runs in-place, that is, without using extra memory. Let's take a look at that first, and then move on to the full algorithm.</p>

    <QuickSortPartition />
    <p>Once you've seen the logic of partitioning, quicksort itself is simple. Here is the full algorithm:</p>
    <QuickSort />
</div>
)}