import TopologicalSort from "./TopologicalSort";
export const TopsortAlgo = () => {
return (
<div className="algocontainer">
    <p>Topological Sorting is an ordering of a directed graph's vertices such that for every edge u -> v, u comes before v in the sorted order. A graph might have multiple topological sorted orders, and problems usually ask you to print just one of them. This algorithm is also used to detect cycles in a graph, since a cyclic graph will have no valid topological order.</p>
    <p>Kahn's algorithm is used here to implement Topological sort. Here, at each step, we find all nodes that have no incoming edges, add them to our topologically sorted order, and remove all outgoing edges of those nodes. We continue until this process is no longer possible, either because we removed all edges and added all nodes to our topologically sorted order, or because we are stuck with a few node that have incoming edges. If it's the latter, then we can deduce that the graph has a cycle, and cannot be topologically sorted.</p>

    <div className="">
        <TopologicalSort />
    </div>
</div>
)}