import { Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function BinarySearch({chapters, module}) {
    let [size, setSize] = useState(100);
    let [numbers, setNumbers] = useState([]);
    let [numberToFind, setNumberToFind] = useState(45);
    let [steps, setSteps] = useState(0);
    let [goodResult, setGoodResult] = useState(null);
    let [badResult, setBadResult] = useState(null);
    let [low, setLow] = useState(0);
    let [high, setHigh] = useState(0);
    let [mid, setMid] = useState(0);
    let [progressStatement, setProgressStatement] = useState("");

    useEffect(() => {
        regenerateSortedRandomNumbers();
    }, []);

    const resetNumberToFind = (num) => {
        setNumberToFind(num);
        reset(numbers.length);
        setProgressStatement("Searching for " + num + " in the highlighted subarray");

    }
    const regenerateSortedRandomNumbers = () => {
        let newNumbers = [];
        for (let i = 0; i < size; i++) {
            newNumbers.push(Math.floor(Math.random() * 1000));
        }
        newNumbers.sort((a, b) => a - b);
        setNumbers(newNumbers);
        reset(newNumbers.length);       
    }
    const reset = (length) => {
        setSteps(0);
        setGoodResult(null);
        setBadResult(null);
        setProgressStatement("Searching for " + numberToFind + " in the highlighted subarray");
        setLow(0);
        setHigh(length - 1);
        setMid(Math.floor((low + high) / 2))           
    }
    const takeStep = () => {
        console.log(low, high)
        let mid = Math.floor((low + high) / 2);
        setMid(mid); 
        console.log("mid = " + mid)
        console.log("mid num = " + numbers[mid])
        if (numbers[mid] == numberToFind) {
            setGoodResult("Found " + numberToFind + "! It is at position " + mid + ".")
            setProgressStatement("");
            setLow(mid)
            setHigh(mid);
            return
        }           
        if (low == high) {
            setBadResult("Could not find " + numberToFind)
            setProgressStatement("");
            return
        }
        console.log(low, high)
        if (low < high) {
            setSteps(steps + 1);
            let mid = Math.floor((low + high) / 2);
            setMid(mid);
            console.log("mid num = " + numbers[mid])
            if (numbers[mid] === numberToFind) {
                setGoodResult("Found " + numberToFind + "! It is at position " + mid + ".")
                setProgressStatement("");
            } else if (numbers[mid] < numberToFind) {
                setLow(mid + 1);
                console.log(low, high)
                setProgressStatement("Found " + numbers[mid] + " in the middle. It is smaller than " + numberToFind + ", so we'll search in the right half.");
            } else {
                setHigh(mid - 1);
                console.log(low, high)
                setProgressStatement("Found " + numbers[mid] + " in the middle. It is bigger than " + numberToFind + ", so we'll search in the left half.");
            }
        } else {
            setBadResult("Could not find " + numberToFind)
            setProgressStatement("");
        }
    }
    return (
<div className="algocontainer">
    <div className="bucket">
        <div className="bucket-title">Problem</div>
        <div>
            Find the number <TextField className="textfield"  size="small" value={numberToFind} sx={{ mt: 2, mb: 2 }} onChange={(e) => resetNumberToFind(e.target.value)}/> in this array of <TextField className="textfield" size="small" value={size} sx={{ mt: 2, mr: 1 }} onChange={(e) => setSize(e.target.value)}/> numbers.
            <div className="numbers">
                {numbers.join(", ")}        
            </div>
            {/* <div>
                <Button size="small" variant="outlined" onClick={() => regenerateSortedRandomNumbers()} fullWidth="true" sx={{mt: 1}}>Change the numbers</Button> 
            </div> */}
        </div>
    </div>
    <div className="bucket" style={{marginTop: 20}}> 
        <div className="bucket-title">Solution</div>
        <div>
            <div>
            {progressStatement && <Typography variant="body1" sx={{mb: 1}}>{progressStatement}</Typography>}
            {goodResult && <Typography variant="body1" className="goodresult">{goodResult}</Typography>}
            {badResult && <Typography variant="body1" className="badresult">{badResult}</Typography>}

            </div>
            <div className="numbers" key={low+"_"+high}>
                <span className="not-highlighted">{[...numbers.slice(0, low), ""].join(", ")}</span>
                <span className="highlighted">{[...numbers.slice(low, high+1), ""].join(", ")}</span>
                <span className="not-highlighted">{[...numbers.slice(high+1, numbers.length), ""].join(", ")}</span>                        
            </div>   
            
            {!goodResult && !badResult &&
            <Button sx={{mt: 1, mb: 1}} fullWidth="true" size="small" variant="contained" onClick={() => takeStep()}>Next Step</Button>
            }
            {steps>0 && <Typography variant="body1" className="clicks">Button Clicks: {steps}</Typography>}      
        </div>
    </div>
</div>
    )
}