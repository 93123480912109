import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useEffect, useState } from "react";
import BinaryHeap from "./BinaryHeap";
import { bfs, genCompleteBinaryTree } from "./GraphFunctions";



export const BinaryHeapAlgo = () => {
    let [minOrMax, setMinOrMax] = useState('min');
    let [smallest, setSmallest] = useState("smallest");
    let [smaller, setSmaller] = useState("smaller");
    let [nodes1, setNodes1] = useState([]);
    let [nodes2, setNodes2] = useState([]);

    useEffect(() => {
        nodes1 = genNodes();
        setNodes1(nodes1);
        nodes2 = genNodes();
        setNodes2(nodes2);    
    }, [])
    let genNodes = () => {
        let nodes = genCompleteBinaryTree(4);
        if (minOrMax == "max") {
            for (let i = 0; i < nodes.length; i++) {
                nodes[i].label = 10 + (nodes.length - i - 1) * 10; // switch labels order
            }
        }
        return bfs(nodes);
    }

    const handleChange = (event, m) => {
        if (m == null) return;
        minOrMax = m;
        setMinOrMax(m);
        setSmallest(m == "min" ? "smallest" : "biggest");
        setSmaller(m == "min" ? "smaller" : "bigger");
        
        nodes1 = genNodes();
        setNodes1(nodes1);
        nodes2 = genNodes();
        setNodes2(nodes2);              
    };

    return (
<div className="algocontainer">
    <div style={{textAlign: "center"}}>
        <ToggleButtonGroup color="primary" value={minOrMax} exclusive onChange={handleChange} aria-label="Heap Type">
            <ToggleButton value="min">Min Heap</ToggleButton>
            <ToggleButton value="max">Max Heap</ToggleButton>
        </ToggleButtonGroup>
    </div>
    <p>Binary Heap is the data structure that is often used to implement Priority Queues. It has 2 efficient methods:</p>
    <div style={{marginBottom: 10}}>
        <span className="highlight">Pop {smallest} element</span> O(logn) time complexity
    </div>
    <div>
        <span className="highlight">Add an element</span> O(logn) time complexity
    </div>
    <p>In a {minOrMax}-heap, the invariant is that every parent node's value is {smaller} than its children's values. Since the root always has the {smallest} value in the entire heap, looking up the {smallest} value is a O(1) query. When you add a new value, it is added to the deepest level, and then propagated upwards, such that the invariant holds true. Go ahead and add a few numbers to this {minOrMax}-heap.</p>
    <BinaryHeap showAdd={true} key={1} redrawMethodName="redraw1" minOrMax={minOrMax} nodes={nodes1} setNodes={setNodes1}/>

    <p>As you can see, the heap is stored as a complete binary tree. All levels are fully filled, except the deepest one. When you pop the {smallest} element, we remove the root. Then, we remove the last element in the last level, and add that as the root. Then we propagate it downwards, such that every parent's value is {smaller} than its children's values. Try it out.</p>
    <BinaryHeap showAdd={true} showPop={true} key={2} redrawMethodName="redraw2" minOrMax={minOrMax} nodes={nodes2} setNodes={setNodes2}/>
</div>
)}