import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {genGraph, bfs} from "../GraphFunctions";
import Graph from "../Graph.jsx";
import {findRoot, pathCompress} from './UnionFindFunctions';

function rand(n) {
    return parseInt((Math.random() * 10000000000000) % n);
}
const Find = ({shouldPathCompress=true, redrawMethodName="redrawGraph"}) => {
    let [nodes, setNodes] = useState([]);
    let [progressStatement, setProgressStatement] = useState("");    
    let [pathCompressionPair, setPathCompressionPair] = useState([null, null]);

    useEffect(() => {
        nodes = bfs(genGraph(10, true, () => 1, () => rand(10/2)));
        setNodes(nodes);
        window[redrawMethodName]({relayout: true}, nodes);
    }, []);

    const onNodeClick = (node) => {
        findRootUI(node);
    }
    const findRootUI = async (node) => {
        setProgressStatement("Finding root node of " + node.label + "...");
        setPathCompressionPair([]);        
        if (node.parents[0] == null) {
            setProgressStatement(node.label + " is its own root.");
            return;
        }
        let parentNode = nodes[node.parents[0]];
        if (parentNode != null && parentNode.parents[0] == null) {
            setProgressStatement(node.label + " is attached to its root.");
            return;
        }

        let result = await findRoot({node, nodes, shouldPathCompress, forceUpdateTree});        
        let stmt = "The root of " + node.label + " is " + result.label + ".";
        setProgressStatement(stmt + " It is also the root of all the nodes between " + node.label + " and " + result.label + ".");
        setPathCompressionPair([node, result]);    
    }
    const forceUpdateTree = (node, field, value) => {
        nodes[node.index][field] = value;
        setNodes(nodes);
        window[redrawMethodName](null, nodes);
    }

    const pathCompressUI = async (node, root) => {
        setProgressStatement("Path Compression: Attaching all the nodes between " + root.label + " and " + node.label + " directly to " + root.label + ".");        
        await pathCompress({node, root, nodes, setNodes, forceUpdateTree, redrawMethodName});
        setPathCompressionPair([]);
        setProgressStatement("Path Compression is complete! Next time, you can quickly find the root of these nodes, since they're all directly attached to their root. Click on another node to find its root.")
    }
    return (
    <div className="unionfind">
        <div>
            {progressStatement && <Typography variant="body1" sx={{mb: 1}}>{progressStatement}</Typography>}
        </div>
        {shouldPathCompress && pathCompressionPair[0] && (
            <Button sx={{mt: 1, mb: 1}} fullWidth="true" size="small" variant="contained" onClick={() => pathCompressUI(pathCompressionPair[0], pathCompressionPair[1])}>Do Path Compression on the nodes between {pathCompressionPair[0].label} and {pathCompressionPair[1].label}</Button>
        )}
        <div>
            <Graph nodes={nodes} setNodes={setNodes} onNodeClick={onNodeClick} redrawMethodName={redrawMethodName}/>                             
        </div>
    </div>
)}

export default Find;
