import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CallStack from "./CallStack";
import Code from "./Code";
import { Array } from "./problems/Array";
import { generateInput, getClassName } from "./QuickSortFunctions";

function sleep() {
    return new Promise(resolve => setTimeout(resolve, 100));
}
const code = 
`quicksort(lo, hi): 
  if lo >= hi
    return    
  p = partition(lo, hi)       
  quicksort(lo, p - 1)
  quicksort(p + 1, hi)`.split("\n");

export const QuickSort = () => {
    let [input, setInput] = useState([]);
    let [pivot, setPivot] = useState(-1);
    let [lo, setLo] = useState(0);
    let [hi, setHi] = useState(0);
    let [stack, setStack] = useState([]);
    let [incrementingKey, setIncrementingKey] = useState(0);
    let [waiting, setWaiting] = useState(false);
    let [i , setI] = useState(0);
    let [j, setJ] = useState(0);
    let [info, setInfo] = useState("");
    let [pivotIndex, setPivotIndex] = useState(-1);
    let [btn, setBtn] = useState("Next");
    let [pivots, setPivots] = useState({});
    let [seen, setSeen] = useState({});
    let [showParentColors, setShowParentColors] = useState(false);

    useEffect(() => {
        input = generateInput();
        console.log(input)
        setInput(input);
        pivot = -1;
        setPivot(pivot);

        lo = -1;
        setLo(lo);
        hi = -1;
        setHi(hi);      
        stack = [];
        setStack(stack);
        incrementingKey = 0;
        setIncrementingKey(incrementingKey);
    }, []);

    let next = async () => {
        if (incrementingKey == 0) {
            push(0, input.length-1); 
            return;          
        }
        if (stack.length == 0) {
            return;
        }
        let par = stack[stack.length-1];
        lo = par.lo;
        hi = par.hi;
        setLo(lo);
        setHi(hi);        
        
        setI(lo-1);
        setJ(lo);
        setShowParentColors(false);
        if (lo < hi && lo >= 0) {
            let p = pivots[lo+","+hi];
            if (!p && p != 0) {
                setPivot(-1);
                setPivotIndex(-1);

                p = await partition(lo, hi);
                stack[stack.length-1].pivotIndex = p; 
                setStack(stack); 
                incrementingKey++;
                setIncrementingKey(incrementingKey);

                pivots[lo+","+hi] = p;
                setPivots(pivots);
                return;
            }
            // setI(-1);
            // setJ(-1);
            console.log("p = " + p)

            if (push(lo, p-1)) {
                setShowParentColors(true);
                return;
            }
            if (push(p+1, hi)) {
                setShowParentColors(true);
                setInfo("Will recursively sort the blue part of the " + lo + ", " + hi + " subarray. ")
                return;
            }
        }
            
        let popped = stack.pop();
        setInfo("Finished sorting the subarray from " + popped.lo + " to " + popped.hi + ". Returning.")
        setStack(stack); setIncrementingKey(incrementingKey + 1);
        if (stack.length == 0) {
            setInfo("Quicksort is complete!");
            setI(-1);
            setJ(-1);
            setLo(-1);
            setHi(-1);
            setPivotIndex(-1);
            setBtn("");
            return;
        }
            
        // setBtn("Pop from stack");        
    }

    let swap = async (i, j) => {
        if (i == j) {
            await sleep();
            return;
        }
        setInput([...input]);
        await sleep();
        let temp = input[i];
        input[i] = input[j];
        input[j] = temp;        
        setInput([...input]);

        await sleep();
    }        

    let push = (lo, hi) => {
        let lab = lo + "," + hi;
        if (seen[lab] || lo > hi) return false;
        stack.push({
            lo: lo,
            hi: hi,
            label: lab
        });
        setStack(stack);
        incrementingKey++;
        setIncrementingKey(incrementingKey);
        seen[lab] = true;
        setSeen(seen);
        setInfo("Let's partition the subarray " + lo + " to " + hi + ".");        

        return true;
    }
    let partition = async (l, h) => {
        setWaiting(true);
        let piv = input[h]
        setPivot(h);
        setPivotIndex(h);
        i = l - 1
        setI(i);
        await sleep();
        let hasRed = false, hasBlue = false;
        for (j = l; j < h; j++) { 
          setJ(j);
          if (input[j] <= piv) {
            i = i + 1
            setI(i);
            hasRed = true;
            await swap(i, j)
          } else {
            hasBlue = true;
            await sleep();
          }
        }
        setJ(j);
        i = i + 1
        setI(i);
        await swap(i, h)

        setPivot(input[i]);
        setPivotIndex(i);

        setWaiting(false);
        info = "Finished partitioning the subarray " + lo + " to " + hi + ".";
        if (hasRed && hasBlue) info += " Now we'll recursively sort the red and blue subarrays.";
        else if (hasRed) info += " Now we'll recursively sort the red subarray.";
        else if (hasBlue) info += " Now we'll recursively sort the blue subarray.";    
        setInfo(info);           
        return i;      
    }

    let getCls = (ii, v) => {
        if (v && v.pivotIndex) {
            if (ii < v.lo || ii > v.hi) return " opaque";
            if (ii == v.pivotIndex) return " quicksort pivot";
            return (ii < v.pivotIndex) ? " quicksort smaller" : " quicksort greater";            
        }
        if (waiting) return getClassName({ii, lo, hi, i, j, pivotIndex});
        if (stack.length == 0) return "";
        let el = showParentColors ? stack[stack.length-2] : stack[stack.length-1];
        if (ii < el.lo || ii > el.hi || el.pivotIndex == null) return " quicksort";
        if (ii == el.pivotIndex) return " quicksort pivot";
        return (ii < el.pivotIndex) ? " quicksort smaller" : " quicksort greater";
    }

    return (
<div className="hzflex playground" style={{alignItems: "stretch"}}>
    <div className="onebythree">
        <Code code={code} />
    </div>        
    <div className="hzflex twobythree" style={{flexDirection: "row", alignItems: "stretch"}}>
        <CallStack recursionStack={stack} incrementingKey={incrementingKey}/> 
        <div className="" style={{flexGrow: 1}}>
            {btn.length > 0 && (<Button fullWidth={true} style={{marginBottom: 10}} variant="contained" onClick={() => next()} disabled={waiting}>Next</Button>)}
            <div>
                <Array values={input} getCls={(ii) => " "} showIndex={true}/>
            </div>
            <div style={{marginTop: 10}}>
                {stack.map((v, i) => (
                    <div key={i} style={{marginTop: 5}}>
                        <Array values={input} getCls={(ii) => {
                            if (ii >= v.lo && ii <= v.hi) return getCls(ii, v);
                            return " opaque";
                        }} showIndex={false}/>
                    </div>
                ))}
            </div>

            {info.length > 0 && <Typography variant="body1" sx={{mt: 1}}>{info}</Typography>}
        </div>
    </div>           
</div>
)}