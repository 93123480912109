import { Button } from "@mui/material";
import { useState } from "react";

const generateRandomArray = (size, isBinary) => {
    let array = [];
    for (let i = 0; i < size; i++) {
        let sign = 1; //Math.random() * 100 < 50 ? -1 : 1;
        if (isBinary) array.push(Math.floor(Math.random() * 2));
        else array.push(sign * Math.floor(Math.random() * 20));
    }
    return array;
}

export const SlidingWindow = (props) => {
    let [array, setArray] = useState(generateRandomArray(10));
    let [windowStart, setWindowStart] = useState(0);
    let [windowEnd, setWindowEnd] = useState(0);
    let [size, setSize] = useState(props.size || 3);
    
    let [sum, setSum] = useState(array[0]);
    let [avg, setAvg] = useState(array[0]);
    
    let grow = () => {
        if (windowEnd < array.length - 1) {
            windowEnd = windowEnd + 1;
            setWindowEnd(windowEnd);
            sum = sum + array[windowEnd];
            setSum(sum);
            setAvg(sum / (windowEnd - windowStart + 1));

        }
    }
    let move = () => {
        if (windowEnd < array.length - 1) {
            windowEnd = windowEnd + 1;
            setWindowEnd(windowEnd);

            windowStart = windowEnd - size + 1;
            setWindowStart(windowStart);
            
            sum = array.slice(windowStart, windowStart + size).reduce((a, b) => a + b, 0);
            setSum(sum);
            setAvg(sum/size);
        }
    }
    let reduce = () => {
        if (windowStart < windowEnd) {
            windowStart = windowStart + 1;
            setWindowStart(windowStart);
            sum = sum - array[windowStart-1];
            setSum(sum);
            setAvg(sum / (windowEnd - windowStart + 1));
        }
    }

    let restart = () => {
        setWindowStart(0);
        setWindowEnd(size ? size-1 : 0);
        sum = array.slice(0, size ? size : 1).reduce((a, b) => a + b, 0);
        setSum(sum);
        setAvg(size ? (sum/size) : sum);
    }
    
    return (
<div>
    <div className="window">
            {array.map((value, index) =>  (
                <div className={"windowitem " + ((index >= windowStart && index <= windowEnd) ? "in" : "") + " " + (index == 0 ? "first " : "") + (index == array.length-1 ? "last " : "")}>
                    <div className="number" style={{textAlign: "center"}}>{value}</div>
                </div>
            ))}
    </div>
    
    {props.showSum && (
    <div>
        <div>Sum = {sum}, Average = {avg}</div>
    </div>)}
    
    {!props.fixedWidth && (<>
    <Button style={{marginRight: 10, marginTop: 10}} variant="outlined" color="primary" onClick={() => grow()}>Grow</Button>
    <Button style={{marginRight: 10, marginTop: 10}} variant="outlined" color="primary" onClick={() => reduce()}>Reduce</Button>
    </>)}
    {props.fixedWidth && (<>
    <Button style={{marginRight: 10, marginTop: 10}} variant="outlined" color="primary" onClick={() => move()}>Move</Button></>)}
    <Button style={{marginRight: 10, marginTop: 10}} variant="outlined" color="primary" onClick={() => restart(true)}>Restart</Button>
</div>
)}