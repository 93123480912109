import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import Code from './Code';
import * as Constants from "./Constants";
import Graph from "./Graph";
import { generateWeightedGraph } from "./GraphFunctions";

const waitTimeMs = 100, clickedNodesColor = Constants.CLICKED_NODE_COLOR, defaultNodeColor = Constants.DEFAULT_NODE_COLOR;
const INFINITY = 10000000000000000000;
function rand(n) {
    return parseInt((Math.random() * 10000000000000) % n);
}
const code = [
`initialize PriorityQueue and minDistance as empty`,
`PriorityQueue.push([source, 0])`,
`while PriorityQueue is not empty:`,
`     u = Pop from PriorityQueue`,
`     if (u.second > minDistance[u.first]) continue`,
`     for each neighbor v of u.first:`,
`         newDist = minDistance[u] + edge(u, v)`,
`         if minDistance[v] > newDist:`,
`             minDistance[v] = newDist`,
`             PriorityQueue.push([v, newDist])`
];

export default function Dijkstra({search=false}) {
    let [graph, setGraph] = useState({});
    let [progressStatement, setProgressStatement] = useState("Click the node to start from (the source)"); 
    let [clickedNodes, setClickedNodes] = useState([]);
    let [bfsNodes, setBfsNodes] = useState([]);
    let [queue, setQueue] = useState([]);
    let [step, setStep] = useState(0);
    let [btn, setBtn] = useState("");
    let [justPopped, setJustPopped] = useState(null);
    let [result, setResult] = useState("");
    let [selectedLine, setSelectedLine] = useState(-1);
    let [sleeping, setSleeping] = useState(false);
    let [dist, setDist] = useState({});

    useState(() => {
        graph = generateWeightedGraph({n: 7, isDag: false, parentCnt: () => rand(1)+1});
        setGraph(graph);
        dist = graph.nodes.map((n) => INFINITY);
        setDist(dist);
    }, []);

    const setNodes = (nodes) => {
        console.log("setnodes")
        graph.nodes = nodes;
        setGraph(graph);
    }

    const sleep = (showProgress=true) => {
        showProgress && setSleeping(true);
        return new Promise(resolve => setTimeout(() =>{
            showProgress && setSleeping(false);
            setTimeout(resolve, 200);            
        }, waitTimeMs));
    }
    const onNodeClick = async (node) => {
        if (clickedNodes.find((n) => n.index === node.index)) {
            redraw(node.index, "fill", defaultNodeColor);
            clickedNodes = clickedNodes.filter((n) => n.index !== node.index);
            setClickedNodes(clickedNodes);   
            return;
        }
        clickedNodes.map((n) => redraw(n.index, "fill", defaultNodeColor));
        clickedNodes = [...clickedNodes, node].slice(-2);
        setClickedNodes(clickedNodes);
        
        if (clickedNodes.length == 0) return;
        redraw(clickedNodes[0].index, "fill", clickedNodesColor);  
        if (!search && clickedNodes.length == 1) {
            setBfsNodes([clickedNodes[0]]);
            setClickedNodes([]);
            setBtn("Start Dijsktra's Algorithm");
            setProgressStatement("");
            return;
        }           
        (clickedNodes.length == 1) && setProgressStatement("Click another node, the DFS target");        
        if (clickedNodes.length === 2) {
            redraw(clickedNodes[1].index, "fill", clickedNodesColor);

            setProgressStatement("");
            let node1 = clickedNodes[0], node2 = clickedNodes[1];
            clickedNodes = [];
            setClickedNodes([]);
            setBfsNodes([node1, node2]);
            setBtn("Start Dijkstra's Algorithm")
        }
    }
    const redraw = (nodeIndex, field, value) => {
        console.log("redraw")
        graph.nodes[nodeIndex][field] = value;
        setNodes(graph.nodes);
        window.redrawGraph();
    }
    const resortQueue = () => {
        queue = queue.sort((a, b) => a[1] - b[1]);
        setQueue(queue);
    }

    const pushToQueue = (node, distance) => {
        console.log("pushing " + node.index)
        queue.push({
            node: node,
            distance: distance,
            label: (it) => "[" + it.node.index + ", " + it.distance + "]"
        });
        resortQueue();
    }
    const takeStep = async () => {
        if (step == 0) { // search has not yet started
            setStep(step+1);            
            setBtn("Set distance of source to 0");
            return;
        }
        if (step == 1) {
            setSelectedLine(4);
            pushToQueue(bfsNodes[0], 0);
            dist[bfsNodes[0].index] = 0;
            setDist(dist);
            setStep(step+1);
            setBtn("Pop a node from the priority queue");
            return;
        }
        if (justPopped) {
            if (bfsNodes[1] && justPopped.node.index == bfsNodes[1].index) {                
                setSelectedLine(8);
                setJustPopped(null);
                setResult("Found a path from " + bfsNodes[0].label + " to " + bfsNodes[1].label + "! The shortest distance is " + dist[bfsNodes[1].index] + ".");
                return;
            }            
            setSelectedLine(6);
            for (let i = 0; i < justPopped.node.children.length; i++) {
                let wt = graph.weights[justPopped.node.index][justPopped.node.children[i]];
                if (dist[justPopped.node.children[i]] > dist[justPopped.node.index] + wt) {
                    dist[justPopped.node.children[i]] = dist[justPopped.node.index] + wt;
                    setDist(dist);
                    pushToQueue(graph.nodes[justPopped.node.children[i]], dist[justPopped.node.index] + wt);
                    resortQueue();
                }           
                await sleep();            
            }
            justPopped = null;
            setJustPopped(justPopped);
            setBtn("Pop another node from the queue");
            
            return;     
        }
        if (queue.length == 0) {
            setResult(search ? "No path found." : "Dijkstra's algorithm has finished.");
            return;
        }
        setSelectedLine(6);
        justPopped = queue.shift();
        setJustPopped(justPopped);
        setQueue(queue);

    }

    return (
    <div className="code-and-btns">
        <Code code={code} codeLine={selectedLine} />
        <div className="bucket">
            <div className="">
                <div>
                    {result && <Typography className="result" variant="body1" sx={{mb: 1}}>{result}</Typography>}
                    {!result && progressStatement && <Typography variant="body1" sx={{mb: 1}}>{progressStatement}</Typography>}
                </div>
                {!result && btn.length > 0 && (
                <Button sx={{mt: 1, mb: 1}} fullWidth={true} size="small" variant="contained" onClick={() => takeStep()}disabled={sleeping}>{btn}</Button>)}
                {!result && step >= 0 && (
                <div className='popped-and-queue'>
                    {justPopped && ( 
                    <div className='popped'>{justPopped.label(justPopped)}</div>)}
                    <div className="priority-queue bucket" style={{marginTop: 30}}>
                        <div className="bucket-title">Priority Queue</div>
                        <div className="nodes">
                            {queue.map((it, i) => (<span key={i} className="queueitem">{it.label(it)}</span>))}
                        </div>
                    </div>
                </div>)}
                { 
                    <div className="bucket" style={{marginTop: 20}}>
                        <div className="bucket-title">dist (distance from {bfsNodes[0] ? bfsNodes[0].label : "source"})</div>
                        {Object.keys(dist).map((key) => (
                            <div key={key}>{key}: {dist[key] == INFINITY ? '\u221E' : dist[key]}</div>
                        ))}
                    </div>
                }
            </div>
            <Graph forceManyBody={true} nodes={graph.nodes} setNodes={setNodes} onNodeClick={onNodeClick} weights={graph.weights}/>       
        </div>        
    </div>
)}