
import * as Constants from "./Constants";
import { bfs } from "./GraphFunctions";

const color1 = Constants.HIGHLIGHTED_NODE_COLOR,  defaultNodeColor = Constants.DEFAULT_NODE_COLOR;

function sleep(waitTimeMs) {
    return new Promise(resolve => setTimeout(resolve, waitTimeMs));
}
let pop = async ({nodes, setNodes, minOrMax, sleepMs, isParentChildInvariant}) => {
    if (nodes.length == 0) return Promise.resolve(nodes);
    nodes[0].fill = color1;
    nodes[nodes.length-1].fill = color1;
    setNodes([...nodes]);
    await sleep(sleepMs * 2);
    
    let last = nodes[nodes.length-1];
    nodes[0].label = last.label;
    nodes.pop();
    if (nodes.length > 0) {
        nodes[last.parents[0]].children = nodes[last.parents[0]].children.filter(x => x !== last.index);        
    }
    setNodes([...nodes]);
    await sleep(sleepMs * 2);
    return bubbleDown({nodes, setNodes, minOrMax, index:0, sleepMs, isParentChildInvariant});
}
let isParentChildInvariantDefault = ({nodes, minOrMax, parentIndex, childIndex}) => {
    let c = nodes[childIndex].label, p = nodes[parentIndex].label;
    return (minOrMax == "min") ? p < c : p > c;
}
let bubbleDown = ({nodes, setNodes, minOrMax, index, sleepMs, isParentChildInvariant=isParentChildInvariantDefault}) => {
    let node = nodes[index];
    if (!node) return Promise.resolve(nodes);
    if (node.children.length === 0 || (isParentChildInvariant({nodes, minOrMax, parentIndex: index, childIndex:node.children[0]}) && (node.children.length === 1 || isParentChildInvariant({nodes, minOrMax, parentIndex: index, childIndex: node.children[1]})))) {
        nodes[index].fill = defaultNodeColor;
        setNodes([...nodes]);
        // setInProgress(false);
        return Promise.resolve(nodes);
    }
    let childIndex = node.children[0];
    if (node.children.length === 2) {
        if (isParentChildInvariant({nodes, minOrMax, parentIndex: node.children[1], childIndex: node.children[0]})) {
            childIndex = node.children[1];
        }
        /*
        let sec = nodes[node.children[1]].label, first = nodes[node.children[0]].label;
        if ((minOrMax == "min" && sec < first) || (minOrMax == "max" && sec > first)) {
            childIndex = node.children[1];
        }
        */
    }
    nodes[node.index].fill = defaultNodeColor;
    let childNode = nodes[childIndex];
    let temp = node.label;
    node.label = childNode.label;
    childNode.label = temp;
    childNode.fill = color1;
    setNodes([...nodes]);
    return sleep(sleepMs)
        .then(() => bubbleDown({nodes, setNodes, minOrMax, index: childIndex, sleepMs, isParentChildInvariant}))
        .then(() => {
            nodes[childNode.index].fill = defaultNodeColor;
            setNodes([...nodes]);
            return nodes;
        });
}
let bubbleUp = ({nodes, setNodes, minOrMax, index, sleepMs, isParentChildInvariant=isParentChildInvariantDefault}) => {
    let node = nodes[index];
    if (node.parents.length === 0 || isParentChildInvariant({nodes, minOrMax, parentIndex:node.parents[0], childIndex:index})) {
        return sleep(sleepMs).then(() => {
            nodes[index].fill = defaultNodeColor;
            setNodes([...nodes]);
            return nodes;
        });
    }
    let parentIndex = node.parents[0];
    let parentNode = nodes[parentIndex];
    let temp = node.label;
    node.label = parentNode.label;
    parentNode.label = temp;
    parentNode.fill = color1;
    setNodes([...nodes]);
    return sleep(sleepMs)
        .then(() => bubbleUp({nodes, setNodes, minOrMax, index: parentIndex, sleepMs, isParentChildInvariant}))
        .then(() => {
            nodes[parentNode.index].fill = defaultNodeColor;
            setNodes([...nodes]);
            return nodes;
        });
}

let add = ({nodes, setNodes, minOrMax, value, sleepMs, isParentChildInvariant=isParentChildInvariantDefault}) => {
    let node = {
        index: nodes.length,
        label: value,
        parents: [],
        children: [],
        fill: color1,
        new: true // used for laying out properly 
    };
    let parentIndex = nodes.findIndex(x => x.children.length < 2);
    if (parentIndex !== -1) {
        node.parents.push(parentIndex);
        nodes[parentIndex].children.push(node.index);
    } 
    nodes = bfs([...nodes, node]);
    setNodes([...nodes]);
    return sleep(sleepMs)
        .then(() => bubbleUp({nodes, setNodes, minOrMax, index: node.index, sleepMs, isParentChildInvariant}))
        .then(() => {
            nodes[node.index].fill = defaultNodeColor;
            nodes[node.index].new = false;
            setNodes([...nodes]);
            return nodes;
        });
}

export { pop, add };
