import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CallStack from "./CallStack";
import Code from "./Code";
import * as Constants from "./Constants";
import Graph from "./Graph";
import { bfs, genGraph } from "./GraphFunctions";

const clickedNodesColor = Constants.CLICKED_NODE_COLOR, defaultNodeColor = Constants.DEFAULT_NODE_COLOR, visitedColor = Constants.QUEUE_ITEM_COLOR;

function rand(n) {
    return parseInt((Math.random() * 10000000000000) % n);
}
const code = 
`void DFS(v) {
    seen[v] = true
    for(int i = 0; i < edges[v].size(); i++) {
        int w = edges[v][i];
        if(!seen[w]) {
            DFS(w)
        }
    }
}`
    .split("\n");    

const genGraphLocal = (isTree) => {
    return isTree ? bfs(genGraph(10, true, () => 1, () => rand(10/2))) : genGraph(10, false, () => rand(2)+1);
}
export default function DFS({isTree=true, search=true}) {
    let [nodes, setNodes] = useState();
    let [progressStatement, setProgressStatement] = useState("Click the node to start DFS from"); 
    let [clickedNodes, setClickedNodes] = useState([]);
    let [dfsNodes, setDfsNodes] = useState([]);
    let [btn, setBtn] = useState("");
    let [seen, setSeen] = useState({});
    let [result, setResult] = useState("");
    let [selectedLine, setSelectedLine] = useState(-1);
    let [sleeping, setSleeping] = useState(false);

    let [recursionStack, setRecursionStack] = useState([]);
    let [incrementingKey, setIncrementingKey] = useState(0);
    useEffect (() => {
        console.log("generating graph")
        nodes = genGraphLocal(isTree);
        setNodes(nodes);        
        window.redrawGraph({relayout: true}, nodes);
    }, []);

    const onNodeClick = async (node) => {
        if (clickedNodes.find((n) => n.index === node.index)) {
            redraw(node.index, "fill", defaultNodeColor);
            clickedNodes = clickedNodes.filter((n) => n.index !== node.index);
            setClickedNodes(clickedNodes);   
            return;
        }
        clickedNodes.map((n) => redraw(n.index, "fill", defaultNodeColor));
        clickedNodes = [...clickedNodes, node].slice(-2);
        setClickedNodes(clickedNodes);
        
        if (clickedNodes.length == 0) return;
        redraw(clickedNodes[0].index, "fill", clickedNodesColor);  
        if (!search && clickedNodes.length == 1) {
            setDfsNodes([clickedNodes[0]]);
            setClickedNodes([]);
            setBtn("Start DFS");
            setProgressStatement("");
            return;
        }           
        (clickedNodes.length == 1) && setProgressStatement("Click another node, the DFS target");        
        if (clickedNodes.length === 2) {
            redraw(clickedNodes[1].index, "fill", clickedNodesColor);

            setProgressStatement("");
            let node1 = clickedNodes[0], node2 = clickedNodes[1];
            clickedNodes = [];
            setClickedNodes([]);
            setDfsNodes([node1, node2]);
            setBtn("Start DFS")
        }
    }
    const redraw = (nodeIndex, field, value) => {
        nodes[nodeIndex][field] = value;
        setNodes(nodes);
        window.redrawGraph();
    }
    const takeStep = async () => {
        if (selectedLine == -1) { // dfs has not yet started
            // setSelectedLine(0);
            setSelectedLine(100); // hack, remove later
            redraw(dfsNodes[0].index, "fill", visitedColor);    
            seen[dfsNodes[0].index] = true; 
            setSeen(seen);            
            recursionStack.push({
                label: "v=" + dfsNodes[0].label,
                node: dfsNodes[0]
            }); 
            setRecursionStack(recursionStack); setIncrementingKey(incrementingKey+1);
            // setSelectedLine(1); 
            setBtn("Call DFS on " + recursionStack[recursionStack.length-1].node.label + "'s next child");
            // await sleep();         
            return;
        }
        let par = recursionStack[recursionStack.length-1].node;                
        for (let i = 0; i < recursionStack[recursionStack.length-1].node.children.length; i++) {
            let node = nodes[recursionStack[recursionStack.length-1].node.children[i]];
            if (dfsNodes[1] && node.index == dfsNodes[1].index) {
                // setSelectedLine(4);
                setRecursionStack([]); setIncrementingKey(incrementingKey+1);
                setResult("Found a path from " + dfsNodes[0].label + " to " + dfsNodes[1].label + "!");
            }
            if (!seen[node.index]) {                
                recursionStack[recursionStack.length-1].label = "v=" + par.label + ", w=" + node.label;
                recursionStack.push({
                    node: node,
                    label: "v=" + node.label
                });
                setRecursionStack(recursionStack); setIncrementingKey(incrementingKey+1);
                redraw(node.index, "fill", visitedColor);
                seen[node.index] = true;
                setSeen(seen);
                // setSelectedLine(1);
                // setBtn("Call DFS on " + node.label + "'s next child");

                
                if (node.children.length > 0 && !seen[node.children[node.children.length-1]]) {
                    setBtn("Call DFS on " + node.label + "'s next child");
                } else {
                    setBtn("Pop from stack");
                }
                return;
            }
        }
        let popped = recursionStack.pop();
        setRecursionStack(recursionStack); 
        setIncrementingKey(incrementingKey+1);
        redraw(popped.node.index, "fill", defaultNodeColor);
        if (recursionStack.length == 0) {
            setResult(search ? "No path found." : "DFS is complete!");
            return;
        }
        let node = recursionStack[recursionStack.length-1].node;                
        
        if (node.children.length > 0 && !seen[node.children[node.children.length-1]]) {
            setBtn("Call DFS on " + node.label + "'s next child");
        } else {
            setBtn("Pop from stack");
        }
        console.log("What should happen?")
    }
    return (
<div className="algocontainer">
    
    <div className="code-and-btns">
        <Code code={code} codeLine={selectedLine} />
        <CallStack recursionStack={recursionStack} incrementingKey={incrementingKey}/>     
        <div className="bucket">
            <div className="header">
                {result && <Typography className="result" variant="body1" sx={{mb: 1}}>{result}</Typography>}
                {!result && progressStatement && <Typography variant="body1" sx={{mb: 1}}>{progressStatement}</Typography>}
            </div>            
            {!result && btn.length > 0 && (
            <Button sx={{mt: 1, mb: 1}} fullWidth="true" size="small" variant="contained" onClick={() => takeStep()} disabled={sleeping}>{btn}</Button>)}
            <Graph nodes={nodes} setNodes={setNodes} onNodeClick={onNodeClick}/>       
        </div>
    </div>
    
</div>
)}