import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CallStack from "./CallStack";
import Code from "./Code";
import { Array } from "./problems/Array";

function sleep(ms=300) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
let generateArray = (n) => {
    let input = [];
    for (let i = 0; i < n; i++) {
        input.push(Math.floor(Math.random() * 10) * 10);
    }
    input = input.filter((v, i, a) => a.indexOf(v) === i);
    return input;
}
const code = ``.split("\n");

export function MergeSort() {
    let [btn, setBtn] = useState("Next");
    let [input, setInput] = useState([]);
    let [lo, setLo] = useState(-1);
    let [hi, setHi] = useState(-1);
    let [stack, setStack] = useState([]);
    let [incrementingKey, setIncrementingKey] = useState(0);
    let [info, setInfo] = useState("");
    let [waiting, wait] = useState(false);
    let [sorted, setSorted] = useState([]);

    useEffect(() => {
        input = generateArray(20);
        setInput(input);
        result = [];
        setResult(result);
        lo = 0;
        setLo(lo);
        hi = input.length - 1;
        setHi(hi);
        stack = [];
        setStack(stack);
        incrementingKey = 0;
        setIncrementingKey(incrementingKey);
    }, []);

    let getCls = (ii) => {
        if (ii >= lo && ii <= hi) return " mergesort";
        return " grayedout";
    }
    let itFn = (i) => {
    }
    let next = async () => {
        if (incrementingKey == 0) {
            push(lo, hi);
            return;
        }
        if (stack.length == 0) return;
        lo = stack[stack.length-1].lo;
        setLo(lo);
        hi = stack[stack.length-1].hi;
        setHi(hi);

        if (lo < hi) {
            let middle = Math.floor((lo + hi) / 2);        
            if (push(lo, middle)) return;        
            if (push(middle+1, hi)) return;
        }
        let popped = stack.pop();
        setStack(stack);
        setIncrementingKey(incrementingKey + 1);

        lo = popped.lo;
        setLo(lo);
        hi = popped.hi;
        setHi(hi);

        wait();
        await merge(lo, hi);
        await sleep(200)
        await copyMerged(lo, hi);
        wait(false);
        // result = [];
        // setResult(result);

        if (stack.length == 0) {
            setBtn("");
            setInfo("Mergesort is complete.");
            return;
        }
    }

    let [result, setResult] = useState([]);

    let merge = async (start, end) => {
        if (start >= end) {
            result[0] = input[start];
            return;
        }
        let k = 0;
        let i = start;
        let middle = Math.floor((start + end) / 2);
        let j = middle + 1;
        result = [];
        setResult(result);
        
        setInfo("Will merge from " + start + " to " + end + ".");
        while (i <= middle && j <= end) {
            if (input[i] < input[j]) {
                result[k] = input[i];
                i++;
                //  setI(i);
            } else {
                result[k] = input[j];
                j++;
                //  setJ(j);
            }
            setResult(result);
            k++; 
            // setK(k);
            await sleep();
        }
        while (i <= middle) {
            result[k] = input[i];
            setResult(result);
            i++; 
            // setI(i);
            k++; 
            // setK(k);
            await sleep();
        }
        while (j <= end) {
            result[k] = input[j];
            setResult(result);
            j++; 
            // setJ(j);
            k++; 
            // setK(k);
            await sleep();
        }

    }
    let copyMerged = async (start, end) => {        
        setInfo("The two sorted arrays are now merged into a single sorted array. Copying merged array back to original array.")
        for (let i = start; i <= end; i++) {
            input[i] = result[i-start];
            sorted[i] = true;
            setInput(input);
            await sleep(100);
        }
        setResult(result);
        setSorted(sorted);
    }

    let [seen, setSeen] = useState({});    

    let push = (lo, hi) => {
        let lab = lo + "," + hi;
        if (seen[lab] || lo >= hi) return false;
        stack.push({
            lo: lo,
            hi: hi,
            label: lab,
            array: [...input]
        });
        setStack(stack);
        setIncrementingKey(incrementingKey + 1);
        seen[lab] = true;
        setSeen(seen);
        return true;
    }


    return (
<div className="hzflex playground">
    <div className="onebythree">
        <Code code={code}/>
    </div>
    <div>
        {lo != -1 && (<div style={{marginBottom: 20}}>Current: {lo + "," + hi}</div>)}
        <CallStack recursionStack={stack} incrementingKey={incrementingKey}/>         
    </div>   
    <div style={{width: "65%"}}>
        {btn.length > 0 && (<Button style={{marginBottom: 10}} variant="contained" onClick={() => next()} diabled={waiting}>{btn}</Button>)}
        <div>
            <Array values={input} getCls={getCls} showArrow={(i) => i == lo || i == hi} showIndex={true} itFn={itFn}/>
        </div>
        <div style={{marginTop: 10}}>
            {stack.map((v, i) => (
                <Array values={input} getCls={(ii) => {
                    if (ii >= v.lo && ii <= v.hi) return " mergesort";
                    return " opaque";
                }} showIndex={true}/>
            ))}
        </div>

        Merged array:
        <div>
            <Array values={result} showIndex={true}/>
        </div>
        {info.length > 0 && <Typography variant="body1" sx={{mt: 1}}>{info}</Typography>}
    </div>         
</div>
)
}