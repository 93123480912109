import { Button, Typography } from '@mui/material';
import { min } from 'lodash';
import { useEffect } from 'react';
const { useState } = require("react");

const recurrence = `option1 = dp[i-1] + 1-day-ticket-cost
option2 = dp[earliestDayToBuy7DayTicket-1] + 7-day-ticket-cost
option3 = dp[earliestDayToBuy30DayTicket-1] + 30-day-ticket-cost
dp[i] = min(option1, option2, option3)`;

const code = 
`int dp[days.size()];
int leastCost = min(costs[2], min(costs[0], costs[1]));
dp[0] = leastCost;
for (int i = 1; i < days.size(); i++) {
    dp[i] = dp[i-1] + leastCost;
    for (int j = i-1; j >=0; j--) {  
        if (days[i]-days[j] < 7) 
            dp[i] = min(dp[i], ((j>0) ? dp[j-1] : 0) + costs[1]);          
        if (days[i]-days[j] < 30) 
            dp[i] = min(dp[i], ((j>0) ? dp[j-1] : 0) + costs[2]);        
    }            
}
return dp[days.size()-1];`;

function generateRandomDays() {
    let days = [];
    let day = Math.floor(Math.random() * 40) + 1;
    while (days.length < 5) {
        days.push(day);
        day += Math.floor(Math.random() * 10) + 1;
    }
    // I want both 30 day tickets to be valid for last day, with lots of days inbetween
    while (days.length < 8) {
        days.push(day);
        day += Math.floor(Math.random() * 10) + 1;
    }
    // I want both 7 tickets to be valid for last day, with lots of days inbetween
    while (days.length < 12) {
        days.push(day);
        day += Math.floor(Math.random() * 3) + 1;
    }
    return days;
}

export default function MinCostTicketsSolution() {    
    let [days, setDays] = useState(generateRandomDays());
    let [steps, setSteps] = useState(10);
    let [log, setLog] = useState(["Let's experiment a little before figuring out the solution. Click a day below to buy a ticket on that day. Click Next after you've played around a bit."]);
    let [btn, setBtn] = useState("Next");
    let [ticketDay, setTicketDay] = useState(-1);
    let [tickets, setTickets] = useState({});
    let [targetTicketDay, setTargetTicketDay] = useState(-1);
    let [canBuyOn, setCanBuyOn] = useState({});
    let [info, setInfo] = useState("");
    let [ticketDays, setTicketDays] = useState({});
    let [costs, setCosts] = useState([2, 7, 15]);
    let [lastDayValidFors, setLastDayValidFors] = useState([]);
    let [showDpValues, setShowDpValues] = useState(true);
    let [showDaysView, setShowDaysView] = useState(true);
    
    useEffect (() => {
        steps = 10;
        setSteps(steps);
        next();
    }, []);

    const doDp = (days, costs) => {
        let dp = {}, opts = {};
        dp[0] = Math.min(costs[2], Math.min(costs[0], costs[1]));
        for (let i = 1; i < days.length; i++) {
            opts[i] = {};
            opts[i][0] = i-1;
            opts[i][1] = i-1;
            opts[i][2] = i-1;
            dp[i] = dp[i-1] + dp[0];
            for (let j = i-1; j >= 0; j--) {
                if (days[i] - days[j] < 7) {
                    dp[i] = Math.min(dp[i], (j>0?dp[j-1]:0) + costs[1]);
                    opts[i][1] = (j>0?j-1:0);                  
                }
                if (days[i] - days[j] < 30) {
                    dp[i] = Math.min(dp[i], (j>0?dp[j-1]:0) + costs[2]);
                    opts[i][2] = (j>0?j-1:0);                    
                }
            }
        }
        return [dp, opts];
    }
    let dpRes = doDp(days, costs);
    let opts = dpRes[1];
    let dp = dpRes[0];
    let [showDays, setShowDays] = useState(days);
    let [showDp, setShowDp] = useState(dp);

    const next = () => {
        if (steps == 10) {
            setLog(["In dp problems, it's a good idea to believe that you've already solved the problem, except for the last bit. Here, let's assume that we've already solved this problem for all days except the last day. Suppose that we store 'the minimum cost to buy tickets to cover all days until day i, including day i' in dp[i]. Assume that we already know dp[0], dp[1], etc, until dp[" + (days.length-2) + "]. Can we calculate dp[" + (days.length-1) + "] based on the previous dp values?"]);
            setTickets({});
            setTargetTicketDay(-1);
            setCanBuyOn({});
            showDp[days.length-1] = "?";
            setShowDp(showDp);
            setShowDpValues(true);
            setSteps(steps+10);
        }
        if (steps == 20) {
            setLog(["We can buy a ticket for the last day on one of these days:"]);            
            setLastDayValidFors([1,7,30]);
            setShowDpValues(false);
            setShowDaysView(false);
            setSteps(steps+10);
        }
        if (steps == 30) { // skipping this
            setLog(["Remember! Our ONLY interest is in the last day. We've already solved the problem to cover all days before that. The dp values below show that. For instance, dp[" + (days.length-2) + "] is the minimum cost to cover all days until & including day " + days[days.length-2] + "."]);
            setTargetTicketDay(-1);
            setLastDayValidFors([]);
            setShowDpValues(true);
            setShowDaysView(true);
            setSteps(steps+20);
        }
        if (steps == 50) {
            setLog(["Let's look at the 1 day ticket first. If we buy that on the last day, the total cost to cover all days until " + days[days.length-1] + " = min-cost-to-cover-all-days-until-" + days[days.length-2] + " + cost-of-1-day-ticket. That is, dp[" + (days.length-2) + "] + " + costs[0] + " = " + (dp[days.length-2]) + " + " + (costs[0]) + ", which is " + (+dp[days.length-2] + +costs[0]) + ". This is one possible value for dp[" + (days.length-1) + "]."]);
            showDp[days.length-1] = "Possibly " + (+dp[days.length-2] + +costs[0]);
            onTargetTicketDayClick(days.length-1);
            setLastDayValidFors([1]);
            setShowDaysView(false);
            setSteps(steps+10);
        }
        if (steps == 60) {
            let earliest = opts[days.length-1][1], badDay = days[earliest+2];
            setLog(["It might be cheaper to buy the 7 day ticket to cover day " + days[days.length-1] + ". Let's calculate and find out if it's cheaper. If we want to hypothetically buy the 7 day ticket to cover day " + days[days.length-1] + ", we can buy it on any one of these highlighted days. The question is, on which day should you buy it?", "It's better to buy a 7 day ticket on the earliest possible day that will still be valid for the last day. That is, day " + days[earliest+1] + ". Our goal is to minimize the total ticket cost, and this way, you don't have to pay for any day between " + (days[earliest+1]) + " and " + (days[days.length-1]) + ", since our 7 day ticket will cover them. If instead we buy it on a later day, such as day " + badDay + ", then we have to buy one more ticket, just to cover " + days[earliest+1] + ", which will cost us more. Instead, for the same cost, we can cover all the days between " + days[earliest+1] + " and " + days[days.length-1] + "."]);
            onTargetTicketDayClick(days.length-1);
            setLastDayValidFors([7]);
            setSteps(steps+30);
        }
        if (steps == 90) {
            setLog(["Ok, so if we want to buy a 7 day ticket, we should buy it on day " + (days[opts[days.length-1][1]+1]) + ". Our total cost = min-cost-to-cover-all-days-until-" + (days[opts[days.length-1][1]]) + " + cost-of-7-day-ticket" + ". The index of " + days[opts[days.length-1][1]] + " is " + opts[days.length-1][1] + ". So it is dp[" + (opts[days.length-1][1]) + "] + cost-of-7-day-ticket, which is " + (dp[opts[days.length-1][1]]) + " + " + (costs[1]) + " = " + (dp[opts[days.length-1][1]] + costs[1]) + ". This is another possible value for dp[" + (days.length-1) + "]."]);
            
            showDp[days.length-1] = showDp[days.length-1] + " or " + (+dp[opts[days.length-1][1]] + +costs[1]);
            setShowDp(showDp);

            onTargetTicketDayClick(days.length-1);
            setLastDayValidFors([7]);
            setSteps(steps+20);
        }
        if (steps == 110) {
            let earliest = opts[days.length-1][2];
            setLog(["Let's look at the 30 day ticket next. You can buy a 30 day ticket on any one of these highlighted days, and it will be valid for last day. Following similar logic, it's better to buy a 30 day ticket on the earliest possible day. Then we don't have to buy any other ticket for days between " + days[earliest+1] + " and " + days[days.length-1] + ".", "So, if we want to buy a 30 day ticket to cover " + (days[days.length-1]) + ", we should buy it on day " + (days[earliest+1]) + ". The total cost will then be min-cost-to-cover-all-days-before-" + (days[earliest+1]) + " + cost-of-30-day-ticket. The index of day " + days[earliest+1] + " is " + (earliest+1) + ". So our value is dp[" + earliest + "] + " + costs[2] + ", which is " + (dp[earliest] + " + " + costs[2]) + " = " + (+dp[earliest] + +costs[2]) + ". This is the last possible value for dp[" + (days.length-1) + "]."]);

            onTargetTicketDayClick(days.length-1);
            showDp[days.length-1] = showDp[days.length-1] + " or " + (+dp[opts[days.length-1][2]] + +costs[2]);
            setShowDp(showDp);
            setLastDayValidFors([30]);
            setSteps(steps+20);
        }
        if (steps == 130) {
            let possibleAnswers = [
                +dp[opts[days.length-1][0]] + +costs[1],
                +dp[opts[days.length-1][1]] + +costs[1],
                +dp[opts[days.length-1][2]] + +costs[2]
            ];
            setLog(["So, dp[" + (days.length-1) + "] = min(" + possibleAnswers.join(", ") + ") = " + Math.min(...possibleAnswers) + ". This is the minimum cost to cover the last day, ASSUMING our dp values for all previous days are correct.", "This recurrence relationship can be written like this:"]);
            showDp[days.length-1] = dp[days.length-1];
            setShowDp(showDp);
            setShowDaysView(false);

            setLastDayValidFors([]);
            setSteps(steps+20);
        }
        if (steps == 150) {
            setLog(["Now let's see how to calculate dp[" + (days.length-1) + "], without knowing the values of dp[0], dp[1], etc upto dp[" + (days.length-2) + "].", "We do this by induction. What is dp[0]?", "dp[0] is the min-cost to cover day " + days[0] + " alone. That's easy to calculate. It's simply the smallest value out of the 3 ticket costs, that is min(" + costs.join(",") + "), which is " + min(costs) + ". Next, how can we calculate dp[1]?"]);
            setShowDays([days[0]]);
            setSteps(steps+10);
        } 
        if (steps == 160) {
            setLog(["We know dp[0] now. We just calculated it. Now we can use our recurrence to calculate dp[1].", "To cover day " + days[1] + " using a 1 day ticket, we should buy that ticket on day " + days[1] + ".", "To cover it using a 7 day ticket, we should buy it on " + days[opts[1][1]] + ".", "To cover it using a 30 day ticket, we should buy it on " + days[opts[1][2]] + ".", "dp[1] = min(dp[0] + 1-day-ticket-cost, dp[" + opts[1][1] + "] + 7-day-ticket-cost, dp[" + opts[1][2] + "] + 30-day-ticket-cost), which is " + dp[1] + "."]);
            
            setShowDays([days[0], days[1]]);
            setShowDaysView(true);
            showDp[1] = "?";
            setShowDp(showDp);

            setSteps(steps+10);
        }        
        if (steps == 170) {
            setLog(["We know both dp[0] and dp[1] now. Now we can use our recurrence to calculate dp[2].", "To cover day " + days[2] + " using a 1 day ticket, we should buy that ticket on day " + days[2] + ".", "To cover it using a 7 day ticket, we should buy it on " + days[opts[2][1]] + ".", "To cover it using a 30 day ticket, we should buy it on " + days[opts[2][2]] + ".", "So dp[2] = min(dp[1] + 1-day-ticket-cost, dp[" + opts[2][1] + "] + 7-day-ticket-cost, dp[" + opts[2][2] + "] + 30-day-ticket-cost),", "which is min(" + dp[1] + " + " + costs[0] + ", " + dp[opts[2][1]] + " + " + costs[1] + ", " + dp[opts[2][2]] + " + " + costs[2] + ").", "So dp[2] = " + dp[2] + "."]);
            setShowDays([days[0], days[1], days[2]]);
            showDp[1] = dp[1];
            showDp[2] = "?";
            setShowDp(showDp);
            setSteps(steps+10);
        }       
        if (steps == 180) {
            setLog(["Great! Using this same idea, we can calculate dp[3], dp[4], etc, all the way up to dp[" + (days.length-1) + "]. The value of dp[" + (days.length-1) + "] represents the min cost needed to travel all given days until " + (days.length-1) + ", so that value is the final answer."]);
            showDp[2] = dp[2];
            setShowDp(showDp);

            setSteps(steps+10);            
        }       
        if (steps == 190) {
            setLog(["This is the final solution:"]);
            setSteps(steps+10);   
            setBtn("");          
        }
    }

    const buyTicket = (validFor) => {
        for (let i = ticketDay; i < ticketDay + validFor; i++) {
            tickets[i] = true;
        }
        setTickets(tickets);
        setTicketDay(-1);
    }

    const getDayCls = (i, validFor) => {
        if (steps == 0) return "day " + (tickets[days[i]] ? "selected" : "");
        if (steps >= 30) {
            if (i == days.length-1) return "day selected";
            if (days[days.length-1] - days[i] < validFor) return "day selected";
            return "day";   
        }
        return canBuyOn[days[i]] ? "day selected" : "day";
    }

    const onTargetTicketDayClick = (i) => {
        canBuyOn = {};
        setCanBuyOn({});
        
        ticketDays = {1: days[i]};
        for (let j = i; j >= 0; j--) {
            if (days[i] - days[j] <= 30) {
                canBuyOn[days[j]] = 30;
                ticketDays[30] = days[j];
            }
            if (days[i] - days[j] <= 7) {
                canBuyOn[days[j]] = 7;
                ticketDays[7] = days[j];
            }
        }        
        setTicketDays(ticketDays);
        canBuyOn[days[i]] = 1;
        setCanBuyOn(canBuyOn);
        setTargetTicketDay(i);
        
    }

    return (
        <div style={{textAlign: "left"}}>
            {/* <div style={{position: "absolute", top: 0, right: 10, opacity: 0.5}}>Steps: {steps}</div> */}
            <Typography variant="body1">
                {log.map((l, i) => (
                    <div key={i}>{l}</div>
                ))}
            </Typography>
            {steps == 150 && (<div className="code" style={{width: "100%"}}><pre>{recurrence}</pre></div>)}
            {steps == 200 && (<div className="code" style={{width: "100%"}}><pre>{code}</pre></div>)}
            {showDaysView && (
                <div className="days" style={{marginTop: 20, marginBottom: 20}}>
                    {showDays.map((day, i) => (
                        <div className={getDayCls(i) + " daywithdp"} key={i} onClick={() => {
                            steps == 0 && setTicketDay(day);
                            steps == 10 && onTargetTicketDayClick(i);
                            steps == 30 && (i == days.length-1) && onTargetTicketDayClick(i);
                        }}>
                            <div>
                                {day}
                            </div>
                            {showDpValues && (<div className="dp">{"dp[" + i + "] = " + showDp[i]}</div>)}                            
                        </div>
                    ))}
                </div>
            )}
            {(
                <div style={{marginTop: 10, marginBottom: 10}}>
                    {lastDayValidFors.map((validFor) => (
                        <div key={validFor}>
                            <div>{validFor} day ticket:</div>
                            <div key={validFor} className="days">
                                {showDays.map((day, i) => (
                                    <div key={i} className={"daywithdp " + getDayCls(i, validFor)}>
                                        <div key={i}>
                                            {day}
                                        </div>        
                                        {showDpValues && (<div className="dp">{"dp[" + i + "] = " + showDp[i]}</div>)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>)}           
            {/* {targetTicketDay != -1 && (
            <div>
                <div>To have a ticket that is valid on day {days[targetTicketDay]}:</div>
                <div>Buy a 1 day ticket on day {ticketDays[1]}</div>
                {ticketDays[7] && (<div>OR buy a 7 day ticket on some day between {ticketDays[7]} and {days[targetTicketDay]}</div>)}
                {ticketDays[30] && (<div>OR buy a 30 day ticket on some day between {ticketDays[30]} and {days[targetTicketDay]}</div>)}
            </div>)} */}
            {ticketDay >= 0 && 
            <div className="tickets">
                <Button style={{marginTop: 10, marginBottom: 10, marginRight: 10}} variant="contained" onClick={() => buyTicket(1)}>Buy 1 day Ticket</Button>
                <Button style={{marginTop: 10, marginBottom: 10, marginRight: 10}} variant="contained" onClick={() => buyTicket(7)}>Buy 7 day Ticket</Button>
                <Button style={{marginTop: 10, marginBottom: 10, marginRight: 10}} variant="contained" onClick={() => buyTicket(30)}>Buy 30 day Ticket</Button>
            </div>}
            {btn.length>0 && (<Button fullWidth={true} style={{marginTop: 10, marginBottom: 10}} variant="contained" onClick={() => next()}>{btn}</Button>)}

        </div>
    )
}