import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import BinaryHeap from "./BinaryHeap";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {genCompleteBinaryTree, bfs} from "./GraphFunctions";
import Find from "./unionfind/Find";
import Code from "./Code";
import Union from "./unionfind/Union";


const findCode = 
`int find(int node) {
    if(parent[node] == -1) return node;
    return find(parent[node]);
}
`.split("\n");

const findImprovedCode = 
`int find(int node) {
    if(parent[node] == -1) return node;
    return parent[node] = find(parent[node]);
}
`.split("\n");

const unionCode = 
`void Union(int node1,int node2) {
    int root1 = find(u), root2 = find(node2);
    if(root1 == root2) return;
    if (maxDepth[root1] < maxDepth[2]) {
        parent[root1] = root2;
    } else if (maxDepth[root1] > maxDepth[2]) {
        parent[root2] = root1;
    } else {
        parent[root2] = root1;
        maxDepth[root1]++;
    }        
}
`.split("\n");

export const UnionFindAlgo = () => {
    return (
<div className="algocontainer">
    <Typography variant="body1">Union-Find is the algorithm that's used by the Disjoint-Set data structure. It tracks the sets to which elements belong, and has two efficient operations:</Typography>
    <div style={{marginTop: 10, marginBottom: 20}}>
        <span className="highlight">find(item)</span> Find the set to which item belongs
    </div>
    <div style={{marginTop: 10, marginBottom: 30}}>
        <span className="highlight">union(item1, item2)</span> Combine the sets of item1 and the item2 into a single set
    </div>
    <p>In practice, it is implemented using trees, like so:</p>

    <div style={{marginTop: 10, marginBottom: 20}}>
        <span className="highlight">find(node)</span> Find the root of the node's tree, as the representative of the tree
    </div>
    <div style={{marginTop: 10, marginBottom: 30}}>
        <span className="highlight">union(node1, node2)</span> Combine the trees of node1 and node2 into a single tree
    </div>

    <p>Let's look at find(node) first. Click on a node to find its root.</p>
    <div className="playground hzflex">
        <div className="fifty bucket">
            <Find debug={false} shouldPathCompress={false} redrawMethodName="redrawGraph1"/>
        </div>
        <div className="fifty">
            <Code code={findCode}/>
        </div>
    </div>
    <p>Path Compression is a technique used to speed up the find(node) operation, not on the first lookup, but on subsequent lookups. For each node node that we encounter in a find operation, we detach it from its parent, and attach it as the root's child. To see how that works, click on a node (to find its root).</p>    
    <div className="playground hzflex">
        <div className="fifty bucket">
            <Find debug={false} shouldPathCompress={true} redrawMethodName="redrawGraph2"/>
        </div>
        <div className="fifty">
            <Code code={findImprovedCode}/>
        </div>
    </div>
    <p>Great. Let's look at how union(node1, node2) works next. When combining two trees, we will attach the shorter tree's root to the taller tree's root, so that the combined tree doesn't become skewed. To do that, we must track each tree's max-depth, right from the beginning when there are zero edges. Click on two nodes to combine their trees.</p>
    <div className="playground hzflex">
        <div className="fifty bucket">
            <Union redrawMethodName="redrawGraph3"/>
        </div>
        <div className="fifty">
            <Code code={unionCode}/>
        </div>
    </div>
</div>
)}