import React from "react";

const problem = `Given the root of a binary tree, for each level, display the node values in a separate line, beginning from the leaf nodes.`, example = `Input:
1 2
1 6
2 4
2 5

Output:
4 5
2 6
1`;

export default function ReverseLevelOrder() {
    return (
        <div style={{textAlign: "left"}}>
            <pre>{problem}</pre>
            <div className="example">
                <pre>{example}</pre>
            </div>
        </div>)
}