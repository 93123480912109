import React from "react";

const problem = `Given the root of a binary tree, for each level, display the level order successor of the input node.`, example = `Input:
1 2
1 6
2 4
2 5
4

Output:
5`;

export default function LevelOrderSuccessor() {
    return (
        <div style={{textAlign: "left"}}>
            <pre>{problem}</pre>
            <div className="example">
                <pre>{example}</pre>
            </div>
        </div>)
}