import { Button } from "@mui/material";
import { useState } from "react";

const problems = [{
    code: 
`class Solution {
    output = empty
    function solveRecursively(problem, candidate) {
        if (candidate is one of the valid solutions) {
            output.add(candidate)
            return;
        }
        if (candidate cannot be developed anymore) {
            return;
        }
        for (each possible next step) {
            solveRecursively(problem + next step, candidate + next step)
        }
    }    
    function solve(problem) {
        solveRecursively(smallest step, empty)
        return output
    }
}`,
    problem: "Find all possible solutions to a backtracking problem."
}, {
    code: `class Solution {
    int n;
    output = empty

    function solveRecursively(i, candidate) {
        if (candidate.length == n) {
            // print candidate or add candidate to output
            return
        }
        for (int j = 0; j < 2; j++) {
            solveRecursively(i + 1, candidate + '0' + j)
        }
    }    
    function solve(N) {
        n = N;
        solveRecursively(0, "")
        return output
    }
}`,
    problem: `Print all binary strings of length n.`
}, {
    code: 
`class Solution {
    int n, k;
    output = empty

    function solveRecursively(i, candidate) {
        if (candidate.length == n) {
            // print candidate or add candidate to output
            return
        }
        for (int j = 0; j < k; j++) {
            solveRecursively(i + 1, candidate + '0' + j)
        }
    }    
    function solve(N, K) {
        n = N;
        k = K;
        solveRecursively(0, "")
        return output
    }
}`,
    problem: `Print all k-ary strings of length n.`
}, {
    code: 
`class Solution {
    vector <int> input;
    output = empty

    function solveRecursively(i, candidate) {
        // print candidate or add candidate to output

        for (int j = 0; j < input.size(); j++) {
            nextCandidate = combine(candidate, input[j])
            solveRecursively(i + 1, nextCandidate)
        }
    }    
    function solve(inputSet) {
        input = inputSet;
        solveRecursively(-1, empty)
        return output
    }
}    
    `,
    problem: `Print all subsets of the input set of integers.`
}, {
    code: 
`class Solution {
    int n;
    output = empty
    seen = empty

    function solveRecursively(i, candidate) {
        if (i == n) {
            // print candidate or add candidate to output
            return;
        }
        for (int j = 1; j <= n; j++) if (!seen[j]) {
            seen[j] = true;
            nextCandidate = combine(candidate, j)
            solveRecursively(i + 1, nextCandidate)
            seen[j] = false;
        }
    }    
    function solve(int N) {
        n = N;
        solveRecursively(-1, empty)
        return output
    }
}    
    `,
    problem: `Print all permutations of numbers between 1 and n.`
}]

export const BacktrackingAlgo = () => {
    let [problem, setProblem] = useState(0);
    let next = () => {
        if (problem + 1 < problems.length) {
            setProblem(problem + 1);
        }
    }
    let back = () => {
        if (problem - 1 >= 0) {
            setProblem(problem - 1);
        }
    }



return (
<div className="algocontainer">
    <p>Backtracking is a technique that uses recursion to explore all possibilities in a problem-space.</p>

    <p>Consider this problem: In a dark theater, how would you know what row you're sitting in? You'd ask the row in front of you "what row are you in?" They would ask the row in front of them and so on until they finally get to the first row, who would know they're in the first row, and would answer the second row's question. The second row would answer the third row, and so on until an answer comes back to the row that originally asked the question. What makes this an example of Recursion is that every row behaves just like the row in front of it -- it passes the question to its front row, and returns the answer to its back row. The only exception to this is the first row (the "base case"), which knows the answer without asking anyone else. [visualization]</p>

    <p>A recursive function calls itself, but each time with a different set of parameters. The only exception to this is the base case: when the parameter is the "base case", the function returns the answer without making any more function calls. Every recursive function call must eventually reach the base case, after a series of calls. Otherwise, it will be stuck in an infinite loop. [visualization]</p>
    
    <p>The template for a backtracking solution looks like this. Go ahead and click through the problems to get a sense of how this template looks for a few popular backtracking problems.</p>
    <div className="playground">
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 10}}>
            <Button variant="outlined" onClick={() => back()}>Back</Button>
                <Button variant="contained" onClick={() => next()}>Next</Button>
            </div>
        <div className="hzflex" style={{alignItems: "stretch"}}>
            <div style={{width: "50%"}} className="bucket">
                <div className="bucket-title">Problem</div>
                <pre>{problems[problem].problem}</pre>
            </div>        
            <div className="code" style={{width: "50%"}}>
                <pre>{problems[problem].code}</pre>
            </div>
        </div>
    </div>

    <p>A good method to write a recursive function is to believe that your function already works and finds the correct answer, even before you begin writing it. Then write the base case at the beginning of the function. [give example]</p>


{/* 
https://www.scaler.com/topics/data-structures/recursion-and-backtracking/  - has some good example problem names

https://leetcode.com/problems/n-queens/solutions/3223270/best-c-solution-backtracking/ - can use this code for n queens

*/}
</div>
)}