import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Code from "./Code";
import { Array } from "./problems/Array";

const code = ``.split("\n");
const generateSortedArray = (n) => {
    let arr = [];
    for (let i=0; i<n; i++) {
        arr.push(Math.floor(Math.random() * 10) * 10);
    }
    arr.sort((a,b) => a-b);
    return arr;
}
export function MergeSortCombine() {
    let [input, setInput] = useState([]);
    let [result, setResult] = useState([]);
    let [btn, setBtn] = useState("Next");
    let [i, setI] = useState(0);
    let [j, setJ] = useState(0);
    let [k, setK] = useState(0);
    let [middle, setMiddle] = useState(0);
    let [begin, setBegin] = useState(0);
    let [end, setEnd] = useState(0);
    let [info, setInfo] = useState("");

    useEffect (() => {
        input = generateSortedArray(4).concat(generateSortedArray(4));
        setInput(input);
        begin = 0;
        setBegin(begin);
        end = input.length;
        setEnd(end);
        middle = Math.floor((begin + end - 1) / 2);
        setMiddle(middle);        
        result = [];
        setResult(result);
        i = 0; setI(i);
        j = middle+1; setJ(j);
        k = 0; setK(k);

    }, []);

    let next = () => {
        if (i < middle && j < end) {
            if (input[i] < input[j]) {
                result[k] = input[i];
                i++; setI(i);
            } else {
                result[k] = input[j];
                j++; setJ(j);
            }
            setResult(result);
            k++; setK(k);
            return;
        }
        if (i <= middle) {
            result[k] = input[i];
            setResult(result);
            i++; setI(i);
            k++; setK(k);
            return;
        }
        if (j < end) {
            result[k] = input[j];
            setResult(result);
            j++; setJ(j);
            k++; setK(k);
            return;
        }
        setBtn("");
        setInfo("The two sorted arrays are now merged into a single sorted array.")
    }
    let getCls = (ii) => {
        if (ii >= i && ii <= middle) return "";
        if (ii >= j && ii < end) return "";
        return "grayedout";
    }

    return (
<>
    <p>The inputs to the combine method are three integers: begin, middle and end. The method expects the subarray from begin to middle-1, and the subarray frm middle to end to be sorted. Here, begin = {0}, end = {input.length-1}, and middle = {(input.length-1)/2}</p>
    <div className="hzflex">
        <div className="fifty">
            <Code code={code} />
        </div>
        <div className="fifty">
            {btn.length > 0 && (<Button variant="contained" onClick={() => next()} style={{marginBottom: 20}}>{btn}</Button>)}
            <div>Input:</div>
            <div style={{marginBottom: 20}}>
                <Array values={input} showIndex={true} showArrow={(ii) => (ii <= middle && ii == i) || ii == j} getCls={getCls} />            
            </div>
            <div>Merged Array:</div>
            <Array values={result} showIndex={true}/>
            {info.length > 0 && <Typography variant="body1" sx={{mt: 1}}>{info}</Typography>} 
        </div>
    </div>
</>
)
}