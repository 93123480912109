function parent(nodes, childNode) {
    return nodes[childNode.parent];
}
function resetTreeAppearance(nodes) {
    for (let i = 0; i < nodes.length; i++) {
        nodes[i].label = nodes[i].name;
    }
    return nodes;
}
function changeParent(nodes, childNode, newParentNode) {
    nodes[childNode.index].parent = newParentNode.index;
    return nodes;
}
function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}
  

function hasCycle(nodes) {
    let q = [];
    for (let i=0; i<nodes.length; i++) {
        if (nodes[i].parents.length == 0) q.push(i);
    }
    let tries=0, nnodes = JSON.parse(JSON.stringify(nodes));
    while(q.length > 0) {
        if (tries++ > 30) break
        // console.log(q.join(","))
        let f = q.shift(), edges = 0;
        for (let i=0; i<nnodes.length; i++) {
            if (nnodes[i].parents.length == 1 && nodes[i].parents[0] == f) q.push(i);
            nnodes[i].parents = nnodes[i].parents.filter((p) => p != f);
            edges = edges + nnodes[i].parents.length;
        }
        // console.log(edges)
        if (edges == 0) return false;  
    }
    console.log("has cycle")
    return true;
}
function genWeights(nodes) {
    console.log("genWeights")
    let weights = {};
    for (let i=0; i<nodes.length; i++) {
        weights[i] = {};
        for (let j=0; j<nodes.length; j++) {
            weights[i][j] = 0;
        }
    }
    for (let i=0; i<nodes.length; i++) {
        for (let j=0; j<nodes[i].parents.length; j++) {
            weights[nodes[i].parents[j]][i] = rand(10)+1;
            console.log("setting weight", nodes[i].parents[j], i, weights[nodes[i].parents[j]][i])
        }
    }
    return weights;
}

const bfs = (nodes) => { // adds a level attribute to each node
    let roots = [];
    for (let i=0; i<nodes.length; i++) {
      if (nodes[i].parents.length == 0 && nodes[i].children.length > 0) roots.push(i);
    }    
    for (var j in roots) {
        let visited = {};
        let queue = [];
        queue.push([roots[j], 0]);
        visited[roots[j]] = true;
        while(queue.length > 0) {
            let pop = queue.shift(), f = pop[0];
            nodes[f].level = pop[1];
            // console.log(nodes[f].label, nodes[f].level)
            for (let i=0; i<nodes[f].children.length; i++) {
                visited[nodes[f].children[i]] = true;
                queue.push([nodes[f].children[i], nodes[f].level+1]);
            }
        }
    }
    return nodes;
}
function genCompleteBinaryTree(n=4) {
    let nodes = [];
    for (let i=0; i<n; i++) {
        nodes.push({
            index: i,
            label: 10 + i*10,
            parents: [],
            children: []
        });
    }
    for (let i=0; i<n; i++) {
        let possibleChildren = [i*2 + 1, i*2 + 2];
        for (let j=0; j<possibleChildren.length; j++) {
            if (possibleChildren[j] < n) {
                nodes[i].children.push(possibleChildren[j]);
                nodes[possibleChildren[j]].parents = [i];
            }
        }
    }
    return nodes;      
}
function generateGraph({n=4, isDag=true, parentCnt=() => rand(2)+1, parentFn=() => rand(n)}) {
    return genGraph(n, isDag, parentCnt, parentFn);
}
function generateWeightedGraph({n=4, isDag=true, parentCnt=() => rand(2)+1, parentFn=() => rand(n)}) {
    let nodes = genGraph(n, isDag, parentCnt, parentFn);
    let weights = genWeights(nodes);
    return {nodes, weights};
}

function genNodes(n=4) {
    let nodes = [];
    for (let i=0; i<n; i++) {
        nodes.push({
            index: i,
            label: i,
            parents: [],
            children: []
        });
    }
    return nodes;
}
function genGraph(n=4, isDag=true, parentCnt=() => rand(2)+1, parentFn=() => rand(n)) {
    let nodes = genNodes(n);
    for (let i=0; i<n; i++) {
        let parentCount = parentCnt();
        let tries = 0;
        while (nodes[i].parents.length < parentCount) {
            if (tries++>100) break;
            let parent = parentFn();
            if (parent == i || nodes[parent].parents.includes(i)) continue; 
            nodes[i].parents = nodes[i].parents.concat(parent);  
            if (isDag && hasCycle(nodes)) {                
                nodes[i].parents = nodes[i].parents.filter((p) => p != parent); // undo the edge you just added
            }
        }
    }
    for (let i=0; i<n; i++) { 
        for (let j=0; j<nodes[i].parents.length; j++) {
            let parent = nodes[i].parents[j];
            nodes[parent].children = nodes[parent].children.concat(i).filter(onlyUnique);
        }
    }

    return nodes;      
}
function rand(n) {
    return parseInt((Math.random() * 10000000000000) % n);
}
export { parent, resetTreeAppearance, changeParent, genGraph, genWeights, bfs, genCompleteBinaryTree, generateGraph, generateWeightedGraph, genNodes };
