
export default function MinDepthSolution() {

    return (
        <div>
            <p>When you do BFS on a tree starting from root, the traversal happens level by level. See <a href='/breadth-first-search/algorithm:-tree-bfs'>here</a> for more information.</p>
            <p>Therefore, the answer to this problem is to simply do BFS on the tree, and return the first leaf node that you encounter, just before adding it to the queue. Of course, you may also return the first leaf node you see that you pop from the queue.</p> 
        </div>

        
    )
}