export default function Code({code, codeLine}) {
    return (
        <div className="code">
            {code.map((line, i) => (
            <div className={"line " + ((codeLine == i) ? "selected" : "")} key={i}>
                {/* <span className="linenumber">{i}</span> */}
                <pre>{line}</pre>
            </div>))}
        </div>
    )
} 