import React from "react";

const problem = `You are given an array of strings equations that represent relationships between variables where each string equations[i] is of length 4 and takes one of two different forms: "xi==yi" or "xi!=yi".Here, xi and yi are lowercase letters (not necessarily different) that represent one-letter variable names.

Return true if it is possible to assign integers to variable names so as to satisfy all the given equations, or false otherwise.`, 

examples = [`Input: 
equations = ["a==b","b!=a"]

Output: 
false

Explanation: 
If we assign say, a = 1 and b = 1, then the first equation is satisfied, but not the second.
There is no way to assign the variables to satisfy both equations.`,`Input: 
equations = ["b==a","a==b"]

Output: 
true

Explanation: 
We could assign a = 1 and b = 1 to satisfy both equations.`];

export default function EqualityEquations() {
    return (
        <div style={{textAlign: "left"}}>
            <pre>{problem}</pre>
            {examples.map((example, index) => (
                <div key={index} className="example">
                    <pre>{example}</pre>
                </div>
            ))}
        </div>)
}