import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Code from "./Code";
import { Array } from "./problems/Array";
import { generateInput, getClassName } from "./QuickSortFunctions";

function sleep(ms=2000) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const code = 
`partition(lo, hi): 
  pivot = A[hi]
  i = lo - 1
  j = lo
  while j < hi 
    if A[j] <= pivot 
        i++
        swap A[i] with A[j]
    j++
  i++
  swap A[i] with A[hi]  
  return i`.split("\n");


export const QuickSortPartition = () => {
    let [input, setInput] = useState([]);
    let [pivot, setPivot] = useState(-1);
    let [waiting, setWaiting] = useState(false);
    let [i , setI] = useState(0);
    let [j, setJ] = useState(0);
    let [info, setInfo] = useState("");
    let [pivotIndex, setPivotIndex] = useState(-1);
    let [btn, setBtn] = useState("Initialize");
    let [line, setLine] = useState(-1);

    useEffect(() => {
        input = generateInput();
        console.log(input)
        setInput(input);
        pivot = -1;
        setPivot(pivot);

        i=-1;
        j=-1;
        setI(-1);
        setJ(-1);
        setInfo("");
        setPivotIndex(-1);
    }, []);

    let swap = async (i, j) => {
        if (i == j) return;        
        setInput([...input]);
        let temp = input[i];
        input[i] = input[j];
        input[j] = temp;
        setInput([...input]);
    }        
    let setExpectation = () => {
        if (j+1 > input.length-1) return;
        if (j+1 == input.length-1) {
            setInfo("Nearly done. Swap number at i+1 with the pivot, so that pivot is in-between red and blue numbers.")
            return
        }
        info = "Will process " + input[j + 1] + " next. ";
        if (input[j+1] <= pivot) {
            info += "It is smaller than " + pivot + ". So swap " + input[i+1] + " and " + input[j+1] + " & increment i and j.";
        } else {
            info += "It is bigger than " + pivot + ". So, simply increment j.";
        }
        setInfo(info);        
    }
    let next = async () => {        
        let l = 0, h = input.length - 1;
        if (j > h) return;
        if (pivot == -1) {
            setInfo("Setting pivot = " + input[h] + ", i = " + (l - 1)  + ", j = " + l);
            setLine(1); await sleep(300);
            pivotIndex = h;
            setPivotIndex(pivotIndex);
            pivot = input[h]
            setPivot(pivot);
            i = l - 1
            setI(i);
            setLine(2); await sleep(300);
            j = l - 1;
            setJ(j);
            setLine(3);
            setBtn("Next")
            setExpectation();
        } else if (j + 1 < h) {            
            j = j + 1;
            setJ(j);
            
            if (input[j] <= pivot) {
                setLine(6); await sleep(300); setLine(7); await sleep(300); setLine(8); 
                i = i + 1
                setI(i);
                swap(i, j)
            } else {
                setLine(5); await sleep(300); setLine(8);
            }
            setExpectation();
        } else {
            // j == h
            i = i + 1
            setI(i);
            
            swap(i, h)
            setPivotIndex(i);

            j = j + 1; setJ(j);
            setLine(9); await sleep(300); setLine(10); await sleep(300); setLine(11);
            setInfo("Finished partitioning!");
            setBtn("");
        }  
    }

    let getCls = (ii) => {
        return getClassName({ii, lo:-2, hi:-2, i, j, pivotIndex});
    }

    let itFn = (ii) => {
        if (ii == i && ii == j) return "i=j="+ii;
        if (ii == i) return "i="+ii;
        if (ii == j) return "j="+ii;
        return ii;
    }
    return (
<div className="">
    <div>While partitioning an array, we'll choose the last number as the pivot. In each step, the array will look like this:</div>
        <div style={{textAlign: "center", display: "inline-block"}}>
            <div style={{width: "fit-content", padding: 5}} className="arrayitem quicksort smaller">{'<='} pivot</div>
            <div style={{width: "fit-content", padding: 5}} className="arrayitem quicksort greater">{'>'} pivot</div>
            <div style={{width: "fit-content", padding: 5}} className="arrayitem quicksort ">unprocessed</div>
            <div style={{width: "fit-content", padding: 5}} className="arrayitem quicksort pivot">pivot</div>
        </div>
    
    <p>In each step, we'll process one unprocessed number. If it's greater than pivot, we'll simply grow the blue subarray to include the new number. If it is smaller, we'll put it in the red subarray, in-place, using a slightly tricky bit of swapping. After processing all the numbers, we'll move pivot in between the red and blue subarrays. Go ahead and try it out in the playground below!</p>

    <div className="hzflex playground" style={{marginTop: 20}}>
        <div className="fifty">
            <Code code={code} codeLine={line}/>
        </div>  
        <div className="fifty" style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <div>
                {btn.length > 0 && (<Button fullWidth={true} style={{marginBottom: 10}} variant="contained" onClick={() => next()} disabled={waiting}>{btn}</Button>)}
                <div>
                    <Array values={input} getCls={getCls} showIndex={false} itFn={itFn}/>
                </div>
                {info.length > 0 && <Typography variant="body1" sx={{mt: 1}}>{info}</Typography>}
            </div>
        </div>  
    </div>      
</div>
)}