
export default function LevelOrderTraversalSolution() {

    return (
        <div>
            <p>When you do BFS on a tree starting from root, the traversal happens level by level. See <a href='/breadth-first-search/algorithm:-tree-bfs'>here</a> for more information.</p>
            <p>Therefore, the answer to this problem is to simply do BFS on the tree, and print the popped element from queue, in the order in which they get popped.</p> 
        </div>

        
    )
}