import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Code from "./Code";
import * as Constants from "./Constants";
import Graph from "./Graph";
import { bfs, genGraph } from "./GraphFunctions";

const clickedNodesColor = Constants.CLICKED_NODE_COLOR, defaultNodeColor = Constants.DEFAULT_NODE_COLOR, visitedColor = Constants.QUEUE_ITEM_COLOR, queueColor = Constants.QUEUE_ITEM_COLOR;

function rand(n) {
    return parseInt((Math.random() * 10000000000000) % n);
}
const code = [`create a queue Q`, 
`mark v as visited & put v into Q`,
`while Q is non-empty`, 
`    pop the head u of Q`,
`    //if a neighbour of u == target node: return`,
`    enqueue unvisited neighbours of u & mark them as visited`];

const genGraphLocal = (isTree) => {
    return isTree ? bfs(genGraph(10, true, () => 1, () => rand(10/2))) : genGraph(10, false, () => rand(2)+1);
}
function sleep() {
    return new Promise(resolve => setTimeout(resolve, 500));
}

export default function BFS({isTree=true, search=true}) {
    let [nodes, setNodes] = useState([]);
    let [progressStatement, setProgressStatement] = useState("Click the node to start BFS from"); 
    let [clickedNodes, setClickedNodes] = useState([]);
    let [bfsNodes, setBfsNodes] = useState([]);
    let [queue, setQueue] = useState([]);
    let [step, setStep] = useState(0);
    let [btn, setBtn] = useState("");
    let [seen, setSeen] = useState({});
    let [justPopped, setJustPopped] = useState(null);
    let [result, setResult] = useState("");
    let [selectedLine, setSelectedLine] = useState(-1);
    let [sleeping, setSleeping] = useState(false);
    
    useEffect (() => {
        console.log("generating graph")
        nodes = genGraphLocal(isTree);
        setNodes(nodes);        
        window.redrawGraph({relayout: true}, nodes);
    }, []);
    const onNodeClick = async (node) => {
        if (clickedNodes.find((n) => n.index === node.index)) {
            redraw(node.index, "fill", defaultNodeColor);
            clickedNodes = clickedNodes.filter((n) => n.index !== node.index);
            setClickedNodes(clickedNodes);   
            return;
        }
        clickedNodes.map((n) => redraw(n.index, "fill", defaultNodeColor));
        clickedNodes = [...clickedNodes, node].slice(-2);
        setClickedNodes(clickedNodes);
        
        if (clickedNodes.length == 0) return;
        redraw(clickedNodes[0].index, "fill", queueColor);  
        if (!search && clickedNodes.length == 1) {
            setBfsNodes([clickedNodes[0]]);
            setClickedNodes([]);
            setBtn("Start BFS");
            setProgressStatement("");
            return;
        }           
        (clickedNodes.length == 1) && setProgressStatement("Click another node, the BFS target");        
        if (clickedNodes.length === 2) {
            redraw(clickedNodes[1].index, "fill", clickedNodesColor);

            setProgressStatement("");
            let node1 = clickedNodes[0], node2 = clickedNodes[1];
            clickedNodes = [];
            setClickedNodes([]);
            setBfsNodes([node1, node2]);
            setBtn("Start BFS")
        }
    }
    const redraw = (nodeIndex, field, value) => {
        nodes[nodeIndex][field] = value;
        setNodes(nodes);
        window.redrawGraph();
    }
    const takeStep = async () => {
        if (step == 0) { // bfs has not yet started
            setSelectedLine(0);
            setStep(step+1);
            redraw(bfsNodes[0].index, "fill", queueColor);            
            seen[bfsNodes[0].index] = true;
            setSeen(seen);            
            queue.push(bfsNodes[0]);
            setQueue(queue);
            setSelectedLine(1);
            setBtn("Next Step")       
            return;
        }
        if (justPopped) {
            setSelectedLine(5);
            setSleeping(true);
            for (let i = 0; i < justPopped.children.length; i++) {
                if (bfsNodes[1] && justPopped.children[i] == bfsNodes[1].index) {                
                    setSelectedLine(4);
                    setJustPopped(null);
                    setResult("Found a path from " + bfsNodes[0].label + " to " + bfsNodes[1].label + "!");
                    return;
                }
    
                if (!seen[justPopped.children[i]]) {                    
                    queue.push(nodes[justPopped.children[i]]);
                    setQueue(queue);
                    redraw(justPopped.children[i], "fill", queueColor);
                    seen[justPopped.children[i]] = true;
                    setSeen(seen);
                    await sleep();
                }
            }
            justPopped = null;
            setJustPopped(justPopped);
            setBtn("Pop another node from the queue");
            setSleeping(false);
            
            return;     
        }
        if (queue.length == 0) {
            setResult(search ? "No path found." : "BFS is complete!");
            return;
        }        
        justPopped = queue.shift();
        setJustPopped(justPopped);
        setQueue(queue);
        let unqueuedNeighbours=0;
        for (let i = 0; i < justPopped.children.length; i++) if (!seen[justPopped.children[i]]) unqueuedNeighbours++;
        if (unqueuedNeighbours == 0) {
            setBtn("Pop another node from the queue");
            return;
        }
        setBtn("Enqueue " + justPopped.label + "'s unvisited neighbors");
        setSelectedLine(3);
    }
    return (
<div className="algocontainer">    
    <div className="code-and-btns">
        <div className="onebythree">
            <Code code={code} codeLine={selectedLine} />
        </div>
        <div className="playground twobythree">
            <div className="">
                <div>
                    {result && <Typography className="result" variant="body1" sx={{mb: 1}}>{result}</Typography>}
                    {!result && progressStatement && <Typography variant="body1" sx={{mb: 1}}>{progressStatement}</Typography>}
                </div>
                {!result && btn.length > 0 && (
                <Button sx={{mt: 1, mb: 1}} fullWidth="true" size="small" variant="contained" onClick={() => takeStep()} disabled={sleeping}>{btn}</Button>)}                
            </div>
            <div className="hzflex">
                <div className="fifty">
                    <Graph nodes={nodes} setNodes={setNodes} onNodeClick={onNodeClick}/>
                </div>
                {step > 0 && (
                <div className="fifty">
                    <div className='popped-and-queue'>
                        {justPopped && (<div className='popped'>{justPopped.label}</div>)}
                        <div className="bucket" style={{marginTop: 30}}>
                            <div className="bucket-title">Q (queue)</div>
                            <div className="nodes">
                                {queue.map((it, i) => (<span key={i} className="queueitem" style={{backgroundColor: queueColor}}>{it.label}</span>))}
                            </div>
                        </div>
                    </div>
                    
                    <div className="bucket">
                        <div className="bucket-title">Visited (hashmap)</div>
                        {Object.keys(seen).map((it, i) => (<div key={i} className="" style={{}}>{it + ": " + seen[it]}</div>))}
                    </div>
                </div>)}
            </div>
        </div>
    </div>
    
</div>
)}