import { useState } from "react";
import KMPBoth from "./KMPBoth";
import KMPBruteforce from "./KMPBruteforce";
import { genString } from "./KMPFunctions.jsx";

function genPattern(n) {
    let pat = genString(n);
    let prefix = pat.substring(0, Math.floor(Math.random()*(n-2))+2);
    console.log(pat, prefix) 
    pat = pat + prefix;        
    return pat;
}

function genText(n, pat) {
    let text = "";
    while (text.length < n) {
        text += pat.substring(0, Math.floor(Math.random()*(pat.length-1)));
        if (Math.random() * 100 < 50)
            text += genString(3);
    }
    text += pat + genString(3);
    return text;
}
let ex = [{
    text: "BBABBABBABB",
    pattern: "ABBAABB",
    i: 2,
    j: 4
}, {
    text: "DADBDADBDADAADA",
    pattern: "DADBDADAADA",
    i: 0,
    j: 7

}];
export default function KMPAlgo() {
    let [pattern, setPattern] = useState("ABBAABB"); //genPattern(4) + genPattern(3));
    let [text, setText] = useState("BBABBABBABB"); //genString(40, 2)); //genText(40, pattern));

    let [nextBackKey, setNextBackKey] = useState("Next");
    let [curEx, setCurEx] = useState(0);
    
    let nextExample = () => {        
        if (curEx+1 < ex.length) {
            setCurEx(curEx+1);
        }      
        // setPattern(genPattern(7));
        // setText(genText(20, pattern));     
    }
    return (
        <div className="algocontainer"> 
            <p>The Knuth-Morris-Pratt algorithm (or KMP algorithm) searches for occurrences of a pattern string within a text string.</p>           
            <p>To begin, let's try doing this in the bruteforce way. We'll use i for iterating over text, and j for iterating over pattern.</p>
            <div className="playground">  
                <KMPBruteforce isKmp={false} text={text} pattern={pattern} showOnlyCells={false}/>
            </div>
            <p>Suppose we stop here:</p>
            <div className="playground">  
                <KMPBruteforce isKmp={false} text={text} pattern={pattern} showOnlyCells={false} startI={2} startJ={4} standalone={false}/>
            </div>
            <p>Here, we've matched only a prefix of the pattern, and then we found a mismatch of letters. Because of the mismatch, we have to move i forward on text. <i>What is the next value for i and j?</i> This is the question that KMP answers. Of course, while brute-forcing, we would set i = i + 1 and j = 0. So i would become 3, and j would be 0. But the KMP algorithm does better than that. It skips a lot of useless comparisons that the bruteforce method goes through. In the playground below, you can click through the examples and see what KMP and Bruteforce look like, in each case.</p>
            {/* <p>Let's go ahead and hide the letters in text, so that it's simpler to see what's happening.</p> */}

            {/* But what if we magically knew, without checking text[3] with pattern[0], that these two letters won't match? What if we knew that none of the letters in text from i to i+5 would match pattern[0]? Then we could skip all of them, and do i=i+6. We would save a lot of wasteful comparisons that way. How can we figure out how much to skip for the next value of i?</p> */}
            <div className="playground">  
                <KMPBoth key={curEx} text={ex[curEx].text} pattern={ex[curEx].pattern} nextExample={nextExample} i={ex[curEx].i} j={ex[curEx].j}/>
            </div>
            {/* <p>Remember, we already have a partial match of 4 letters, from text[2] to text[5]". The first four letters of pattern is {pattern.substring(0,4)}. So text[2] must be A, text[3] must be B, text[4] must be B and text[5] must be A. The current value of i is 2. Its next value can't be 3 or 4, since text[3]=B and text[4]=B, and our pattern does not start with B. However, text[5] is A, and our pattern starts with A. So restarting the comparisons from i = 5 MIGHT lead to a complete match! So we can set i to 5, and j to 0.</p>
             
            <p>Whenever we have a partial match and then a mismatch, we can jump i to the next occurrence of A within the matched-part, and set j=0. But how can we quickly figure out where the next occurrence of A will be, in the partially-matched part of text? Here is the thing. We just need to know where the next occurrence of A will be, in the partially-matched part of <i>pattern</i>.</p>
            <p>We can do that by pre-processing pattern. [more info]</p> */}
            {/* <div className="playground">  
                 
                <div>
                    
                </div>
            </div>

            <KMP isKmp={true} text={"BACBACBABD"} pattern={"BACBABD"} /> */}
        </div>
    )
}