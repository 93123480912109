import { MergeSort } from "./MergeSort";
import { MergeSortCombine } from "./MergeSortCombine";

export const MergeSortAlgo = () => {
    return (
<div className="algocontainer">
    <p>Mergesort is a recursive sorting algorithm. In each call, it recursively sorts the left-half and right-half subarrays, and then merges them.</p>
    <MergeSortCombine />
    <p>Here is the full algorithm:</p>
    <MergeSort />
</div>
)}