import { Button } from "@mui/material";
import { useState } from "react";
import { bruteforce } from "./KMPFunctions.jsx";

export default function KMPBruteforce({text, pattern, showOnlyCells=true, startI=0, startJ=0, standalone=true}) {
    let [i, setI] = useState(startI);
    let [j, setJ] = useState(startJ);
    let [lps, setLPS] = useState(null);
    let [btn1, setBtn1] = useState("i = i + 1");
    let [btn2, setBtn2] = useState("j = j + 1");
    let [nextBackKey, setNextBackKey] = useState("next");

    let [sleeping, setSleeping] = useState(false);

    let getTextLetterCls = (ii) => {
        let s = (i+j >= ii && i <= ii)? "highlighted kmp-cell" : "kmp-cell";
        if (i+j == ii) {
            s += (text[i+j] == pattern[j] ? " matched" : " unmatched");
        }
        return s;
        
    }
    let getPatternLetterCls = (jj) => {
        let s = j >= jj ? "highlighted kmp-cell" : "kmp-cell";
        if (j == jj)
            s += (text[i+j] == pattern[j] ? " matched" : " unmatched");
        return s;
    }
    let [stack, setStack] = useState([]);
    let nextback = async () => {
        if (nextBackKey == "next") {
            setNextBackKey("back");
            next();
        }
        else {
            setNextBackKey("next");
            back();
        }
    }
    let back = () => {
        if (stack.length > 0) {
            let {i, j} = stack.pop();
            setI(i);
            setJ(j);
            console.log("popped " + i + " " + j)
        }
    }
    let next = async () => {
        if (i == text.length-1 || j == pattern.length-1) return;

        stack.push({i, j});
        console.log("pushing " + i + " " + j)        

        setSleeping(true);
        await bruteforce({text, pattern, i, j, setI, setJ});
        setSleeping(false);        
    }

    function Space() {
        return (
            text.substring(0, i).split("").map((t, ii) => (<div key={ii} className="kmp-cell opaque">{t}</div>))
        )
    }
    return (
        
        <div className="">            
            <div className={showOnlyCells ? "show-only-cells" : ""}>
                {standalone && (
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 20}}>
                    <div>
                        <Button style={{textTransform: "lowercase", marginRight: 20}} variant='outlined' onClick={() => next()} disabled={sleeping || (text[i+j] == pattern[j])}>{btn1}</Button>
                        <Button style={{textTransform: "lowercase"}} variant='outlined' onClick={() => next()} disabled={sleeping || (text[i+j] != pattern[j])}>{btn2}</Button>                    
                    </div>
                    {standalone && (<Button style={{marginRight: 20}} variant='outlined' onClick={() => back()} disabled={sleeping}>Back</Button>)}
                </div>)}
                {!standalone && (<Button style={{marginRight: 20}} variant='outlined' onClick={() => nextback()}>{nextBackKey == "next" ? "Next" : "Back"}</Button>)}
                
                <div className="">
                    <div>
                        {text.split("").map((t, ii) => (<div className="kmp-cell" key={ii}>
                            <div style={{opacity: (i == ii) ? 1 : 0.5, fontSize:12, textAlign: "center"}}>{ii}</div>
                            <div className={getTextLetterCls(ii)}>{showOnlyCells ? " " : t}</div>
                        </div>))}
                    </div>
                    <div>
                        <Space />
                        {pattern.split("").map((p, jj) => (<div className="kmp-cell" key={jj}>
                            <div className={getPatternLetterCls(jj)}>{p}</div>
                            <div style={{opacity: (j == jj) ? 1 : 0.5, fontSize:12, textAlign: "center"}}>{jj}</div>                            
                        </div>))}
                    </div>
                
                    {standalone && (
                    <div>
                        <Space />
                        i={i}, j={j}                   
                    </div>)}
                </div>
            </div>
        </div>
    )
}