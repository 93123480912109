import Dijkstra from "./Dijkstra";

export const DijkstraAlgo = () => {
return (
<div className="algocontainer">
    <p>Dijkstra's algorithm is used to find the shortest path between two nodes in a weighted graph. It is similar to Breadth First Search, but instead of a queue, it uses a priority queue to prioritize the nodes that have the shortest distance from the starting node.</p>
    <div className="">
        <Dijkstra search={false}/>
    </div>
</div>
)}