import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";
import MinPathSum from "./problems/MinPathSum";

const stmts = [
`Given a m x n grid filled with non-negative numbers, find a path from top left to bottom right, which minimizes the sum of all numbers along its path. You can only move either down or right at any point in time.`,
`Input: grid = [[1,3,1],[1,5,1],[4,2,1]]
Output: 7
Explanation: Because the path 1 → 3 → 1 → 1 → 1 minimizes the sum.`];

const Problem = () => {
    return (
        <div style={{textAlign: "left"}}>
            <pre>{stmts[0]}</pre>
            <div className="example">
                <pre>{stmts[1]}</pre>
            </div>
        </div>            
    )
};

const steps = [{
    label: 'Problem',
    component: <Problem/>
},{
    label: 'Solution',
    component: <MinPathSum/>
}
];

export const MinPathSumStepper = () => {
    let [activeStep, setActiveStep] = useState(1);

    return (
<div className="algocontainer">
    <div className="info">
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel style={{color: "#fff !important"}} onClick={() => {                                            
                            setActiveStep(index)
                        }}>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    </div>
    <div className="section">
        <div className="section-body">
            <div>
                {steps[activeStep].component}
            </div>
        </div>
    </div>
</div>
)}