import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";

export const ProblemStepper = (props) => {
    let [activeStep, setActiveStep] = useState(1);

    const steps = [{
        label: 'Problem'
    },{
        label: 'Solution'
    }];
    
    return (
        <div className="algocontainer">
            <div className="info">
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel style={{color: "#fff !important"}} onClick={() => {                                            
                                    setActiveStep(index)
                                }}>{step.label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </div>
            <div className="section">
                <div className="section-body">
                    <div>
                        {(activeStep == 0) && props.problem}
                        {(activeStep == 1) && props.solution}
                    </div>
                </div>
            </div>
        </div>
    );
}