import { Button, Typography } from "@mui/material";

import Graph from "./Graph";
import React, { useEffect, useState } from "react";
import { genGraph } from './GraphFunctions.jsx';
import Code from "./Code";
import * as Constants from "./Constants";

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
const color1 = Constants.HIGHLIGHTED_NODE_COLOR, color3 = Constants.DEFAULT_NODE_COLOR;

const code = [
"SortedList ← Empty list that will contain the sorted elements",
"Queue ← Queue of all nodes with no incoming edge",
"while Queue is not empty",
"    pop N from Queue & add it to SortedList",
"    for each neighbour of N",
"        delete the edge between N and neighbour",
"        if this neighbour has no other incoming edges",
"            enqueue it",
"if graph still has edges",
"    return error (graph has at least one cycle)",
"else ",
"    return SortedList"];
const TopologicalSort = () => {
    let [result, setResult] = useState(null);
    let [btn, setBtn] = useState("Initialize Topological Sort");
    let [noIncomingEdges, setNoIncomingEdges] = useState([]);
    let [sorted, setSorted] = useState([]);
    let [justPopped, setJustPopped] = useState(null);
    let [incrementingKey, setIncrementingKey] = useState(0);
    let [codeLine, setCodeLine] = useState(-1);
    let [nodes, setNodes] = useState([]);
    let [sleeping, setSleeping] = useState(false);

    let [newNodesToAdd1, setNewNodesToAdd1] = useState([]);
    let [newNodesToAdd2, setNewNodesToAdd2] = useState([]);
    
    const color = (nodeIndex, clr) => {
        nodes[nodeIndex].fill = clr;
        redraw();
    }
    useEffect(() => {
        nodes = genGraph(10);
        setNodes(nodes);
        window.redrawGraph({relayout: true}, nodes);
    }, []);
    const next = async () => {
        if (codeLine == -1) {
            initTopoSort();
            return;
        }
        if (newNodesToAdd1.length > 0) {
            newNodesToAdd2 = [...newNodesToAdd1];
            setNewNodesToAdd2(newNodesToAdd2);
            newNodesToAdd1 = [];
            setNewNodesToAdd1(newNodesToAdd1);
            setCodeLine(7);
            setBtn("Pop a node")
        }
        else if (justPopped != null) {
            removeOutEdges();
        }
        else {
            pop();
        }
    }

    const initTopoSort = async () => {
        setResult(null);
        setCodeLine(0);
        // await sleep();
        setCodeLine(1);
        // await sleep();

        let num=0, childCount = {};
        noIncomingEdges = [];
        for (let i = 0; i < nodes.length; i++) {
            for (let j = 0; j < nodes[i].parents.length; j++) {
                childCount[nodes[i].parents[j]] = childCount[nodes[i].parents[j]] || 0;
                childCount[nodes[i].parents[j]] = childCount[nodes[i].parents[j]] + 1;
            }
            if (nodes[i].parents.length == 0) {
                noIncomingEdges.push(i);
                nodes[i].fill = color3;
                num++;
            }
        }
        redraw();

        setNoIncomingEdges(noIncomingEdges);
        if (num > 0) {
            setBtn("Pop a node");
        } else {
            setCodeLine(9);
            setResult("This graph has at least one cycle. It cannot be sorted topologically.");
            return;
        }
    }
    
    const pop = () => {        
        noIncomingEdges = [...noIncomingEdges, ...newNodesToAdd2].filter(onlyUnique);
        setNewNodesToAdd2([]);
        setNoIncomingEdges(noIncomingEdges);

        if (noIncomingEdges.length == 0 && sorted.length < nodes.length) {
            setCodeLine(9);
            setResult("This graph has a cycle. No topological sort exists.");
            return;
        }
        setCodeLine(3);
        let node = noIncomingEdges.shift();
        sorted.push(node);
        setSorted(sorted);
        justPopped = node;
        setJustPopped(node);
        color(node, color1);
        setBtn("Delete " + justPopped + "'s outbound edges");
        if (sorted.length == nodes.length) {
            setCodeLine(11);
            setResult("Topological sort complete!");
            setBtn("");
            return;
        }
        
    }
    const redraw = (opts) => {
        setNodes(nodes);
        window.redrawGraph(opts);
    }
    const removeOutEdges = async () => {    
        setCodeLine(5);

        let edges = 0;
        newNodesToAdd1 = [];
        console.log("before: ", nodes)
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].parents.length == 1 && nodes[i].parents[0] == justPopped) {
                newNodesToAdd1.push(nodes[i].index);
                nodes[nodes[i].index].fill = color3;
            }
            nodes[i].parents = nodes[i].parents.filter(function(parent) { return parent != justPopped});
            edges = edges + nodes[i].parents.length;
        }
        console.log("after: ", nodes)
        redraw({redrawEdges:true});        
        setIncrementingKey(incrementingKey + 1);
        setNewNodesToAdd1(newNodesToAdd1);
        color(justPopped, color1);
        setJustPopped(null);
        setBtn("Enqueue new nodes without in-edges");
    }

    return (  
<div className="code-and-btns hzflex">
    <div className="fifty">
       <Code code={code} codeLine={codeLine} />
    </div>
    <div className="bucket fifty">                    
        {result && <div style={{marginBottom: 20}}><Typography variant="body1" className="result">{result}</Typography></div>}
        {btn.length > 0 && <Button sx={{mb: 2}} fullWidth={true} size="small" variant="contained" onClick={() => next()} disabled={sleeping}>{btn}</Button>}            
        {codeLine >= 0 && (
        <div className="bucket-sorted-nodes bucket" style={{marginBottom: 20}}>
            <div className="bucket-title">Topologically Sorted Nodes</div>
            <div className="nodes">
                {sorted.map((it, i) => (<span key={i} className="sortitem">{it}</span>))}
            </div>
        </div>)}
        {!result && (<>           
            {codeLine >= 1 && (
            <div key={incrementingKey} className="bucket-no-incoming-edges bucket">
                <div className="bucket-title">Queue</div>
                <div className="nodes">
                    {noIncomingEdges.map((it, i) => (<span key={i} className="queueitem">{it}</span>))}
                    {newNodesToAdd2.map((it, i) => (<span key={i} className="queueitem new">{it}</span>))}
                </div>
            </div>)}                
            </>)}
        <Graph nodes={nodes} setNodes={setNodes} fadeOutEdges={true} forceManyBody={true}/>
    </div>        
</div>            

)}

export default TopologicalSort;