export default function CallStack({recursionStack, incrementingKey}) {
    return (
<div className='bucket' style={{flexGrow: 0}}>
    <div className="bucket-title" style={{top: -10, right: 5, padding: 0}}>Call Stack</div>
    <div className="nodes" style={{display: "flex", flexDirection: "column-reverse", justifyItems: "flex-end", width: 50, height: "100%"}} key={incrementingKey}>
        {recursionStack.map((it, i) => (<div key={i} className="callstackitem" style={{textAlign: "center", backgroundColor: "#4cb8ba", color: "white", marginTop: 2}}>
            {it.label} {it.i >= 0 && (<span>, i = {it.i}</span>)}
        </div>))}
    </div>
</div>
)} 