
let generateInput = () => {
    let input = [];
    for (let i = 0; i < 20; i++) {
        input.push(Math.floor(Math.random() * 10) * 10);
    }
    let sorted = [...input].sort((a, b) => a - b);
    let middle = sorted[sorted.length/2];
    input = input.filter((v, i, a) => a.indexOf(v) == i && v != middle);
    input.push(middle);
    return input;
}

let getClassName = ({ii, i, j, pivotIndex, lo, hi}) => {
    let c = "quicksort ";
    if (ii == pivotIndex && pivotIndex != -1) {
        c += " pivot";
    }
    if (ii >= lo && ii <= i) c += " smaller";
    if (ii > i && ii <= j) c += " greater";
    if (ii > j && ii <= hi) c += " ";
    if (lo == -1 && hi == -1) c += " sorted";
    return c;
}
export { generateInput, getClassName };
