import { SlidingWindow } from "./SlidingWindow";

const generateRandomArray = (size, isBinary) => {
    let array = [];
    for (let i = 0; i < size; i++) {
        let sign = 1; //Math.random() * 100 < 50 ? -1 : 1;
        if (isBinary) array.push(Math.floor(Math.random() * 2));
        else array.push(sign * Math.floor(Math.random() * 20));
    }
    return array;
}

export const SlidingWindowAlgo = () => {
    return (
<div className="algocontainer">          
    <p>Sliding Window is a technique using which you can iterate over subarrays in an array. Here is a sliding window, over an array of integers. Go ahead and try growing and reducing it.</p>
    <SlidingWindow fixedWidth={false} />
    <p>As you see, the window always grows on the right side and shrinks on the left side. You can track many things using a sliding window. For example, you can track the sum of all items in the window, or the average value. Here's an example tracking these things:</p>
    <SlidingWindow fixedWidth={false} showSum={true}/>
    <p>There is another kind of sliding window, which is of fixed size, like this one:</p>
    <SlidingWindow fixedWidth={true} showSum={true}/>
    
    <p>Usually, the sliding window is implemented like this:</p>
    <pre className="code">
{`while (grow-the-window())
if (window-is-invalid())
keep shrinking the window until it becomes valid again
check for the best solution`}                          
    </pre>
    <p>In code, it looks like this:</p>
    <pre className="code">
{`int start = 0;
for (int end = 0; end < arr.size(); end++) {
while(windowInvalid())
start++;
// now window is valid
// do problem-specific stuff like calculating maxSubOfSubarray, numberOfOnesInWindow, etc. 
}`}                         
    </pre>
</div>
)}