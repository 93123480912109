
export default function ReverseLevelOrderSolution() {

    return (
        <div>
            <p>When you do BFS on a tree starting from root, the traversal happens level by level. See <a href='/breadth-first-search/algorithm:-tree-bfs'>here</a> for more information.</p>
            <p>Problem Solution: Do BFS on the tree, and whenever you pop an element, add it to an array. Then return the array.</p> 
        </div>

        
    )
}