import { Button, TextField } from '@mui/material';
import { createRef } from 'react';
import BinaryHeap from '../BinaryHeap';
import { add, pop } from "../BinaryHeapFunctions";
import Code from "../Code";
const { useState } = require("react");

const code = `class MedianOfAStream {
    public:
      priority_queue<int> maxHeap;
      priority_queue<int, vector<int>, greater<int>> minHeap;
    
      void addNumber(int num) {
        if (maxHeap.empty() || maxHeap.top() >= num) {
          maxHeap.push(num);
        } else {
          minHeap.push(num);
        }
        if (maxHeap.size() > minHeap.size() + 1) {
          minHeap.push(maxHeap.top());
          maxHeap.pop();
        } else if (maxHeap.size() < minHeap.size()) {
          maxHeap.push(minHeap.top());
          minHeap.pop();
        }
      }
    
      double findMedian() {
        if (maxHeap.size() == minHeap.size()) {
          return maxHeap.top() / 2.0 + minHeap.top() / 2.0;
        }
        return maxHeap.top();
      }
    };
`.split("\n");
export default function MedianNumberStreamSolution() {
    let [steps, setSteps] = useState(0);
    let [log, setLog] = useState([]);
    let [btn, setBtn] = useState("Begin");
    let [array, setArray] = useState([50, 10, 40, 30]);
    let [left, setLeft] = useState([10, 30]);
    let [right, setRight] = useState([40, 50]);
    
    let [inProgress, setInProgress] = useState(false);
    let [incrementingKey, setIncrementingKey] = useState(0);
    let ref = createRef(), ref2 = createRef();
    let [leftNodes, setLeftNodes] = useState([]);
    let [rightNodes, setRightNodes] = useState([]);
    let [inited, setInited] = useState(false);

    function sleep(ms=500) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    let addToArray = async () => {
        let n = parseInt(ref.current.value);
        if (isNaN(n)) return;        
        array.push(n);
        array = array.sort((a, b) => a-b);
        setArray(array);
        left = array.slice(0, Math.floor(array.length/2));
        setLeft(left);
        right = array.slice(Math.floor(array.length/2))
        setRight(right);
        setMedianStr(findMedian());
        console.log("medianStr", medianStr);
        setIncrementingKey(incrementingKey+1);
    }

    let findMedian = () => {
        if (array.length == 0) return 0;
        if (left.length == right.length) {
            return "(" + left[left.length-1] + " + " + right[0] + ")/2, which is " + (left[left.length-1] + right[0])/2  + ".";
        }
        if (right.length > left.length) {
            return "right has one number more than left, so median is " + right[0] + ".";
        }
        return "left has one number more than right, so median is " + left[left.length-1]  + ".";
    }
    let [medianStr, setMedianStr] = useState(findMedian());
    let [medianStrFromHeaps, setMedianStrFromHeaps] = useState("");

    let initHeaps = async () => {
        for (let i=0; i<left.length; i++) {           
            leftNodes = await add({nodes: leftNodes, setNodes: setLeftNodes, value: left[i], sleepMs: 0});   
            // setLeftNodes(leftNodes);         
        }
        for (let i=0; i<right.length; i++) {           
            rightNodes = await add({nodes: rightNodes, setNodes: setRightNodes, value: right[i], sleepMs: 0});
            // setRightNodes(rightNodes);
        }
        setInited(true);
    }
    let addToLeftHeap = async (num, sleepMs=500) => {
        leftNodes =  await add({nodes: leftNodes, setNodes: setLeftNodes, minOrMax: "max", value: num, sleepMs});
    }
    let addToRightHeap = async (num, sleepMs=500) => {
        rightNodes =  await add({nodes: rightNodes, setNodes: setRightNodes, minOrMax: "min", value: num, sleepMs});        
    }
    let popLeft = async (sleepMs=500) => {
        leftNodes = await pop({nodes: leftNodes, setNodes: setLeftNodes, minOrMax: "max", sleepMs});
    }
    let popRight = async (sleepMs=500) => {
        rightNodes = await pop({nodes: rightNodes, setNodes: setRightNodes, minOrMax: "min", sleepMs});
    }
    let addToHeaps = async (num) => {
        num = parseInt(num);
        if (array.length == 0) {
            await addToRightHeap(num);
        } else if (leftNodes.length == rightNodes.length) {
            console.log("left and right are equal")
            if (num < rightNodes[0].label) {
                await addToLeftHeap(num);
            } else {
                await addToRightHeap(num);
            }
        } else if (rightNodes.length > leftNodes.length) {
            console.log("right is bigger")
            if (num < rightNodes[0].label) {
                await addToLeftHeap(num);
            } else {
                await addToLeftHeap(rightNodes[0].label);
                await popRight();
                await addToRightHeap(num);
            }
        } else {
            console.log("left is bigger")
            if (num < rightNodes[0].label) {
                await addToRightHeap(leftNodes[0].label);
                await popLeft();
                await addToLeftHeap(num);
            } else {
                await addToRightHeap(num);
            }     
        }
        let median, str = "";
        if (leftNodes.length == rightNodes.length) {
            median = (leftNodes[0].label + rightNodes[0].label)/2;
            str = "Median = (" + leftNodes[0].label + " + " + rightNodes[0].label + ")/2, which is " + median;
        } else if (rightNodes.length > leftNodes.length) {
            median = rightNodes[0].label;
            str = "Right heap has one number more than left, so median is " + median;
        } else {
            median = leftNodes[0].label;
            str = "Left heap has one number more than right, so median is " + median;
        }
        setMedianStrFromHeaps(str);
        
    }
    return (
        <div>
            <p>Consider the array [5, 1, 4, 3]. To find the median, we need to first sort it: [1, 3, 4, 5]. Then we need the biggest number in the first half of the array (which is 3), and the smallest number in the second half (which is 4). The median is (3+4)/2, which is 3.5.</p>
            <p>So, basically we need to track the biggest number in the first half of the sorted array, and the smallest number in the second half. Go ahead and add a few numbers in this playground.</p>
            
            <div className='playground'>
                <div key={incrementingKey}>         
                    <div style={{display:"flex", alignItems:"start", gap: 30, marginTop: 20}}>
                        <div>
                            {left.map((n, i) => <span key={i} className="number arrayitem">{n}</span>)}
                        </div>
                        <div>
                            {right.map((n, i) => <span key={i} className="number arrayitem">{n}</span>)}
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        Median: {medianStr}
                    </div>
                </div>
                <div className="input-text-wrapper" style={{display:"flex", alignItems:"stretch", gap: 10, marginTop: 20}}>
                    <TextField InputProps={{ inputProps: { style: { color: '#fff' }}}} label="Value" variant="outlined" inputRef={ref} style={{width: 100}} disabled={inProgress}/>
                    <Button variant="contained" onClick={() => addToArray()} disabled={inProgress}>Add</Button>                
                </div>
            </div>

            <p>As you can see, whenever a new number is added, it must go into the correct half of the array. If the array has an odd number of elements, we make the right half have one number more than the left half. Additionally, if the right half becomes too big, the smallest number in right half must go into the left half. And vice-versa. To achieve all this, we need these methods:</p>
            <div style={{display:"flex", alignItems:"start", gap: 10, marginTop: 20}}>
                <div>In Left Half:</div>
                <div><span className='highlight-outlined'>findMax()</span></div>
                <div><span className='highlight-outlined'>popMax()</span></div>
                <div><span className='highlight-outlined'>addNumber(n)</span></div>
            </div>
            <div style={{display:"flex", alignItems:"start", gap: 10, marginTop: 20}}>
                <div>In Right Half:</div>
                <div><span className='highlight-outlined'>findMin()</span></div>
                <div><span className='highlight-outlined'>popMin()</span></div>
                <div><span className='highlight-outlined'>addNumber(n)</span></div>
            </div>
            <p>A heap provides exactly these methods! We can solve this problem using two heaps: a max-heap for the left half, and a min-heap for the right half, like this:</p>
            <div className='playground'>
                {!inited && (
                <div><Button variant="contained" onClick={() => initHeaps()}>Initialize Heaps</Button></div>
                )}
                {inited && (
                <div className="input-text-wrapper" style={{display:"flex", alignItems:"stretch", gap: 10, marginTop: 20}}>
                    <TextField label="Value" variant="outlined" inputRef={ref2} style={{width: 100}} disabled={inProgress}/>
                    <Button variant="contained" onClick={() => addToHeaps(ref2.current.value)} disabled={inProgress}>Add</Button>                
                </div>)}
                {(leftNodes.length > 0 || rightNodes.length > 0) && (
                <>
                    <div style={{marginTop: 20}}>{medianStrFromHeaps}</div>
                    <div style={{display:"flex", flexDirection: "row", alignItems:"center", gap: 10, marginTop: 20}}>
                        <div className="bucket" style={{}}>
                            <div className='bucket-title'>Left Half (max heap)</div>
                            <BinaryHeap showAdd={false} showPop={false} key={1} redrawMethodName="redraw1" minOrMax="max" nodes={leftNodes} setNodes={setLeftNodes}/>
                        </div>
                        <div className="bucket">
                            <div className='bucket-title'>Right Half (min heap)</div>
                            <BinaryHeap showAdd={false} showPop={false} key={2} redrawMethodName="redraw2" minOrMax="min" nodes={rightNodes} setNodes={setRightNodes}/>
                        </div>
                    </div>
                </>
                )}
            </div>
            <p>Here is the implementation:</p>
            <div>
                <Code code={code} codeLine={-1} />
            </div>

            
        </div>
    )
}