export const ProbAlienDictionary = () => {
    return (
        <div className="sample-problem">
            Given a sorted dictionary of an alien language having N words and k starting alphabets of standard dictionary. Find the order of characters in the alien language.
            <div>
                Many orders may be possible for a particular test case, and you may return any valid order.
            </div>
            <div className="example">
                <div>            
                Input: 
                </div>
                <div>
                N = 5, K = 4
                </div>
                <div>
                dict = ["baa","abcd","abca","cab","cad"]
                </div>
                <div>
                Output:
                </div>
                <div>
                b, d, a, c
                </div>
                <div>
                Explanation:
                </div>
                <div>
                Here order of characters is 'b', 'd', 'a', 'c' Note that words are sorted within a dictionary. In this language, "baa" comes before "abcd", therefore 'b' is before 'a' in output. Similarly we can find other orders.
                </div>
            </div>
        </div>        
    )
}