import React from "react";

const problem = `Given an array of positive numbers and a number K, find the maximum sum of any contiguous subarray of size K.`, 

examples = [`Input: [2, 1, 5, 1, 3, 2], K=3
Output: 9`];

export default function MaxSubarraySumSizeK() {
    return (
        <div style={{textAlign: "left"}}>
            <pre>{problem}</pre>
            {examples.map((example, index) => (
                <div key={index} className="example">
                    <pre>{example}</pre>
                </div>
            ))}
        </div>)
}