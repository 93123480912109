export default function Grid({name, grid, getCls, ii, jj}) {
    return (
<div>        
    <div style={{display: "inline-block", width: 30, opacity: 0}}>00</div>
    {grid[0].map((v, j) => (
        <div key={j} style={{display: "inline-block", width: 30, textAlign: "center"}}>
                                       
            <div key={-1} style={{display: "inline-block", width: 30, textAlign: "left", position: "relative", opacity: 0.4}} className={j == jj ? "selected-grid-index" : ""}>
                {/* {j == jj && (<span style={{position: "absolute", bottom: 16, left: 10, color: "red", fontSize: 20}}>↓</span>)} */}
                <span style={{opacity: j==jj ? 1 : 0, fontSize: 12, verticalAlign: "middle"}}>j=</span>
                {j}
            </div>
        </div>))}
    {grid.map((row, i) => (                        
        <div key={i}>                            
            <div key={-1} style={{display: "inline-block", width: 30, textAlign: "center", position: "relative", opacity: 0.4}} className={i == ii ? "selected-grid-index" : ""}>
                {/* {i == ii && (<span style={{position: "absolute", bottom: 0, left: -11, color: "red", fontSize: 20}}>→</span>)} */}
                <span style={{opacity: i==ii ? 1 : 0, fontSize: 12, verticalAlign: "middle"}}>i=</span>
                {i}
            </div>
            {row.map((v, j) => {
                return (
                    <div key={j} style={{display: "inline-block", width: 30, border: "0.5px solid rgb(225 225 225)", textAlign: "center"}} className={getCls(i,j)}>{grid[i][j]}</div>
                )
            })}
        </div>
    ))}
    <div className="grid-title" style={{textAlign: "center"}}>{name}</div>
</div>     
)}