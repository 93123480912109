export function Array({values , itFn=()=>null, current, getCls=()=>"", showArrow=(i) => false, showIndex=false}) {
    return (
        <div className="array">
            {values.map((value, idx) => (
                <div key={idx} className="arrayitem-wrapper">
                    <div className={"arrayitem " +  (current==idx ? "current " : " ") + getCls(idx)}>{value}</div>
                    <div className="array-extra-items">
                        <div className="arrayindex" style={{opacity: (itFn(idx) != null) ? 1 : 0, textAlign: "center"}}>{itFn(idx)}</div>            
                        {showIndex && (<div className="arrayindex" style={{opacity: (current == idx) ? 0.8 : 0.4, textAlign: "center"}}>{idx}</div>)}
                        {showArrow(idx) && (<div className="arrow">↑</div>)}
                    </div>
                </div>
            ))}
        </div>
    )
}