import { Button, Typography } from "@mui/material";
import { useState } from "react";


function sleep(ms=1000) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const generate = (nodeCount) => {
    let items = [];
    for (let i = 0; i < nodeCount; i++) {
        let num = Math.floor(Math.random() * 10)+1;
        if (Math.random() * 100 < 20) num = Math.floor(num*10);
        items.push(num);
    }
    return items
}
const doDp = (nodes) => {
    let ndp = {};
    ndp[0] = 0;
    ndp[1] = 0;
    for (let i = 2; i < nodes.length; i++) {
        ndp[i] = Math.min(ndp[i-2] + +nodes[i-2], ndp[i-1] + +nodes[i-1]);
    }
    // in final answer, you must add last node's value
    return ndp;
}
function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}
const equations = `
minCost[0] = 0;
minCost[1] = 0;

for (int i = 2; i < totalSteps; i++) {
    minCost[i] = min(minCost[i-2] + cost[i-2], minCost[i-1] + cost[i-1]);
}`;

export default function MinCost() {
    let [nodeCount, setNodeCount] = useState(6);
    let [nodes, setNodes] = useState(generate(nodeCount));
    let [ways, setWays] = useState([[]]);
    let [steps, setSteps] = useState(0);
    let [btn, setBtn] = useState("Next Step");
    let [info, setInfo] = useState("");
    let [dp, setDp] = useState(doDp(nodes));

    const combinations = (i) => {
        if (i === 0) return [[0]];
        if (i === 1) return [[0], [1], [0, 1]];
        let combs = [...combinations(i-1), ...combinations(i-2)];
        for (let j = 0; j < combs.length; j++) {
            combs[j] = [...combs[j], i];
        }
        combs = combs.filter(onlyUnique);
        return combs;
    }
    const listWays = async () => {
        if (steps == 0) {
            let combs = combinations(nodeCount-1);   
            setWays(combs);
            setSteps(steps+1);
            setBtn("List all possible paths to the top");
            return;
        }
        setBtn("Next Step")
        if (steps == 1) {
            setInfo("All of these paths end in " + (nodeCount-1) + ", which is the last step.");
            setSteps(steps+1);
            return;
        }
        if (steps == 2) {
             setInfo("However, the last-but-one step is either " + (nodeCount-2) + " or " + (nodeCount-3) + ".");
             setSteps(steps+1);
             return;
        }
        if (steps == 3) {
             setInfo("The problem says that from any step, you can climb either one or two steps. So, you can only go to " + (nodeCount-1) + " directly from " + (nodeCount-2) + " or " + (nodeCount-3) + ".");
             setSteps(steps+1);
             return;
        }
        if (steps == 4) {
             setInfo("You cannot go to " + (nodeCount-1) + " from anywhere else. For ex, you can go " + (nodeCount-4) + " -> " + (nodeCount-2) + " -> " + (nodeCount-1) + ", but not " + (nodeCount-4) + " -> " + (nodeCount-1) + ".");
             setSteps(steps+1);
             return;
        }
        if (steps == 5) {
            setInfo("Imagine that there is one house on each step, and your friends live in those houses. Suppose your friend Alice tells you that the cheapest cost to get to her house on step " + (nodeCount-2) + " is " + (dp[nodeCount-2]) + ". Bob tells you that the cheapest cost to get to step " + (nodeCount-3) + " is " + dp[nodeCount-3] + ". But they don't know the cheapest way to get to step " + (nodeCount-1) + ", which is where you live.");
             setSteps(steps+1);
             return;
        }
        if (steps == 6) {
            setInfo("There are only two ways to get to " + (nodeCount-1) + ": either from Alice's house, or Bob's house. The cheapest cost to get to Alice's house (" + (dp[nodeCount-2]) + ") + " + " the cost of entering step " + (nodeCount-2) + " (" + +nodes[nodeCount-2] + ") = " + (+dp[nodeCount-2] + +nodes[nodeCount-2]) + ". If you go through Bob's house, it's the cheapest cost to his house (" + (dp[nodeCount-3]) + ") + cost of entering his step (" + +nodes[nodeCount-3] + ") = " + (+dp[nodeCount-3] + ++nodes[nodeCount-3]) + ". The smaller number is " + Math.min(+dp[nodeCount-2] + +nodes[nodeCount-2], +dp[nodeCount-3] + +nodes[nodeCount-3]) + ", so that's the answer.");
             setSteps(steps+1);
             return;
        }
        if (steps == 7) {
            setInfo("Great. Then you ask Alice how she knows the cheapest cost to get to " + (nodeCount-2) + ".");
            setSteps(steps+1);
            return;
        }
        if (steps == 8) {
            setInfo("She says she did the same thing! You can only go to " + (nodeCount-2) + " from " + (nodeCount-3) + " or " + (nodeCount-4) + ". A friend of hers knew how to get to those steps cheaply, and Alice figured out the rest.");
            setSteps(steps+1);
            return;
        }
        if (steps == 9) {
            setInfo("Bob says the same thing about finding the cheapest cost to " + (nodeCount-3) + ".");
            setSteps(steps+1);
            return;
        }
        if (steps == 10) {
            setInfo("You ask Alice's friends and Bob's friends how they solved their problems, and they say they knew friends who found the cheapest ways to get to the steps just before their steps. You keep asking their friends, and so on, until you finally ask someone how they knew the cheapest cost to get to the 0th and the 1st step.");
            setSteps(steps+1);
            return;
        }
        if (steps == 11) {
            setInfo("They look at you like you're crazy. Because of course the cheapest cost to get to 0 and 1 is 0. The problem says so.");
            setSteps(steps+1);
            return;
        }
        if (steps == 12) {
            setInfo("Now you have the picture. To know the cheapest cost to get to any step i, you just need to know the cheapest cost to get to 1 and 2 steps before i. From i-1, you can pay cost[i-1] and reach i. From i-2, you can pay cost[i-2] and reach i. Between these two options, you just need to select the smaller number. You write your solution like this:");
            setSteps(14);
            return;
        }
        if (steps == 14) {
            setInfo("That's it. That's the recurrence.");
            setSteps(steps+1);
            return;
        }
    }
    const getCellClass = (way, j) => {
        let cls = "cell" + (way.includes(j) ? " selected" : "");
        if (steps == 2 && j == nodeCount-1) cls += " highlight";
        if ([3,4,5,6,7,8].includes(steps) && way[way.length-2] == j) cls += " highlight";
        if (steps == 9 && way[way.length-2] == (nodeCount-2) && way[way.length-3] == j) cls += " highlight";
        if (steps == 10 && way[way.length-2] == (nodeCount-3) && way[way.length-3] == j) cls += " highlight";
        if ([11,12].includes(steps) && way[0] == j) cls += " highlight";
        return cls;
    }

    return (     
    <div style={{textAlign: "left"}}>
        {steps <= 14 && (
        <Button fullWidth="true" style={{marginTop: 10, marginBottom: 10}} variant="contained" onClick={() => listWays()}>{btn}</Button>)}

        {[0].includes(steps) && (
        <div style={{padding: 5}}>
            Suppose costs = {nodes.join(", ")}
        </div>)}
        {[1].includes(steps) && (
        <div style={{padding: 5}}>
        Let's begin by writing down all the possible paths to the top of the staircase. In the first path, we go to index 0, then index 2, index 3, etc, and then at last to the final step. From the final step, which is the last step, we can climb to the top. 
        </div>)}
                
        <Typography variant="body1">{info}</Typography>
        {steps == 14 && (<div><pre style={{textAlign: "left"}}>{equations}</pre></div>)}

        {steps >= 0 && steps < 12 && (
            <div className="bucket-container" style={{display: "inline-block", marginBottom: 10, marginTop: 10}}>
                <div className="way bucket" style={{marginBottom: 5, width: "fit-content", textAlign: "center"}}>
                    <div className='bucket-title'>Costs</div>
                    {nodes.map((node, i) => (
                            <div key={i} className="cell selected" style={{display: "inline-block"}}>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <div className="arrayitemvalue">{node}</div>
                                    <div className="arrayitemindex" style={{fontSize: "small", color: "rgb(133 152 152)"}}>{i}</div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>)}
        <div></div>      
        {steps >= 1 && steps <= 12 && (
        <div style={{padding: 5, textAlign: "left", display: "inline-block", padding: 10, marginTop: 10}} key={steps} className="bucket">
            <div className='bucket-title'>All Possible Paths to the Top</div>
            {(ways).map((way, i) => (
                <div key={i} className="way">
                    {nodes.map((node, j) => (
                        <div key={j} className={getCellClass(way, j)}>
                            {j}
                        </div>
                    ))}
                    {way.length > 0 && steps == -1 && (
                    <span className="cost" style={{marginLeft: 10}}>
                        Total Cost = {i<=1 && (way.map(w => nodes[w]).join(" + ")) + " = "} {way.reduce((acc, curr) => acc + nodes[curr], 0)}
                    </span>)}
                </div>
            ))}
        </div>)}
    </div>)
}