import { Button, Typography } from "@mui/material";
import React, { useState } from "react";

let code = 
`int findLength(const vector<char> &arr) {
    int start = 0, maxLength = 0;
    unordered_map<char, int> count;
    for (int end = 0; end < arr.size(); end++) {
        count[arr[end]]++;
        while ((int)count.size() > 2) {
            count[arr[start]]--;
            if (count[arr[windowStart]] == 0) {
                count.erase(arr[start]);
            }
            start++;
        }
        maxLength = max(maxLength, end - start + 1);
    }
    return maxLength;
}`.split("\n");

const generateRandomArray = (size) => {
    let array = [];
    for (let i = 0; i < size; i++) {
        array.push(Math.floor(Math.random() * 5) + 1);
    }
    return array;
}

function sleep(ms=100) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export default function FruitsIntoBasketsSolution() {
    let [steps, setSteps] = useState(0);
    let [array, setArray] = useState(generateRandomArray(10));
    let [windowStart, setWindowStart] = useState(0);
    let [windowEnd, setWindowEnd] = useState(0);
    let [btn, setBtn] = useState("Grow the Window");
    let [log, setLog] = useState([""]);
    let [twoNumbers, setTwoNumbers] = useState([]);
    let [count, setCount] = useState({});
    let [selectedLine, setSelectedLine] = useState(-1);
    
    const GROW = 0;
    const SHRINK = 1;    
    let [action, setAction] = useState(GROW);
    let [answer, setAnswer] = useState(0);
    
    let grow = async () => {
        if (windowEnd < array.length - 1) {
            windowEnd = windowEnd + 1;
            setWindowEnd(windowEnd);

            let nextNumber = array[windowEnd];
            if(!count[nextNumber] && twoNumbers.length == 2) {
                while (windowStart < windowEnd && twoNumbers.length == 2) {
                    await sleep();
                    count[array[windowStart]] = count[array[windowStart]] ? count[array[windowStart]] - 1 : 0;
                    setCount(count);
                    if (count[array[windowStart]] == 0) {
                        twoNumbers = twoNumbers.filter((n) => n != array[windowStart]);
                        setTwoNumbers(twoNumbers);
                    }        
                    windowStart = windowStart + 1;
                    setWindowStart(windowStart);        
                }
            }
            if (!count[nextNumber]) {
                count[nextNumber] = 1;
                setCount(count);
                twoNumbers.push(nextNumber);
                setTwoNumbers(twoNumbers);
            } else {
                count[nextNumber] = count[nextNumber] + 1;
                setCount(count);
            }
            let size = windowEnd - windowStart + 1;
            answer = Math.max(answer, size);
            setAnswer(answer);          
        }
        if (windowEnd == array.length - 1) {
            setBtn("");
            setAnswer(answer);
        }
    }
    let next = () => {
        if (Object.keys(count).length == 0) {
            count[array[0]] = 1;
            setCount(count);
            twoNumbers = [array[0]];
            setTwoNumbers(twoNumbers);
        }

        grow();
    }

    return (
<div style={{textAlign: "left"}}>
    <p>
        If you rewrite the problem without the story, it will go like this: Given an array of numbers, find the length of the longest subarray with a maximum of two distinct numbers.
    </p>
    <p>
        We can solve it using a sliding window, by tracking the two distinct numbers currently inside the window. We can grow the window whenever the new number is one of the two numbers we are tracking. Whenever it is not, we must shrink the window until there are only two distinct numbers inside the window. Go ahead and try it out.
    </p>
    <div className="code-and-btns">
        <div className="btns">            
            {btn.length>0 && (<Button fullWidth="true" style={{marginTop: 10, marginBottom: 10}} variant="contained" onClick={() => next()}>{btn}</Button>)}
            <p><Typography variant="body1">
                {log.map((l, i) => (
                    <div key={i}>{l}</div>
                ))}
            </Typography></p>
            <div className="window" style={{margin: "0 auto"}}>
                {array.map((value, index) =>  (
                    <div className={"windowitem " + ((index >= windowStart && index <= windowEnd) ? "in" : "") + " " + (index == 0 ? "first " : "") + (index == array.length-1 ? "last " : "")}>
                        <div className="number" style={{textAlign: "center"}}>{value}</div>
                    </div>
                ))}
            </div>
            <div style={{marginTop: 30}}>
                <div className="bucket">
                    <div className="bucket-title">Count</div>
                    {Object.keys(count).map((key, i) => count[key]>0 && (
                        <div key={i}>
                            {key}: {count[key]}
                        </div>
                    ))}
                </div>
                <div>
                    Current Window Size: {windowEnd - windowStart + 1}
                </div>        
                <div>
                    Maximum Window Size: {answer}
                </div>     
            </div>
        </div>
        <div className="code">
            {code.map((line, i) => (
            <div className={"line " + ((selectedLine==i) ? "selected" : "")} key={i}>
                <span className="linenumber">{i}</span>
                <pre>{line}</pre>
            </div>))}
        </div>
    </div>
</div>)
}